import { Close, Delete, Search } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { BiPlus } from 'react-icons/bi'
import { useStateContext } from '../Context/ContextProvider';
import {RiDeleteBin5Fill} from 'react-icons/ri'
import {FaCubes} from 'react-icons/fa'
import {PiDownload} from 'react-icons/pi'
import swal from 'sweetalert';
function NewStockTransfer() {
  const {transferData,createTranfer,wareTransferData,WareHouse,wareHouse,}=useStateContext()
  console.log(transferData);
  const [productSelect,setProductSelect]=useState(false)
  const [selectedRows, setSelectedRows] = useState([]);
  const [product, setProduct] = useState([]);
  console.log(product);
// const handleRowSelect = (rowId) => {
//   if (selectedRows.includes(rowId)) {
//     setSelectedRows(selectedRows.filter((id) => id !== rowId));
//   } else {
//     setSelectedRows([...selectedRows, rowId]);
//   }
// };
  const [value,setvalue]=useState(10)
  const [transfer,settransfer]=useState("")
  const [warehouse,setwarehouse]=useState({
    ship_from:"",
    destination:""
  })
  
  const [show,setshow]=useState(false)
  console.log(wareHouse);
  useEffect(()=>{ 
      
      WareHouse()
  },[])
  useEffect(()=>{ 
    if(warehouse.ship_from!==""&&warehouse.destination!==""){
      
      handlechange()
    }
},[warehouse])
  const handlechange=()=>{
    console.log(warehouse);
    if(warehouse.ship_from!==warehouse.destination && warehouse.ship_from&& warehouse.destination){

      if(warehouse.ship_from&& warehouse.destination && warehouse.ship_from!==warehouse.destination){
        setshow(true)
        let ren=""
        var value=""
        for(let i = 0; i < 10; i++) {
          ren=String.fromCharCode(Math.floor(Math.random() * 10) + 48)
            value+=ren
          
      }
      settransfer(value)
      wareTransferData({
        ship_from:warehouse.ship_from,
        ship_destination:warehouse.destination
      })
      }
      else{
        setshow(false)
      }
    }
    else{
      swal({
        title: "Warehouse is same!",
        text: "change the warehouse ship from or destination Warehouse",
        icon: "warning",
        dangerMode: true,
      });
    }
  }
  const deltranfer=()=>{
    setshow(false)
    setSelectedRows([])
  }
  const [multipulSelct, setmultipulSelct] = useState([])
  const [select, setselect] = useState([])
  const getselect = (id, event) => {
    console.log(multipulSelct);
    if(multipulSelct.includes(id)){
      setselect(select.filter((item) => item !== id));
      setmultipulSelct(multipulSelct.filter((item) => item !== id));
      setProduct(product.filter((item) => item !== id));

    }
    else{
      setselect([...select,id]);
      setmultipulSelct([...multipulSelct,id])
      setProduct([...product,id])
    }
  
  }
  const isRowSelected = (id) => multipulSelct.includes(id);

  const deldata=(item)=>{
    
    {
      var array=product?.filter((items)=>{
        if(items.id!==item){
          return items
        }
      })
    }
    console.log(array);
    setProduct(array)
  }
  const [senPro,setSendPro]=useState([])
  const sendataTransfer=()=>{
    var array=product?.filter((item)=>{
        if(item?.level_warehouse_1<=0){
          return item
        }
    })
    // console.log();
    if(array.length>0){
      swal({
        title: "Warehouse QTY is zero",
        text: "change the warehouse ship from or destination Warehouse",
        icon: "warning",
        dangerMode: true,
      });
    }else{
 
      createTranfer({
  
        transfer_items:senPro,
        transfer_id:`WT${transfer}`,
        from_warehouse_id:warehouse.ship_from,
        to_warehouse_id:warehouse.destination,
        transferred_at:new Date()
      })
    }

  }
return (
  <div className='new_stok py-4'>
  <h4 style={{fontWeight:"bold"}}>Transfer Details<span style={{background:"#E8E6E6",color:"gray",fontSize:"13px",padding:"0.3rem 1rem ",borderRadius:"50px"}}>Draft</span><span style={{background:"#FFD7D3",color:"#C66362",fontSize:"13px",padding:"0.3rem 1rem ",borderRadius:"50px",marginLeft:"10px"}}>Beta</span></h4>
   {/* Button trigger modal */}
{show &&<button onClick={deltranfer} className="btnn" >
<RiDeleteBin5Fill/> Delete transfer
</button>
}  
{/* Modal */}
<div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
<div className="modal-dialog modal-xl" role="document">
<div className="modal-content">
  <div style={{border:"none"}} className="modal-header ">
    <h5 className="modal-title" id="exampleModalLabel">Add items</h5>
    <button style={{border:"none",background:"none",color:"gray"}} type="button" className="close" data-dismiss="modal" aria-label="Close">
      <span aria-hidden="true"><Close/></span>
    </button>
  </div>
  <div className="modal-body">
  {/* <div className='inp'>
      <label><Search/><input /></label>
  </div> */}
<table className='table'>
    <thead>
    <tr style={{background:"#F3F3F3"}}>
<th scope="col"></th>
<th scope="col" style={{width:"500px"}}>Items detail</th>
<th scope="col"  >Availble
<p style={{margin:"0",fontWeight:"lighter",fontSize:"13px"}}>default</p>
</th>
<th scope="col">Availble
<p style={{margin:"0",fontWeight:"lighter",fontSize:"13px"}}>uk warehouse</p>
</th>
<th scope="col">Due</th>

</tr>
    </thead>

    <tbody>
{ transferData?.map((item)=>{
      return(
        <tr onClick={(e) => getselect(item, e)} >
    
 
        <td><input type="checkbox" checked={ isRowSelected(item) ? true:false}  /></td>
        <td>
              
         <div className='row'>
               <div className='col-2'>
               {item.images[0]? <img src={item.images[0]?.image_path} alt='...'/>: <img src='https://ipsf.net/wp-content/uploads/2021/12/dummy-image-square.webp' alt='...'/>}
               </div>
               <div className='col-10'>
               <h6>{item.title}</h6>
            <span style={{fontWeight:"bold",color:"#2895d8"}}>{item.item_no}</span>
               </div>
              </div>
           
          </td>
        <td>{item?.level_warehouse_1}</td>
        <td>{item?.level_warehouse_2}</td>
        <td>0</td>
        {/* <td><input disabled={item?.pro_inventory?.level>0?false:true} style={{cursor:`${item?.pro_inventory?.level>0?"pointer":"not-allowed"}`}} onChange={(e)=>setProduct([...product,{product_id:item.id,quantity:e.target.value}])}/></td> */}
        {/* <td><Delete/></td> */}
      
      </tr>
      )
  })
}

    </tbody>
  </table>

  </div>
  <div className="modal-footer">
    <button type="button" style={{border:"1px solid lightgray",padding:".5rem 1rem",background:"#ddd",color:"gray",fontWeight:"bold"}} data-dismiss="modal">Close</button>
    <button type="button" style={{border:"1px solid lightgray",padding:".5rem 1rem",background:"#44688C",color:"lightgray",fontWeight:"bold"}} data-dismiss="modal" onClick={()=>setProductSelect(true)} className="">Save changes</button>
  </div>
</div>
</div>
</div>
<div className='select_box my-3' >

  <div className='row py-4 '>
      <div className='col-3'> 
      <label>Transfer Reference</label>
      {show&&<p>WT{transfer}</p>}
      </div>
      <div className='col-3'>
      <label>Ship From<span style={{color:"red"}}>*</span></label>
      <select onChange={(e)=>setwarehouse({...warehouse,ship_from:e.target.value})} placeholder='Default' style={{padding:".7rem 1rem" ,width:"100%"}} >
        <option hidden>Select Warehouse</option>
          {wareHouse?.map((item)=>{
              return(

                  <option value={item.id}>{item.name}</option>
              )

          })
          
          }
       
      </select>
      </div>
      <div className='col-6'>
      <label>Destination Warehouse<span style={{color:"red"}}>*</span></label>
      <select onChange={(e)=>setwarehouse({...warehouse,destination:e.target.value})} placeholder='Default' style={{padding:".7rem 1rem" ,width:"75%"}} >
        <option hidden>Select Warehouse</option>
         
          {wareHouse?.map((item)=>{
              return(

                  <option value={item.id}>{item.name}</option>
              )

          })
          
          }
          
      </select>

      </div>
  </div>
</div>

  <div className='box_product' style={{overflowY:"scroll"}}>
  <div  className='row'>
    <div className='col-9'>
    {show&&<h5>Add and pack products</h5>}
    </div>
    <div className='col-3'>
    {show && <>
<button type="button" className="bt2" data-toggle="modal" data-target="#exampleModal">
<PiDownload/> Import product
</button>
<button type="button" onClick={()=>setmultipulSelct([])}  className="btnnn" data-toggle="modal" data-target="#exampleModal">
<BiPlus/> Add Products
</button>
</>
}
    </div>
    {product.length>0?
    <>
    <table className='table my-5'>
    <thead>
    <tr style={{background:"#F3F3F3"}}>
<th scope="col"></th>
<th scope="col" style={{width:"500px"}}>
  <div style={{display:"flex",justifyContent:"center",alignContent:"center"}}>
    <p>Items detail</p>
  </div>
</th>
<th scope="col"  >Availble
<p style={{margin:"0",fontWeight:"lighter",fontSize:"13px"}}>default</p>
</th>
<th scope="col">Availble
<p style={{margin:"0",fontWeight:"lighter",fontSize:"13px"}}>uk warehouse</p>
</th>
<th scope="col">Due</th>
<th scope="col">Qty Product</th>
<th scope="col"></th>

</tr>
    </thead>

    <tbody>
    { product?.map((item)=>{
      return(
        <tr>
    
 
        <td><input type="checkbox"   /></td>
        <td>
              
         <div className='row'>
               <div className='col-2'>
               {item.images[0]? <img src={item.images[0]?.image_path} alt='...'/>: <img src='https://ipsf.net/wp-content/uploads/2021/12/dummy-image-square.webp' alt='...'/>}
               </div>
               <div className='col-10'>
               <h6>{item.title}</h6>
            <span style={{fontWeight:"bold",color:"#2895d8"}}>{item.item_no}</span>
               </div>
              </div>
           
          </td>
          <td>{item?.level_warehouse_1}</td>
        <td>{item?.level_warehouse_2}</td>
        <td>0</td>
        <td><input disabled={item?.level_warehouse_1>0?false:true} style={{cursor:`${item?.level_warehouse_1>0?"pointer":"not-allowed"}`}} onChange={(e)=>setSendPro([...senPro,{product_id:item.id,quantity:e.target.value}])}/></td> 
 <td onClick={()=>deldata(item.id)}><Delete/></td>
      
      </tr>
      )
  })
}
        </tbody>
  </table>
 
    </>
    :<div className='col-4'>
    <FaCubes style={{fontSize:"50px"}}/>
          <h4>No item</h4>
          <p>Add items manually or import items to add stock to your transfer</p>
          <div>

          <button type="button" className="bt2" data-toggle="modal" disabled={!show} data-target="#exampleModal">
<PiDownload/> Import product
</button>
         <button type="button" className="bt1" data-toggle="modal" disabled={!show} data-target="#exampleModal">
<BiPlus/> Add Products
</button>
          </div>
    </div>}
 
 
  </div>
  </div>
  <div className='row'>
      <div className='col-8'></div>
      <div className='col-4'>
      </div>
    </div>
        <button style={{border:"none",background:"#44688C",color:"white",padding:"0.5rem 1rem",borderRadius:"5px",margin:"1rem 0 0 0"}} onClick={sendataTransfer}>Move Transfer</button>
</div>
)
}
export default NewStockTransfer