import { Add, Settings } from '@mui/icons-material'
import React from 'react'
import { HiSearch } from 'react-icons/hi'

function OpenOrderOld() {
  return (
    <div className='open_order'>
        <div className='row'>
            <div className='col-8'>
                <select>
                    <option>asd</option>
                </select>
                <input type='text' placeholder='Search by Email,order id or Reference Number '/>
                <button><HiSearch/></button>
                <span style={{marginLeft:"1rem"}}>0 Orders Selected of 1</span>
            </div>
            <div className='col-4'>
                <div>

                <label className='mx-3'>Location:</label>
                <select>
                    <option>asd</option>
                </select>
                </div>
                <div>
                    <button><Add style={{color:"black"}}/>Add New</button>
                    <button style={{color:"black"}}><Add/>Despatch Console</button>
                    <button style={{marginRight:"1rem"}}><Settings style={{color:"black"}}/></button>
                </div>
                <button>Edit Order (Shift+E)</button>
                <button style={{marginRight:"1rem"}}>View Order (Shift+V)</button>

            </div>
        </div>
        <div>
  {/* Tabs navs */}
  <ul className="nav nav-tabs mb-3" id="ex-with-icons" role="tablist">
    <li className="nav-item" role="presentation">
      <a data-mdb-tab-init className="nav-link active" id="ex-with-icons-tab-1" href="#ex-with-icons-tabs-1" role="tab" aria-controls="ex-with-icons-tabs-1" aria-selected="true"><i className="fas fa-chart-pie fa-fw me-2" />Sales</a>
    </li>
    <li className="nav-item" role="presentation">
      <a data-mdb-tab-init className="nav-link" id="ex-with-icons-tab-2" href="#ex-with-icons-tabs-2" role="tab" aria-controls="ex-with-icons-tabs-2" aria-selected="false"><i className="fas fa-chart-line fa-fw me-2" />Subscriptions</a>
    </li>
    <li className="nav-item" role="presentation">
      <a data-mdb-tab-init className="nav-link" id="ex-with-icons-tab-3" href="#ex-with-icons-tabs-3" role="tab" aria-controls="ex-with-icons-tabs-3" aria-selected="false"><i className="fas fa-cogs fa-fw me-2" />Settings</a>
    </li>
  </ul>
  {/* Tabs navs */}
  {/* Tabs content */}
  <div className="tab-content" id="ex-with-icons-content">
    <div className="tab-pane fade show active" id="ex-with-icons-tabs-1" role="tabpanel" aria-labelledby="ex-with-icons-tab-1">
      Tab 1 content
    </div>
    <div className="tab-pane fade" id="ex-with-icons-tabs-2" role="tabpanel" aria-labelledby="ex-with-icons-tab-2">
      Tab 2 content
    </div>
    <div className="tab-pane fade" id="ex-with-icons-tabs-3" role="tabpanel" aria-labelledby="ex-with-icons-tab-3">
      Tab 3 content
    </div>
  </div>
  {/* Tabs content */}
</div>


    </div>
  )
}

export default OpenOrderOld