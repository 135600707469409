import React, { useEffect, useState } from 'react'
import Topbar from '../Pages/Topbar'
import { BiEnvelope, BiPrinter, BiSave} from 'react-icons/bi'
// import { FaRegNewspaper } from 'react-icons/fa'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { useStateContext } from '../Context/ContextProvider'
import { FiFilter } from 'react-icons/fi'
import { ImDownload2 } from 'react-icons/im'
// import { GrFormAdd } from 'react-icons/gr'
import AddProductPO from './AddProductPO'
import{TiArrowBack}from'react-icons/ti'
import { useNavigate, useParams } from 'react-router-dom'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close } from '@mui/icons-material'
import NotePO from './Edit/NotePO'
import AutidLog from './Edit/AutidLog'
import ExtendPO from './Edit/ExtendPO'
import swal from 'sweetalert'
import Print from '../Pages/Print'
const style = {
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  boxShadow: 24,
};
const style1 = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
};
function EditPO() {
  const { WareHouse, wareHouse, currency,
    currencyList,token, changePOstatus, getPObyId, singelPo, getsuplier, supplier, poData, deletePO, editPo,base_url } = useStateContext()
  const parms = useParams()
  const [data, setdata] = useState({
    status: "",
    reference: "",
    supplier_ref: "",
    date: "",
    location: "",
    supplier: "",
    expected: "",
    unit_cost_amounts: "",
    invoicing_currency: "",
    invoicing_currency: "",
    total_amount: "",
    products: [],
  })
  console.log(singelPo);
  // const navigate = useNavigate()
  useEffect(() => {
  
    setdata({
      status: singelPo?.status,
      reference: `PO${singelPo?.reference}`,
      supplier_ref: singelPo?.supplier_ref,
      date: singelPo?.date,
      location: singelPo?.location_id,
      supplier: singelPo?.supplier_id,
      expected: singelPo?.expected,
      unit_cost_amounts: singelPo?.unit_cost_amounts,
      invoicing_currency: singelPo?.invoicing_currency,
      total_amount: singelPo?.total_amount,
      products:singelPo?.products,
      auditLogs:singelPo?.auditLogs,
      poExtends:singelPo?.poExtends,
      poNotes:singelPo?.poNotes,
      id:singelPo?.id

    })
    // if (singelPo?.status == "Delivered") {
    //   navigate("/dashboard/PurchaseOrder")
    // }
  }, [singelPo, parms.id])
  useEffect(() => {
    getPObyId(parms.id)
    // poData?.filter((item)=>{
    //     if(item.id==parms.id){
    //        setdata(item)
    //     }
    // })
  }, [])
  console.log(data);
  const azab = () => {
    const array = []
    data.products.map((item) => {
      var obj = {

        id: item.id,
        item_no: item.item_no,
        product_id: item.pro_inventory.product_id,
        qty: "1",
        line_total: parseInt(item.purchase_price) + parseInt(item.tax),
        unit_cost: item.purchase_price,
        retail_price: item.retail_price,
        tax: item.tax,
        title: item.title

      }
      array.push(obj)
    })
    setdata({ ...data, products: array })
  }
  console.log(data);
  useEffect(() => {
    let ren = ""
    var value = ""
    // for(let i = 0; i < 10; i++) {
    //     ren=String.fromCharCode(Math.floor(Math.random() * 10) + 48)
    //       value+=ren

    // }
    // console.log(value);
    // setdata({...data,reference:value})
    WareHouse()
    getsuplier()
    currency()
  }, [])
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const [multipulSelct, setmultipulSelct] = useState([])
  const [select, setselect] = useState([])
  const [recive, setrecive] = useState('')
  const getselect = (id, event) => {
    if (event.ctrlKey || event.metaKey) {

      setselect([...select, id]);
      setmultipulSelct([...multipulSelct, id])
    } else {
      setselect([id]);
      setmultipulSelct([id])
    }
  }
  const isRowSelected = (id) => select.includes(id);
  console.log(select);
  const sendData = () => {
    handleClose()
    changePOstatus({
      id: data.id,
      status: "OPEN",
    })
  }
  const sendData1 = () => {
    handleClose()
    changePOstatus({
      id: data.id,
      status: "Delivered",
    })
   
     
    
  }
  const sendData2 = () => {
    console.log(data);
   const obj= {
      po_id: data.id,
      products: [
          {
              product_id: select[0]?.id,
              delivered_quantity: recive,
              location_id:data.location
          }
      ]
  }
  fetch(`${base_url}mark-products-as-delivered`, {
    method: 'POST',
    // mode:"no-cors",
    headers: {
      'Content-Type': 'application/json',
      "Accept": 'application/json', 
      'Authorization': token,  
    },
    body: JSON.stringify(obj)
  })
  .then(response => response.json())
  .then(data => {
    swal({
      title: "Status update SuccessFully",
      text: 'successFully',
      icon: "success",
    })
    console.log("1234567890",obj);
    console.log(data);
    // handleClose1()

    })
  handleClose1()

  }
  return (
    <div className='create_po'>
      <Topbar />
      <div className='row'>
        <div className='col-5'>
          <h6>Purchase Order Edit - PO{data?.reference}</h6>
        </div>
        <div className='col-7'>
          <ExtendPO value={data?.poExtends} id={data?.id}/>
         <AutidLog value={data?.auditLogs}/>
          <NotePO value={data?.poNotes}/>
          <button style={{ background: "#859CB6", color: "whitesmoke" }} onClick={() => editPo(data)}><BiSave /> Save</button>
          <button onClick={() => deletePO(data?.id)}><RiDeleteBin5Line /> Delete</button>
          <button><BiEnvelope /> Email</button>
          <Print data={data}/>
        </div>
      </div>
      <div className='row '>
        <div className='col-5'>

          <label>Status :</label>
          <input type='text' disabled={true} style={{ cursor: "not-allowed" }} value={data?.status} className='form-control' />
          <button onClick={handleOpen} className='mx-2'> To Open</button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              {/* {data.products.length>=0?<div>Pending</div>:<div>open</div>} */}
              <div style={{ padding: "1rem", background: "#D9EDF7", color: "#3A87AD" }}>
                <p>Are you sure?</p>
              </div>
              <div style={{ background: "#FAFDFF", color: "gray", padding: "1rem" }}>

                <p style={{ fontSize: "13px" }}>Change status of the Purchase Order to 'Open'?</p>
                <p style={{ fontSize: "13px" }}>Stock Due for the selected location will be incremented by the quantity of items on order.</p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button onClick={sendData} style={{ padding: ".5rem 2.5rem", color: "gray", background: "#D9EDF7", border: "1px solid lightgray", margin: '0 10px' }}>Yes</button>
                  <button onClick={handleClose} style={{ padding: ".5rem 2.5rem", color: "gray", background: "#D9EDF7", border: "1px solid lightgray" }}>No</button>
                </div>
              </div>
            </Box>
          </Modal>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Reference:</label>
            </div>
            <div className='col-8'>
              <input type='text' onChange={(e) => setdata({ ...data, reference: e.target.value })} value={data?.reference} />
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Supplier Ref:</label>
            </div>
            <div className='col-8'>
              <input onChange={(e) => setdata({ ...data, supplier_ref: e.target.value })} type='text' value={data?.supplier_ref} />

            </div>
          </div>

        </div>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Date:</label>
            </div>
            <div className='col-8'>
              <input value={data?.date} onChange={(e) => setdata({ ...data, date: e.target.value })} type='date' />

            </div>
          </div>

        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Location:</label>
            </div>
            <div className='col-8'>
              {/* <input type='text'  /> */}
              <select value={data?.location} onChange={(e) => setdata({ ...data, location: e.target.value })}>
                <option hidden></option>

                {wareHouse?.map((item) => {

                  return (

                    <option value={item.id}>{item.name}</option>
                  )

                })

                }
              </select>

            </div>
          </div>

        </div>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label >Supplier:</label>
            </div>
            <div className='col-8'>
              <select value={data.supplier} onChange={(e) => setdata({ ...data, supplier: e.target.value })}>
                <option hidden></option>
                {supplier?.map((item) => {

                  return (

                    <option value={item.id}>{item.supplier_name}</option>
                  )

                })

                }
              </select>

            </div>
          </div>

        </div>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Expected:</label>
            </div>
            <div className='col-8'>
              <input value={data?.expected} onChange={(e) => setdata({ ...data, expected: e.target.value })} type='date' />

            </div>
          </div>

        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Invoicing currency:</label>
            </div>
            <div className='col-8'>
              <select value={data?.invoicing_currency} onChange={(e) => setdata({ ...data, invoicing_currency: e.target.value })} style={{ width: "80px" }}>
                <option hidden></option>
                {currencyList?.map((item) => {
                  return (
                    <option value={item.id}>{item.name}</option>
                  )
                })}

              </select>

            </div>
          </div>

        </div>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Unit Cost amounts:</label>
            </div>
            <div className='col-8'>
              <select value={data?.unit_cost_amounts} onChange={(e) => setdata({ ...data, unit_cost_amounts: e.target.value })}>
                <option hidden></option>
                <option value={"Tax Exclusive"}>Tax Exclusive</option>
                <option value={"Tax Inclusive"}>Tax Inclusive</option>
                <option value={"No Tax"}>No Tax</option>
              </select>

            </div>
          </div>

        </div>
        <div style={{ display: "flex", justifyContent: "end" }} className='col-12'>
          {data?.status == "OPEN" || data?.status=="Partial" ?<>
          <button onClick={handleOpen1}><RiDeleteBin5Line className='mx-1' />Deliver Item</button>
            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style1}>
                <div style={{ padding: "1rem", display: "flex", justifyContent: "space-between", background: "#44688C", color: "white" }}>
                  <h4>Deliver Item</h4><button onClick={handleClose1} style={{ background: "none", border: "none" }}><Close style={{ color: "lightcyan" }} /></button>
                </div>

                <div style={{ background: "#F9F9F9", padding: "1rem" }}>
                  <div className='row my-2'>
                    <div className='col-4'>
                      <p style={{ fontWeight: "bold" }}>SKU</p>
                    </div>
                    <div className='col-8'>
                      <p>{select[0]?.item_no}</p>
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-4'>
                      <p style={{ fontWeight: "bold" }}>Title</p>
                    </div>
                    <div className='col-8'>
                      <p>{select[0]?.title}</p>
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-4'>
                      <p style={{ fontWeight: "bold" }}>To Location</p>
                    </div>
                    <div className='col-8'>
                      <p>{select[0]?.item_no}</p>
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-4'>
                      <p style={{ fontWeight: "bold" }}>Quantity</p>
                    </div>
                    <div className='col-8'>
                      <p>{select[0]?.qty}</p>
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-4'>
                      <p style={{ fontWeight: "bold" }}>Delivered</p>
                    </div>
                    <div className='col-8'>
                      <p>0</p>
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-4'>
                      <p style={{ fontWeight: "bold" }}>Add to Delivered</p>
                    </div>
                    <div className='col-8'>
                      <input  onChange={(e)=>setrecive(e.target.value)} type='number' style={{ width: "200px", border: "1px solid lightgray", padding: ".3rem" }} />

                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-4'>
                      <p style={{ fontWeight: "bold" }}>Total Delivered</p>
                    </div>
                    <div className='col-8'>
                      <p>0</p>
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-4'>
                      <p style={{ fontWeight: "bold" }}>Print Stock Label</p>
                    </div>
                    <div className='col-8'>
                      <input type='checkbox' />
                    </div>
                  </div>
                  <div className='row my-2'>
                    <div className='col-4'>
                      <p style={{ fontWeight: "bold" }}>Template</p>
                    </div>
                    <div className='col-8'>
                      <select style={{ width: "200px", border: "1px solid lightgray", padding: ".3rem" }}>
                        <option>default</option>
                      </select>
                      {/* <p>002018-885-Crosbody-Green</p> */}
                    </div>
                  </div>
                  <div style={{ display: "flex", background: "#F9F9F9", justifyContent: "end", borderTop: "1px solid lightgray", padding: ".5rem" }}>
                    <button onClick={sendData2} style={{ padding: ".5rem 1.5rem", color: "white", background: "#5C7FA5", border: "1px solid lightgray", margin: '0 10px' }}>Delivered</button>
                    <button onClick={handleClose1} style={{ padding: ".5rem 1.5rem", color: "gray", background: "#EAEBEC", border: "1px solid lightgray" }}>Close</button>
                  </div>
                  {/* <p style={{fontSize:"13px"}}>Change status of the Purchase Order to 'Open'?</p>
            <p style={{fontSize:"13px"}}>Stock Due for the selected location will be incremented by the quantity of items on order.</p>
            <div style={{display:"flex",justifyContent:"center"}}>
              <button style={{padding:".5rem 2.5rem",color:"gray",background:"#D9EDF7",border:"1px solid lightgray",margin:'0 10px'}}>Yes</button>
              <button onClick={handleClose1}  style={{padding:".5rem 2.5rem",color:"gray",background:"#D9EDF7",border:"1px solid lightgray"}}>No</button>
            </div> */}
                </div>
              </Box>
            </Modal>
            <button onClick={sendData1}><RiDeleteBin5Line className='mx-1' />Deliver All</button>
            <AddProductPO azab={azab} setdata={setdata} data={data} />
          <button><RiDeleteBin5Line className='mx-1' />Delete Item</button>
             </>:""}
            {singelPo?.status == "Delivered"?<>
          <button style={{cursor:"not-allowed"}} disabled><TiArrowBack className='mx-1' />Return to Supplier</button></>:""}
       
          <button><ImDownload2 className='mx-1' />Export</button>

        </div>

      </div>
      <div className='table_box'>
        <div className='table-responsive'>

          <table class="table table-sm">
            <thead>
              <tr>

                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>SKU</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>

                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Sup.Code</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Spu.Barcode</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Supplier</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Title</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Quantity</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Unit Cost</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Tax Rate</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Tax</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Line Total</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>



              </tr>
            </thead>
            <tbody>
              {data?.products?.map((item, i) => {
                return (
                  <tr onClick={(e) => getselect(item, e)} style={{ background: `${isRowSelected(item) ? "#93B1C9" : ""}`, color: `${isRowSelected(item?.id) ? "white" : ""}` }}>
                    <td><input style={{ border: "none", background: "none", color: "gray" }} onChange={(e) => {
                      const newData = [...data.products];
                      newData[i].item_no = e.target.value;
                      setdata({ ...data, products: newData });
                    }} value={item.item_no} /></td>
                    <td>{item.supplier_code}</td>
                    <td>{item.supplier_barcode}</td>
                    {/* <td>Shabby</td> */}

                    <td>{data?.supplier_name}</td>
                    <td><input style={{ border: "none", background: "none", color: "gray" }} onChange={(e) => {
                      const newData = [...data.products];
                      newData[i].title = e.target.value;
                      setdata({ ...data, products: newData });
                    }} value={item.title} /></td>

                    <td><input onChange={(e) => {
                      const newData = [...data.products];
                      newData[i].qty = e.target.value;
                      setdata({ ...data, products: newData });
                    }} style={{ border: "none", background: "none", color: "gray" }} value={item?.qty} /></td>

                    <td><input onChange={(e) => {
                      const newData = [...data.products];
                      newData[i].unit_cost = e.target.value;
                      setdata({ ...data, products: newData });
                    }} style={{ border: "none", background: "none", color: "gray" }} value={item?.unit_cost} /></td>
                    <td><input onChange={(e) => {
                      const newData = [...data.products];
                      newData[i].tax = e.target.value;
                      setdata({ ...data, products: newData });
                    }} style={{ border: "none", background: "none", color: "gray" }} value={item?.tax} /></td>
                    <td>{"0.8%"}</td>
                    <td>{item?.line_total}</td>
                    {/* <td><LiaEdit style={{fontSize:"22px"}}/></td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}

export default EditPO