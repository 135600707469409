import React from 'react'
import Rechart from './Rechart'

function StockForcasting() {
  return (
    <div className='stock_forcasting'>
      <h6>Stock Forcasting Parameters:</h6>
      <div className='row'>
    <div className='col-3'>
      <label for="Categore" className='mx-4'>Forcasting Using:</label>
   
    </div>
    <div className='col-9'>
    <select id='Categore'  className=' py-1 px-2 rounded border' type='text' placeholder='Categore'>
      <option hidden>This is Stock item</option>
   
      </select>
    </div>
  </div>
  <div className='row'>
    <div className='col-3'>
      <label for="Categore" className='mx-4'>Consumption Period:</label>
   
    </div>
    <div className='col-9'>
    <input id='Categore'  className=' py-1 px-2 rounded border' type='text' placeholder='Categore'/>
    <span>(days)</span>

    </div>
  </div>
  <div className='row'>
    <div className='col-3'>
      <label for="Categore" className='mx-4'>Default Reorder Quantity:</label>
   
    </div>
    <div className='col-9'>
    <input id='Categore'  className=' py-1 px-2 rounded border' type='text' placeholder='Categore'/>
    <span>(Unit)</span>

    </div>
  </div>
  <div className='row'>
    <div className='col-3'>
      <label for="Categore" className='mx-4'>Reorder amount:</label>
   
    </div>
    <div className='col-9'>
    <input id='Categore'  className=' py-1 px-2 rounded border' type='text' placeholder='Categore'/>
      <span>(2x lead time)</span>
    </div>
  </div>
  <Rechart/>
    </div>
  )
}

export default StockForcasting