import { Add, Close, Delete } from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { FiSave } from 'react-icons/fi'
import { useStateContext } from '../../Context/ContextProvider';
import Tine from '../Tine';
import ListingRule from '../ListingRule';
const style = {
  position: 'absolute',
  top: '47%',
  left: '50%',
  // transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
};
function ListingDescription({pricing_descriptions,product_descriptions,title_descriptions,value,setvalue}) {
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const { setpricingDescription, getsource, getsubsource, subsource, source, settitleDescription, setproductDescription } = useStateContext()
  useEffect(() => {
    getsource()
    getsubsource()
  }, [])
  useEffect(() => {
    setTableData(pricing_descriptions)
    setTableData1(title_descriptions)
    setTableData2(product_descriptions)
    console.log(pricing_descriptions,product_descriptions,title_descriptions);
  }, [pricing_descriptions,product_descriptions,title_descriptions])
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const handleOpen = () => {
    setOpen(true);

  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen1 = () => {
    setOpen1(true);

  };
  const handleClose1 = () => {
    setOpen1(false);
    console.log("heelo1");
  };
  const handleOpen2 = () => {
    setOpen2(true);

  };
  const handleClose2 = () => {
    console.log("heelo2");
    setOpen2(false);
  };
  const [row, setrow] = useState({
    source_id: "",
    subsource_id: "",
    price: "",
    tags: "",
    rules: [],
  })
  const [row2, setrow1] = useState({
    source_id: "",
    subsource_id: "",
    title: "",




  })
  const [row3, setrow2] = useState({
    source_id: "",
    subsource_id: "",
    description: "",


  })

  const addRow = () => {
    setTableData([...tableData, row])
  
  }
  console.log(row);
  const addRow1 = () => {
    setTableData1([...tableData1, row2])
  
  }
  const addRow2 = () => {
    setTableData2([...tableData2, row3])
  
  }
  const [tableData, setTableData] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
console.log(tableData);
  const handleInputChange = (e, rowIndex, columnName) => {
    const updatedData = [...tableData];
    updatedData[rowIndex][columnName] = e.target.value;
    setpricingDescription(updatedData)
    setvalue({...value,pricing_descriptions:updatedData})
    setTableData(updatedData);

  };
  const handleInputChange2 = (e, rowIndex, columnName) => {
    const updatedData = [...tableData1];
    updatedData[rowIndex][columnName] = e.target.value;
    setTableData1(updatedData);
    setvalue({...value,title_descriptions:updatedData})
    
    settitleDescription(updatedData)
  };
  const handleInputChange3 = (e, rowIndex, columnName) => {
    const updatedData = [...tableData2];
    updatedData[rowIndex][columnName] = e.target.value;
    setTableData2(updatedData);
    setvalue({...value,product_descriptions:updatedData})
    // pricing_descriptions,product_descriptions,title_descriptions
    setproductDescription(updatedData)

  };
  useEffect(()=>{
    
    setproductDescription(tableData2)
  },[tableData2])
  const getData = () => {
    addRow()
    setrow({
      source_id: "",
      subsource_id: "",
      price: "",
      tags: "",
      rules: [],
    })
    handleClose()
  }
  const getData1 = () => {
    addRow1()
    handleClose1()
    setrow1({
      source_id: "",
      subsource_id: "",
      title: "",
    })
  }
  const getData2 = () => {
    addRow2()
    setrow2({
      source_id: "",
      subsource_id: "",
      description: "",
    })
    handleClose2()
  }
  console.log(tableData);
  const [defult, setdefalt] = useState(false)
  const [multipulSelct,setmultipulSelct]=useState([])
  const [select,setselect]=useState([])
  const getselect = (id, event) => {
    if (event.ctrlKey || event.metaKey) {
      setselect([...select, id]);
      setmultipulSelct([...multipulSelct, id])
    } else {
      setselect([id]);
      setmultipulSelct([id])
    }
  }
  const isRowSelected = (id) => select.includes(id);
  const deleteFun = () => {
    var array = tableData.filter((item, i) => {
      return !select.includes(i);
    });
  
    setTableData(array)
  };
  const [multipulSelct1,setmultipulSelct1]=useState([])
  const [select1,setselect1]=useState([])
  const getselect1 = (id, event) => {
    if (event.ctrlKey || event.metaKey) {
      setselect1([...select1, id]);
      setmultipulSelct1([...multipulSelct1, id])
    } else {
      setselect1([id]);
      setmultipulSelct1([id])
    }
  }
  const isRowSelected1 = (id) => select1.includes(id);
  const deleteFun1 = () => {
    var array = tableData1.filter((item, i) => {
      return !select1.includes(i);
    });
  
    setTableData1(array)
  };
  const [multipulSelct2,setmultipulSelct2]=useState([])
  const [select2,setselect2]=useState([])
  const getselect2 = (id, event) => {
    if (event.ctrlKey || event.metaKey) {
      setselect2([...select2, id]);
      setmultipulSelct2([...multipulSelct2, id])
    } else {
      setselect2([id]);
      setmultipulSelct2([id])
    }
  }
  const isRowSelected2 = (id) => select2.includes(id);
  const deleteFun2 = () => {
    var array = tableData2.filter((item, i) => {
      return !select2.includes(i);
    });
  
    setTableData2(array)
  };
  const getDesc=()=>{

    setvalue({...value,product_descriptions:tableData2})

}
  return (
    <>
      <div className='Createsuppliers listing_discription'>

        <div className='row'>
          <div className='col-8'>
            <h5>Product pricing by channel:</h5>
          </div>
          <div className='col-4'>
            <button onClick={handleOpen} type="button" className='btn1' >
              <Add /> Add new
            </button>

            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box sx={{ ...style, width: 500 }}>
                <div className='modal_body' style={{ height: "40vh" }}>
                  <div className='py-2 px-2'>
                    <div className='row my-3'>
                      <div className='col-4' style={{ position: "relative" }}>
                        <label style={{ position: "absolute", right: "0%" }}>Default:</label>
                      </div>
                      <div className='col-8'>
                        <input onChange={(e) => setrow({ ...row, source_id: e.target.checked ? "Default" : "" })} style={{ border: "1px solid lightgray", padding: ".4rem" }} type='checkbox' />
                      </div>
                    </div>
                    {row.source_id !== "Default" ?
                      <>
                        <div className='row my-3'>
                          <div className='col-4' style={{ position: "relative" }}>
                            <label style={{ position: "absolute", right: "0%" }}>Source:</label>
                          </div>
                          <div className='col-8'>
                            <select onChange={(e) => setrow({ ...row, source_id: e.target.value })} style={{ padding: "0.5rem", border: "1px solid lightgray" }}>
                              <option hidden>AMAZON</option>
                              {source?.map((item) => {
                                return (

                                  <option value={item.id}>{item.name}</option>
                                )
                              })}



                            </select>
                          </div>
                        </div>
                        <div className='row my-3'>
                          <div className='col-4' style={{ position: "relative" }}>
                            <label style={{ position: "absolute", right: "0%" }}>Sub Source:</label>
                          </div>
                          <div className='col-8'>
                            <select onChange={(e) => setrow({ ...row, subsource_id: e.target.value })} style={{ padding: "0.5rem", border: "1px solid lightgray" }}>
                              <option hidden>ASIN</option>
                              {subsource?.map((item) => {
                                return (

                                  <option value={item.id}>{item.name}</option>
                                )
                              })}

                            </select>
                          </div>
                        </div>
                      </>
                      : ""}
                    <div className='row my-3'>
                      <div className='col-4' style={{ position: "relative" }}>
                        <label style={{ position: "absolute", right: "0%" }}>Price:</label>
                      </div>
                      <div className='col-8'>
                        <input onChange={(e) => setrow({ ...row, price: e.target.value })} style={{ border: "1px solid lightgray", padding: ".4rem" }} type='text' />
                      </div>
                    </div>


                  </div>

                </div>
                <div className='model_footer'>
                  <div className='row'>
                    <div className='col-7'></div>
                    <div className='col-5'>
                      <button className='btn1 px-3' onClick={getData}><FiSave /> Add</button>
                      <button className='btn2' onClick={handleClose}>Cancel</button>
                    </div>
                  </div>

                </div>
              </Box>
            </Modal>
            <button type="button" className='btn2' onClick={deleteFun}  >
              <Delete />   Delete
            </button>
          </div>
        </div>

        <div className='table-responsive'>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Source</th>
                <th scope="col">SubSource</th>
                <th scope="col">Price</th>
                <th scope="col">Tag</th>
                <th scope="col">Rules</th>
              </tr>
            </thead>
            <tbody>
              {tableData?.map((item, index) => {
                return (
                  <tr onClick={(e)=>getselect(index, e)} style={{ background: `${isRowSelected(index) ? "#93B1C9" : ""}`,color: `${isRowSelected(index) ? "white" : ""}`}}>
                    <td>{
                      item.source_id=="Default"?<p style={{color:"red"}}>Default</p>: <select disabled={true} onChange={(e) => handleInputChange(e, index, 'source_id')} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                          <option hidden></option>

                      {source?.map((item) => {
                        return (

                          <option value={item.id}>{item.name}</option>
                        )
                      })}

                    </select>
                      }
                      {/* <input style={{color:`${item.source=="Default"?"red":""}`}}  onChange={(e) => handleInputChange(e, index, 'source')} value={item.source}/> */}
                     
                    </td>
                    <td>{
                      item.source_id=="Default"?"": <select disabled={true} onChange={(e) => handleInputChange(e, index, 'source_id')} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                          <option hidden></option>
                      {subsource?.map((item) => {
                        return (

                          <option value={item.id}>{item.name}</option>
                        )
                      })}

                    </select>
                      }
                      {/* <input style={{color:`${item.source=="Default"?"red":""}`}}  onChange={(e) => handleInputChange(e, index, 'source')} value={item.source}/> */}
                     
                    </td>
                    <td><input onChange={(e) => handleInputChange(e, index, 'price')} value={item.price} /></td>
                    <td><input onChange={(e) => handleInputChange(e, index, 'tags')} value={item.tags} /></td>
                    <td> <ListingRule value={tableData} price={item.price} rule={item.tags?false:true} setValue={setTableData} idx={index}/></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

      </div>
      <div className='Createsuppliers listing_discription'>

        <div className='row'>
          <div className='col-8'>
            <h5>Product titles by channel:</h5>
          </div>
          <div className='col-4'>
            <button onClick={handleOpen1} type="button" className='btn1' >
              <Add /> Add new
            </button>

            <Modal
              open={open1}
              onClose={handleClose1}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box sx={{ ...style, width: 500 }}>
                {/* <div className='modal-heade'>
<div className='row'>
    <div className='col-10'>
    <h6>Product titles by channel:</h6>
    </div>
    <div className='col-2'>
        <button onClick={handleClose1} className='closeBtn'>
            <Close/>
        </button>
    </div>
</div>

</div> */}
                <div className='modal_body' style={{ height: "40vh" }}>
                  <div className='py-2 px-2'>
                    <div className='row my-3'>
                      <div className='col-4' style={{ position: "relative" }}>
                        <label style={{ position: "absolute", right: "0%" }}>Default:</label>
                      </div>
                      <div className='col-8'>
                        <input onChange={(e)=>setrow1({...row2,source_id:e.target.checked?"Default":""})} style={{ border: "1px solid lightgray", padding: ".4rem" }} type='checkbox' />
                      </div>
                    </div>
                 { row2.source_id=="Default"?"":<>
                   <div className='row my-3'>
                      <div className='col-4' style={{ position: "relative" }}>
                        <label style={{ position: "absolute", right: "0%" }}>Source:</label>
                      </div>
                      <div className='col-8'>
                        <select onChange={(e) => setrow1({ ...row2, source_id: e.target.value })} style={{ padding: "0.5rem", border: "1px solid lightgray" }}>
                          <option hidden>ASIN</option>
                          {source?.map((item) => {
                            return (

                              <option value={item.id}>{item.name}</option>
                            )
                          })}



                        </select>
                      </div>
                    </div>
                    <div className='row my-3'>
                      <div className='col-4' style={{ position: "relative" }}>
                        <label style={{ position: "absolute", right: "0%" }}>Sub Source:</label>
                      </div>
                      <div className='col-8'>
                        <select onChange={(e) => setrow1({ ...row2, subsource_id: e.target.value })} style={{ padding: "0.5rem", border: "1px solid lightgray" }}>
                          <option hidden>ASIN</option>
                          {subsource?.map((item) => {
                            return (

                              <option value={item.id}>{item.name}</option>
                            )
                          })}


                        </select>
                      </div>
                    </div>
                    <div className='row my-3'>
                      <div className='col-4' style={{ position: "relative" }}>
                        <label style={{ position: "absolute", right: "0%" }}>Title:</label>
                      </div>
                      <div className='col-8'>
                        <input onChange={(e) => setrow1({ ...row2, title: e.target.value })} style={{ border: "1px solid lightgray", padding: ".4rem" }} type='text' />
                      </div>
                    </div>
                    </>
                    }

                  </div>

                </div>
                <div className='model_footer'>
                  <div className='row'>
                    <div className='col-7'></div>
                    <div className='col-5'>
                      <button className='btn1 px-3' onClick={getData1}><FiSave /> Add</button>
                      <button className='btn2' onClick={handleClose1}>Cancel</button>
                    </div>
                  </div>

                </div>
              </Box>
            </Modal>
            <button type="button" className='btn2' onClick={deleteFun1} >
              <Delete />   Delete
            </button>
          </div>
        </div>

        <div className='table-responsive'>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Source</th>
                <th scope="col">SubSource</th>
                <th scope="col">Title</th>



              </tr>
            </thead>
            <tbody>
              {tableData1?.map((item, index) => {
                return (
                  <tr onClick={(e)=>getselect1(index, e)} style={{ background: `${isRowSelected1(index) ? "#93B1C9" : ""}`,color: `${isRowSelected1(index) ? "white" : ""}`}}>
                    <td>{
                      item.source_id=="Default"?<p style={{color:"red"}}>Default</p>: <select disabled={true} onChange={(e) => handleInputChange(e, index, 'source_id')} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                          <option hidden></option>

                      {source?.map((item) => {
                        return (

                          <option value={item.id}>{item.name}</option>
                        )
                      })}

                    </select>
                      }
                      {/* <input style={{color:`${item.source=="Default"?"red":""}`}}  onChange={(e) => handleInputChange(e, index, 'source')} value={item.source}/> */}
                     
                    </td>
                    <td>{
                      item.source_id=="Default"?"": <select disabled={true} onChange={(e) => handleInputChange(e, index, 'source_id')} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                          <option hidden></option>

                      {subsource?.map((item) => {
                        return (

                          <option value={item.id}>{item.name}</option>
                        )
                      })}

                    </select>
                      }
                      {/* <input style={{color:`${item.source=="Default"?"red":""}`}}  onChange={(e) => handleInputChange(e, index, 'source')} value={item.source}/> */}
                     
                    </td>
                    <td><input onChange={(e) => handleInputChange2(e, index, 'title')} value={item.title} /></td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

      </div>
      <div className='Createsuppliers listing_discription'>

        <div className='row'>
          <div className='col-8'>
            <h5>Product descriptions by channel:</h5>
          </div>
          <div className='col-4'>
            <button onClick={handleOpen2} type="button" className='btn1' >
              <Add /> Add new
            </button>

            <Modal
              open={open2}
              onClose={handleClose2}
              aria-labelledby="child-modal-title"
              aria-describedby="child-modal-description"
            >
              <Box sx={{ ...style, width: 500 }}>

                <div className='modal_body' style={{ height: "30vh" }}>
                  <div className='py-2 px-2'>
                    <div className='row my-3'>
                      <div className='col-4' style={{ position: "relative" }}>
                        <label style={{ position: "absolute", right: "0%" }}>Default:</label>
                      </div>
                      <div className='col-8'>
                        <input onChange={(e)=>setrow2({...row3,source_id:e.target.checked?"Default":""})}  style={{ border: "1px solid lightgray", padding: ".4rem" }} type='checkbox' />
                      </div>
                    </div>
                  {row3.source_id=="Default"?"":<>  <div className='row my-3'>
                      <div className='col-4' style={{ position: "relative" }}>
                        <label style={{ position: "absolute", right: "0%" }}>Source:</label>
                      </div>
                      <div className='col-8'>
                        <select onChange={(e) => setrow2({ ...row3, source_id: e.target.value })} style={{ padding: "0.5rem", border: "1px solid lightgray" }}>
                          <option hidden>ASIN</option>
                          {source?.map((item) => {
                            return (

                              <option value={item.id}>{item.name}</option>
                            )
                          })}



                        </select>
                      </div>
                    </div>
                    <div className='row my-3'>
                      <div className='col-4' style={{ position: "relative" }}>
                        <label style={{ position: "absolute", right: "0%" }}>Sub Source:</label>
                      </div>
                      <div className='col-8'>
                        <select onChange={(e) => setrow2({ ...row3, subsource_id: e.target.value })} style={{ padding: "0.5rem", border: "1px solid lightgray" }}>
                          <option hidden>ASIN</option>
                          {subsource?.map((item) => {
                            return (

                              <option value={item.id}>{item.name}</option>
                            )
                          })}

                        </select>
                      </div>
                    </div></>}


                  </div>

                </div>
                <div className='model_footer'>
                  <div className='row'>
                    <div className='col-7'></div>
                    <div className='col-5'>
                      <button className='btn1 px-3' onClick={getData2}><FiSave /> Add</button>
                      <button className='btn2' onClick={handleClose2}>Cancel</button>
                    </div>
                  </div>

                </div>
              </Box>
            </Modal>
            <button type="button" className='btn2'  onClick={deleteFun2}  >
              <Delete />   Delete
            </button>
          </div>
        </div>

        <div className='table-responsive'>

          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">Source</th>
                <th scope="col">SubSource</th>
                <th scope="col">description</th>




              </tr>
            </thead>
            <tbody>
              {tableData2?.map((item, index) => {
                return (
                  <tr onClick={(e)=>getselect2(index, e)} style={{ background: `${isRowSelected2(index) ? "#93B1C9" : ""}`,color: `${isRowSelected2(index) ? "white" : ""}`}}>
                    <td>{
                      item.source_id=="Default"?<p style={{color:"red"}}>Default</p>: <select disabled={true} onChange={(e) => handleInputChange(e, index, 'source_id')} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                          <option hidden></option>

                      {source?.map((item) => {
                        return (

                          <option value={item.id}>{item.name}</option>
                        )
                      })}

                    </select>
                      }
                      {/* <input style={{color:`${item.source=="Default"?"red":""}`}}  onChange={(e) => handleInputChange(e, index, 'source')} value={item.source}/> */}
                     
                    </td>
                    <td>
                    <td>{
                      item.source_id=="Default"?"": <select disabled={true} onChange={(e) => handleInputChange(e, index, 'source_id')} value={item.source_id} style={{ padding: "0.5rem", border: "none", background: "none", width: "200px" }}>
                          <option hidden></option>

                      {subsource?.map((item) => {
                        return (

                          <option value={item.id}>{item.name}</option>
                        )
                      })}

                    </select>
                      }
                      {/* <input style={{color:`${item.source=="Default"?"red":""}`}}  onChange={(e) => handleInputChange(e, index, 'source')} value={item.source}/> */}
                     
                    </td>
                    </td>
                    <td>
                      {/* <input onChange={(e) => handleInputChange3(e, index, 'description')} value={item.description} /> */}
                     
                      <Tine setTableData2={setTableData2} getDesc={getDesc} tableData2={tableData2} index={index} value={item.description}/>


                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

      </div>
    </>
  )
}

export default ListingDescription