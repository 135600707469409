import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import {Add, Delete } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close } from '@mui/icons-material';
import { FiSave } from 'react-icons/fi';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 370,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
  };
function InventoryModal({data,ware_id,setvalue1,value1}) {
    const useData=data?data:[]
    const{setinventory,inventory,WareHouse,wareHouse,setwareid}=useStateContext()
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    console.log(data);
    useEffect(()=>{
        WareHouse()
    },[])
    const [value,setvalue]=useState({
        binrack: "",
        batch_no: "",
        status: "",
        sell_by: "",
        unit_cost:"",
        level: "",
        value: "",
        priority:"",
        expires_on:"",
        warehouse_id: ""
    })
    console.log(inventory);
    const addRow = () => {
        var obj = {
            binrack: "",
            batch_no: "",
            status: "",
            sell_by: "",
            level: "",
            value: "",
            expires_on:"",
        }



        setTableData([...tableData, obj])


    }
    const [tableData, setTableData] = useState([
      
  

    ]);
    useEffect(()=>{
        if(data){
            setTableData(data)
        
        }
        else{
            setTableData([
                {
                    binrack: "",
                    batch_no: "",
                    status: "",            
                    level: "",
                    value: "",

                }
            ])
        }
    },[data])

    const handleInputChange = (e, rowIndex, columnName) => {
        const updatedData = [...tableData];
        updatedData[rowIndex][columnName] = e.target.value;
        // setinventory(updatedData[updatedData.length-1])
        setTableData(updatedData);
        setvalue1({...value1,pro_inventory:updatedData})

    };
    const [multipulSelct,setmultipulSelct]=useState([])
    const [select,setselect]=useState([])
    const getselect = (id, event) => {
      if (event.ctrlKey || event.metaKey) {
        setselect([...select, id]);
        setmultipulSelct([...multipulSelct, id])
      } else {
        setselect([id]);
        setmultipulSelct([id])
      }
    }
    const isRowSelected = (id) => select.includes(id);
    const deleteFun = () => {
      var array = tableData.filter((item, i) => {
        return !select.includes(i);
      });
    
      setTableData(array)
      setvalue1({...value1,pro_inventory:array})

    };
        const save=()=>{
        setTableData([...tableData,value])
        handleClose()
        var array=tableData
        array.push(value)
        setvalue1({...value1,pro_inventory:array})


 
        
        // console.log("heelo");
    }
    return (
        <div className='Createsuppliers listing_discription'>
    
    <div style={{display:"flex",justifyContent:"space-between"}} >
            <div >
                <h5>Inventory:</h5>
            </div>
            <div >
            <select onChange={(e)=>setwareid(e.target.value)} style={{width:"200px",padding:".3rem",border:"1px solid lightgray",marginRight:"1rem"}}>
                        {wareHouse?.map((item)=>{
                return(

                    <option value={item.id}>{item.name}</option>
                )

            })
        }
                        </select>
                {useData[0]?.sell_by ||useData[0]?.priority? <>
                <button onClick={handleOpen} style={{border:"1px solid lightgray", padding:".3rem",marginRight:"1rem"}}><Add/>Add New</button>
                {value.batch_type==0?<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
  <div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                        <h5>Business pricing rules - io</h5>
                        <div>
                            {/* <button style={{ border: "none", background: "none" }}><VscChromeRestore style={{ color: "whitesmoke", fontSize: "20px" }} /></button> */}
                            <button onClick={() => handleClose()} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                        </div>
                    </div>
                    <div className='main_box' style={{height:"70vh",background:"white"}}>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Batch No:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input onChange={(e)=>setvalue({...value,batch_no:e.target.value})}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Sell by:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input onChange={(e)=>{
                                    setvalue({...value,sell_by:e.target.value,priority:""})
                                    // setvalue({...value,priority:""})
                                    }}  type='date' style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Expires:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input onChange={(e)=>setvalue({...value,expires_on:e.target.value})}  type='date' style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Bin/rack:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input onChange={(e)=>setvalue({...value,binrack:e.target.value})}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Quantity:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input onChange={(e)=>setvalue({...value,level:e.target.value})}  type='number' style={{border:"1px solid lightgray",padding:".3rem",width:"200px"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Unit cost:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input type='number' onChange={(e)=>{
                                    setvalue({...value,unit_cost:e.target.value,value:e.target.value*value.level})
                                    // setvalue({...value,value:e.target.value*value.level})
                                    }} style={{border:"1px solid lightgray",padding:".3rem",width:"200px"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Status:</label>
                            </div>
                            <div  style={{padding:"1rem"}} className='col-8'>
                                <select onChange={(e)=>setvalue({...value,status:e.target.value})} style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}>
                                    <option value={"Available"}>Available</option>
                                    <option value={"Restricted"}>Restricted</option>
                                    <option value={"Damaged"}>Damaged</option>
                                    <option value={"Expired"}>Expired</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ backgroundColor: "#f0f0f0", borderTop: "1px solid #dee2e6!important",padding:"1.3rem",paddingRight:"0",width:"100%",margin:"0%" }}>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                        <button onClick={save}  style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button>
                            <button onClick={handleClose} style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                        </div>
                    </div>
  </Box>
</Modal>:  <Modal
open={open}
onClose={handleClose}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
>
<Box sx={style}>
<div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                      <h5>Business pricing rules - io</h5>
                      <div>
                          {/* <button style={{ border: "none", background: "none" }}><VscChromeRestore style={{ color: "whitesmoke", fontSize: "20px" }} /></button> */}
                          <button onClick={() => handleClose()} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                      </div>
                  </div>
                  <div className='main_box' style={{height:"70vh",background:"white"}}>
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Batch No:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                              <input  onChange={(e)=>setvalue({...value,batch_no:e.target.value})}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                          </div>
                      </div>
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Priority:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                              <input onChange={(e)=>{
                                    setvalue({...value,sell_by:"",priority:e.target.value})
                                    // setvalue({...value,priority:e.target.value})
                                    }}   type='number' style={{border:"1px solid lightgray",padding:".3rem",width:"200px"}}/>
                          </div>
                      </div>
                  
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Bin/rack:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                              <input  onChange={(e)=>setvalue({...value,binrack:e.target.value})}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                          </div>
                      </div>
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Quantity:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                              <input type='number'  onChange={(e)=>setvalue({...value,level:e.target.value})}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px"}}/>
                          </div>
                      </div>
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Unit cost:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                              <input type='number' onChange={(e)=>{
                                    setvalue({...value,unit_cost:e.target.value,value:e.target.value*value.level})
                                    // setvalue({...value,value:e.target.value*value.level})
                                    }}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px"}}/>
                          </div>
                      </div>
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Status:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                          <select onChange={(e)=>setvalue({...value,status:e.target.value})} style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}>
                                    <option value={"Available"}>Available</option>
                                    <option value={"Restricted"}>Restricted</option>
                                    <option value={"Damaged"}>Damaged</option>
                                    <option value={"Expired"}>Expired</option>
                                </select>
                          </div>
                      </div>
                  </div>
                  <div className='row' style={{ backgroundColor: "#f0f0f0", borderTop: "1px solid #dee2e6!important",padding:"1.3rem",paddingRight:"0",width:"100%",margin:"0%" }}>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                      <button  onClick={save} style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button>
                          <button onClick={handleClose} style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                      </div>
                  </div>
</Box>
</Modal>}
                <button onClick={deleteFun} style={{border:"1px solid lightgray", padding:".3rem"}}><Delete/>Delete</button>
                </>
                :""}
            </div>
            
        </div>
    
    <div className='table-responsive'>
    
    {useData[0]?.sell_by?
<table className="table table-striped">
        <thead>
            <tr>
            <th scope="col">Bin/Rak</th>
                        <th scope="col">Batch No</th>
                        <th scope="col">Status</th>
                        <th scope="col">Expired on</th>
                        <th scope="col">Sell By</th>
                        <th scope="col">Status</th>
                        <th scope="col">Level</th>
                        <th scope="col">Value </th>
    
    
            </tr>
        </thead>
        <tbody>
            {tableData?.map((item, index) => {
                return (
                  <tr onClick={(e)=>getselect(index, e)} style={{ background: `${isRowSelected(index) ? "#93B1C9" : ""}`,color: `${isRowSelected(index) ? "white" : ""}`}}>
                 <td><input onChange={(e) => handleInputChange(e, index, 'binrack')} value={item.binrack} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'batch_no')} value={item.batch_no} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'status')} value={item.status} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'expires_on')} value={item.expires_on} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'sell_by')} value={item.sell_by} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'level')} type='number' value={item.level} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'value')} type='number' value={item.value} /></td>
                </tr>
                )
            })}
        </tbody>
    </table>
   
   :"" }
{useData[0]?.priority? 
 <table className="table table-striped">
        <thead>
            <tr>
            <th scope="col">Bin/Rak</th>
                        <th scope="col">Batch No</th>
                        <th scope="col">Status</th>
                        <th scope="col">Priority</th>
                        {/* <th scope="col">Status</th> */}
                        <th scope="col">Level</th>
                        <th scope="col">Value </th>
    
    
            </tr>
        </thead>
        <tbody>
            {tableData?.map((item, index) => {
                return (
                  <tr onClick={(e)=>getselect(index, e)} style={{ background: `${isRowSelected(index) ? "#93B1C9" : ""}`,color: `${isRowSelected(index) ? "white" : ""}`}}>
                 <td><input onChange={(e) => handleInputChange(e, index, 'binrack')} value={item.binrack} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'batch_no')} value={item.batch_no} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'status')} value={item.status} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'priority')} value={item.priority} /></td>
                                {/* <td><input onChange={(e) => handleInputChange(e, index, 'sell_by')} value={item.sell_by} /></td> */}
                                <td><input onChange={(e) => handleInputChange(e, index, 'level')} type='number' value={item.level} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'value')} type='number' value={item.value} /></td>
     
                </tr>
                )
            })}
        </tbody>
    </table>
   :
""}
    </div>
    
    </div>
    )
}

export default InventoryModal