import React, { useState } from 'react';
import { FiEdit, FiFilter } from 'react-icons/fi';
import Check from './Edit/Check';
import Checks from './Variation/Checks';
import {MdOutlineAddBox} from 'react-icons/md'
import { useStateContext } from '../Context/ContextProvider';
import Tracked from './Tracked';
import { AiOutlineMinusSquare } from 'react-icons/ai';
const BasicTable = ({ select, setselect, datain, wareHouse }) => {

  console.log(datain);
  const [value, setvalue] = useState({})
  const [edit, setedit] = useState()
  const { setdataInventory, editData,multipulSelct,setmultipulSelct } = useStateContext()
  // const [select,setselect]=useState([])
  const getselect = (id, event) => {
    if (event.ctrlKey || event.metaKey) {

      setselect([...select, id]);
      setmultipulSelct([...multipulSelct, id])
    } else {
      setselect([id]);
      setmultipulSelct([id])
    }
  }
  const headers = ["", 'SKU', 'Title', 'Barcode Number', 'Retail Price', 'Purchase Price', 'Stock Level', 'In Open Orders', 'Availble', 'Minimum Level', 'Tracked'];
  const [columnWidths, setColumnWidths] = useState(() => {
    // Initialize column widths with equal values
    return headers.map(() => 150); // You can adjust the initial width as needed
  });
  const isRowSelected = (id) => select.includes(id);
  const handleColumnResize = (index, width) => {
    const newWidths = [...columnWidths];
    newWidths[index] = width;
    setColumnWidths(newWidths);
  };
  console.log(datain,"datain");
  // const VariationLevel=({pro})=>{
  //   // console.log(pro.pro_inventory.level);
  //   console.log(pro);
  //   var total=pro.pro_inventory.level
  //   return total  
  //   // total=pro.map((item)=>{
  //   //   return item.pro_inventory.level+total
  //   // })
  // }
  // const [showvariation,setshowVariation]=useState(false)
  // const [showvariation1,setshowVariation1]=useState(false)
  const [idx,setidx]=useState()
  const [idx1,setidx1]=useState()
  const show=(i)=>{  
      setidx(i)
      // setshowVariation1(!showvariation1)
  }
  const show1=(i)=>{  
      setidx1(i)
      // setshowVariation(!showvariation)
  }
  const [showVariation, setShowVariation] = useState(Array(datain?.variation?.length).fill(false));

  const toggleVariation = (index) => {
    const newShowVariation = [...showVariation];
    newShowVariation[index] = !newShowVariation[index];
    setShowVariation(newShowVariation);
  };
  const [showVariation1, setShowVariation1] = useState(Array(datain?.products?.length).fill(false));

  const toggleVariation1 = (index) => {
    const newShowVariation = [...showVariation1];
    newShowVariation[index] = !newShowVariation[index];
    setShowVariation1(newShowVariation);
  };
  return (
    <div className='table-responsive'>

      <table style={{minHeight:"13vh"}} className="table table-sm">
        <thead>
          <tr>
            {datain?.columns?.map((item, index) => {
              // <th scope="col" style={{width:"30px"}}></th>
              return (
                <th scope="col" 
                  style={{ width: `${columnWidths[index]}px`, border: "1px solid lightgray", position: "relative" }}
                >
                  <div style={{width:`${index==0?"10px":""}`}} className="row">
                    <div className="col-8" style={{ overflow: "hidden", fontSize: "13px" }}>
                      {index == 0 ? "" : item}
                    </div>

                  </div>
                  <div
                    className="resizable-handle"
                    onMouseDown={(e) => {
                      e.preventDefault();
                      const initialX = e.clientX;
                      const column = e.target.parentElement;
                      const columnIndex = Array.from(
                        column.parentElement.children
                      ).indexOf(column);

                      const handleMouseMove = (e) => {
                        const widthDiff = e.clientX - initialX;
                        const newWidth = Math.max(
                          40, // Minimum column width
                          columnWidths[columnIndex] + widthDiff
                        );
                        handleColumnResize(columnIndex, newWidth);
                      };

                      const handleMouseUp = () => {
                        window.removeEventListener('mousemove', handleMouseMove);
                        window.removeEventListener('mouseup', handleMouseUp);
                      };

                      window.addEventListener('mousemove', handleMouseMove);
                      window.addEventListener('mouseup', handleMouseUp);
                    }}
                  />
                </th>
              )
            })}
            
          </tr>
        </thead>
        <tbody >
        {datain?.variation?.map((item, i) => {
            return (
              <>
              <tr onClick={(e) => getselect(item.sku, e)} style={{display:`${item?.archive==1?"none":""}`, background: `${isRowSelected(item?.sku) ? "#93B1C9" : ""}` }} className={`${i % 2 === 0 ? "bg" : ""}`}>
                <th scope="row"></th>
                <td>
                {showVariation[i]? <AiOutlineMinusSquare onClick={() => toggleVariation(i)} /> :<MdOutlineAddBox onClick={() => toggleVariation(i)} /> } <Checks value={value} pro={item} wareHouse={wareHouse} setvalue={setvalue} data={item.sku} id={item.sku} idx={i} />
                  </td>
            
               {datain?.columns.includes("Title")?<td><div style={{ color: `${isRowSelected(item?.sku) ? "white" : ""}` }} className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input onChange={(e) => {
                      const newData = [...datain.variation];
                      newData[i].name = e.target.value;
                      setdataInventory({ product_type:datain.product_type,
                        columns:datain.columns,
                        products:datain.products,
                        variation:newData});
                        editData(newData[i])
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.sku) ? "white" : "gray"}`,fontSize:"12px" }} value={item.name} />
                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%" }} />
                  </div>
                </div></td>:""}
                {datain?.columns.includes("Barcode Number")?<td><div className="row">
                  <div className="col-10" style={{ overflow: "hidden" }}>
                    <input onChange={(e) => {
                      const newData = [...datain.variation];
                      newData[i].barcode = e.target.value;
                      setdataInventory(newData);
                      editData(newData[i])
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.sku) ? "white" : "gray"}`,fontSize:"12px" }} value={item.products[0]?.barcode} />
                  </div>
                  <div className="col-2">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", color: `${isRowSelected(item?.sku) ? "white" : "gray"}` }} />
                  </div>
                </div></td>:""}
                {datain?.columns.includes("Retail Price")?<td><div className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>-
                  </div>
              
                </div></td>:""}
              {datain?.columns.includes("Purchase Price")?<td><div className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>-

                  </div>
            
                </div></td>:""}
               {datain?.columns.includes("Stock Level")?<td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(item?.sku) ? "white" : "gray"}`,fontSize:"12px" }}>{item.pro_inventory?.level}</span></td>:""}
                {datain?.columns.includes("In Open Orders")?<td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(item?.sku) ? "white" : "gray"}`,fontSize:"12px" }}>0</span></td>:""}
              {datain?.columns.includes("Available")?<td style={{ textAlign: "center", background: `${item?.pro_inventory?.level >"0" ? "#AAC99E" : "#FF7782"}`,fontSize:"12px" }}><span style={{ padding: "0",fontSize:"12px" }}>{item.pro_inventory?.level}</span></td>:""}
                {datain?.columns.includes("Minimum Level")?<td ><span style={{ padding: "0", color: `${isRowSelected(item?.sku) ? "white" : "gray"}`,fontSize:"12px" }}>0</span></td>:""}
                 {datain?.columns.includes("Tracked")?<td>-</td>:""}

               

              
              </tr>
              {showVariation[i] && item?.products?.map((items,idxx)=>{
              return(
                <tr onClick={(e) => getselect(items.id, e)} style={{display:`${items?.archive==1?"none":""}`,  background: `${isRowSelected(items?.id) ? "#93B1C9" : ""}` }} className={`${idxx % 2 === 0 ? "bg" : ""}`}>
                <th scope="row"></th>
                <td className='ps-4'>
                  <Check value={value} pro={items} wareHouse={wareHouse} setvalue={setvalue} data={items.item_no} id={items.id} idx={i} />
                  </td>
                {datain?.columns.includes("Title")?<td><div style={{ color: `${isRowSelected(items?.id) ? "white" : ""}` }} className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input 
                    onBlur={()=>{
                     
                        
                        editData(datain.variation[idx1].products[idxx])
                      
                    }}
                    onChange={(e) => {
                      const newData = [...datain.variation];
                     
                      newData[idx1].products[idxx].title = e.target.value;
            

                      
                      setdataInventory({
                        product_type:datain.product_type,
                        columns:datain.columns,
                        products:datain.products,
                        variation:newData
                      });
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(items?.id) ? "white" : "gray"}`,fontSize:"12px" }} value={items.title} />
                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%" }} />
                  </div>
                </div></td>:""}
                <td><div className="row">
                  <div className="col-10" style={{ overflow: "hidden" }}>
                    <input
                    onBlur={()=>{
                     
                        
                      editData(datain.variation[idx1].products[idxx])
                    
                  }}
                     onChange={(e) => {
                      const newData = [...datain.variation];
                      newData[idx1].products[idxx].barcode = e.target.value;
                      setdataInventory({
                        product_type:datain.product_type,
                        columns:datain.columns,
                        products:datain.products,
                        variation:newData
                      });
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(items?.id) ? "white" : "gray"}`,fontSize:"12px" }} value={items.barcode} />
                  </div>
                  <div className="col-2">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", color: `${isRowSelected(items?.id) ? "white" : "gray"}` }} />
                  </div>
                </div></td>
                <td><div className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input
                      onBlur={()=>{
                     
                        
                        editData(datain.variation[idx1].products[idxx])
                      }}  
                    onChange={(e) => {
                                           const newData = [...datain.variation];

                      newData[idx1].products[idxx].retail_price = e.target.value;
                      setdataInventory({
                        product_type:datain.product_type,
                        columns:datain.columns,
                        products:datain.products,
                        variation:newData
                      });
                    }} style={{ outline: "none", border: "none", color: "gray", width: "60px", color: `${isRowSelected(items?.id) ? "white" : "gray"}` ,fontSize:"12px"}} value={items.retail_price} />

                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(items?.id) ? "white" : "gray"}` }} />
                  </div>
                </div></td>
                <td><div className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input
                      onBlur={()=>{
                     
                        
                        editData(datain.variation[idx1].products[idxx])
                      }}  
                    onChange={(e) => {
                                           const newData = [...datain.variation];

                      newData[idx1].products[idxx].purchase_price = e.target.value;
                      setdataInventory({
                        product_type:datain.product_type,
                        columns:datain.columns,
                        products:datain.products,
                        variation:newData
                      });
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(items?.id) ? "white" : "gray"}`,fontSize:"12px", width: "60px" }} value={items.purchase_price} />

                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(items?.id) ? "white" : "gray"}` }} />
                  </div>
                </div></td>
                {items?.pro_inventory?.level?<td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(items?.id) ? "white" : "gray"}`,fontSize:"12px" }}>{items?.pro_inventory?.level ? items?.pro_inventory?.level : "-"}</span></td>:""}
                <td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(items?.id) ? "white" : "gray"}`,fontSize:"12px" }}>0</span></td>
                {items?.pro_inventory?.level?<td style={{ textAlign: "center", background: `${items?.pro_inventory?.level > "0" ? "#AAC99E" : "#FF7782"}`}}><span style={{ padding: "0",fontSize:"12px" }}>{items?.pro_inventory?.level > "0" ? items?.pro_inventory?.level : 0}</span></td>:""}
                <td style={{background:`${items.min_level<0?"lightgray":''}`}}><div className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input
                     onBlur={()=>{
                     
                        
                      editData(datain.variation[idx1].products[idxx])
                    }}  
                  onChange={(e) => {
                                         const newData = [...datain.variation];

                    newData[idx1].products[idxx].min_level = e.target.value;
                    setdataInventory({
                      product_type:datain.product_type,
                      columns:datain.columns,
                      products:datain.products,
                      variation:newData
                    });
                  }}
                   style={{ outline: "none", border: "none", color: `${isRowSelected(items?.id) ? "white" : "gray"}`,fontSize:"12px", width: "60px" }} value={items.min_level} />

                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(items?.id) ? "white" : "gray"}` }} />
                  </div>
                </div></td>                <td><Tracked data={items} isRowSelected={isRowSelected}/></td>
                 
              
              </tr>
             
              )
             })}
              </>
            )
          })}
             {/* {showvariation?datain?.variation[0]?.products?.map((item,i)=>{
              return(
                <tr onClick={(e) => getselect(item.id, e)} style={{display:`${item?.archive==1?"none":""}`,  background: `${isRowSelected(item?.id) ? "#93B1C9" : ""}` }} className={`${i % 2 === 0 ? "bg" : ""}`}>
                <th scope="row"></th>
                <td className='ps-4'>
                  <Check value={value} pro={item} wareHouse={wareHouse} setvalue={setvalue} data={item.item_no} id={item.id} idx={i} />
                  </td>
                {item.title?<td><div style={{ color: `${isRowSelected(item?.id) ? "white" : ""}` }} className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input onChange={(e) => {
                      const newData = [...datain.products];
                      newData[i].title = e.target.value;
                      // console.log(newdata);
                      setdataInventory({
                        product_type:datain.product_type,
                        columns:datain.columns,
                        products:newData,
                        variation:datain.variation
                      });
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }} value={item.title} />
                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%" }} />
                  </div>
                </div></td>:""}
                <td><div className="row">
                  <div className="col-10" style={{ overflow: "hidden" }}>
                    <input onChange={(e) => {
                      const newData = [...datain.products];
                      newData[i].barcode = e.target.value;
                      setdataInventory(newData);
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }} value={item.barcode} />
                  </div>
                  <div className="col-2">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", color: `${isRowSelected(item?.id) ? "white" : "gray"}` }} />
                  </div>
                </div></td>
                <td><div className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input onChange={(e) => {
                      const newData = [...datain.products];
                      newData[i].retail_price = e.target.value;
                      setdataInventory(newData);
                    }} style={{ outline: "none", border: "none", color: "gray", width: "60px", color: `${isRowSelected(item?.id) ? "white" : "gray"}` ,fontSize:"12px"}} value={item.retail_price} />

                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(item?.id) ? "white" : "gray"}` }} />
                  </div>
                </div></td>
                <td><div className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input onChange={(e) => {
                      const newData = [...datain.products];
                      newData[i].purchase_price = e.target.value;
                      setdataInventory(newData);
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px", width: "60px" }} value={item.purchase_price} />

                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(item?.id) ? "white" : "gray"}` }} />
                  </div>
                </div></td>
                {item?.pro_inventory?.level?<td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }}>{item?.pro_inventory?.level ? item?.pro_inventory?.level : "-"}</span></td>:""}
                <td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }}>0</span></td>
                {item?.pro_inventory?.level?<td style={{ textAlign: "center", background: `${item?.pro_inventory?.level > "0" ? "#AAC99E" : "#FF7782"}`}}><span style={{ padding: "0",fontSize:"12px" }}>{item?.pro_inventory?.level > "0" ? item?.pro_inventory?.level : 0}</span></td>:""}
                <td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }}>0</span></td>
                <td><Tracked data={item} isRowSelected={isRowSelected}/></td>
                 
              
              </tr>
             
              )
             }):""} */}
          {datain?.products?.length ? datain?.products?.map((item, i) => {
            return (
              <>{datain.product_type=="variation_parent"?<>
               <tr onClick={(e) => getselect(item, e)} style={{display:`${item?.full_details?.archive?item?.full_details?.archive:item?.archive==1?"none":""}`,  background: `${isRowSelected(item) ? "#93B1C9" : ""}` }} className={`${i % 2 === 0 ? "bg" : ""}`}>
              <th scope="row"></th>
              <td>
              {showVariation1[i]? <AiOutlineMinusSquare onClick={() => toggleVariation1(i)} /> :<MdOutlineAddBox onClick={() => toggleVariation1(i)} /> }  <Check value={value} pro={item.full_details} wareHouse={wareHouse} setvalue={setvalue} data={item.sku} id={item?.sku} idx={i} />
                </td>
          
              {datain?.columns.includes("Title")?<td><div style={{ color: `${isRowSelected(item) ? "white" : ""}` }} className="row">
                <div className="col-8" style={{ overflow: "hidden" }}>
                  <input onBlur={()=>editData(datain.products[i])} onChange={(e) => {
                    const newData = [...datain.variation];
                    newData[i].name = e.target.value;
                    setdataInventory({ product_type:datain.product_type,
                      columns:datain.columns,
                      products:datain.products,
                      variation:newData});
                   
                  }} style={{ outline: "none", border: "none", color: `${isRowSelected(item) ? "white" : "gray"}`,fontSize:"12px" }} value={item.name} />
                </div>
                <div className="col-4">
                  <FiEdit style={{ position: "absolute", right: "5%", top: "20%" }} />
                </div>
              </div></td>:""}
              {datain?.columns.includes("Barcode Number")?<td><div className="row">
                <div className="col-10" style={{ overflow: "hidden" }}>
                  <input onBlur={()=>editData(datain.products[i])} onChange={(e) => {
                    const newData = [...datain.variation];
                    newData[i].barcode = e.target.value;
                    setdataInventory(newData);
                    
                  }} style={{ outline: "none", border: "none", color: `${isRowSelected(item) ? "white" : "gray"}`,fontSize:"12px" }} value={item.products[0]?.barcode} />
                </div>
                <div className="col-2">
                  <FiEdit style={{ position: "absolute", right: "5%", top: "20%", color: `${isRowSelected(item) ? "white" : "gray"}` }} />
                </div>
              </div></td>:""}
              {datain?.columns.includes("Retail Price")?<td><div className="row">
                <div className="col-8" style={{ overflow: "hidden" }}>-


                </div>
          
              </div></td>:""}
            {datain?.columns.includes("Purchase Price")?<td><div className="row">
                <div className="col-8" style={{ overflow: "hidden" }}>-


                </div>
               
              </div></td>:""}
             {datain?.columns.includes("Stock Level")?<td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(item) ? "white" : "gray"}`,fontSize:"12px" }}>{item.products[0].full_details.pro_inventory?.level}</span></td>:""}
              {datain?.columns.includes("In Open Orders")?<td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(item) ? "white" : "gray"}`,fontSize:"12px" }}>0</span></td>:""}
           {datain?.columns.includes("Available")? <td style={{ textAlign: "center", background: `${item?.pro_inventory&&item?.pro_inventory[0]?.level >0 ? "#AAC99E" : "#FF7782"}`,fontSize:"12px" }}><span style={{ padding: "0",fontSize:"12px" }}>{item?.pro_inventory&&item?.pro_inventory[0]?.level}</span></td>:""}
              {datain?.columns.includes("Minimum Level")?<td ><span style={{ padding: "0", color: `${isRowSelected(item) ? "white" : "gray"}`,fontSize:"12px" }}>{item.min_level}</span></td>:""}
             
                {datain?.columns.includes("Tracked")?<td>-</td>:""}
            </tr>
         
                  {showVariation1[i] && datain?.products[i]?.products?.map((item,index)=>{
            return(
              <tr onClick={(e) => getselect(item.id, e)} style={{display:`${item?.archive==1?"none":""}`,  background: `${isRowSelected(item?.id) ? "#93B1C9" : ""}` }} className={`${i % 2 === 0 ? "bg" : ""}`}>
              <th   style={{ width: `${columnWidths[index]}px`, border: "1px solid lightgray" }} scope="row"></th>
              <td  style={{ width: `${columnWidths[index]}px`, border: "1px solid lightgray" }}  className='ps-4'>
                <Check value={value} pro={item?.full_details} wareHouse={wareHouse} setvalue={setvalue} data={item.full_details.item_no} id={item.full_details.id} idx={i} />
                </td>
              {datain?.columns.includes("Title")?<td  style={{ width: `${columnWidths[index]}px`, border: "1px solid lightgray" }} ><div style={{ color: `${isRowSelected(item?.id) ? "white" : ""}` }} className="row">
                <div className="col-8" style={{ overflow: "hidden" }}>
                  <input onBlur={()=>editData(datain.products[i]?.full_details)} onChange={(e) => {
                    const newData = [...datain.products];
                    newData[i].title = e.target.value;
                    // console.log(newdata);
                    setdataInventory({
                      product_type:datain.product_type,
                      columns:datain.columns,
                      products:newData,
                      variation:datain.variation
                    });

                  }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }} value={item.full_details.title} />
                </div>
                <div className="col-4">
                  <FiEdit style={{ position: "absolute", right: "5%", top: "20%" }} />
                </div>
              </div></td>:""}
            {datain?.columns.includes("Barcode Number")?<td  style={{ width: `${columnWidths[index]}px`, border: "1px solid lightgray" }} ><div className="row">
                <div className="col-10" style={{ overflow: "hidden" }}>
                  <input onBlur={()=>editData(datain.products[i]?.full_details)} onChange={(e) => {
                    const newData = [...datain.products];
                    newData[i].barcode = e.target.value;
                    setdataInventory(newData);
                  }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }} value={item.full_details.barcode} />
                </div>
                <div className="col-2">
                  <FiEdit style={{ position: "absolute", right: "5%", top: "20%", color: `${isRowSelected(item?.id) ? "white" : "gray"}` }} />
                </div>
              </div></td>:""}
              {datain?.columns.includes("Retail Price")?<td  style={{ width: `${columnWidths[index]}px`, border: "1px solid lightgray" }} ><div className="row">
                <div className="col-8" style={{ overflow: "hidden" }}>
                  <input onBlur={()=>editData(datain.products[i]?.full_details)} onChange={(e) => {
                    const newData = [...datain.products];
                    newData[i].retail_price = e.target.value;
                    setdataInventory(newData);
                  }} style={{ outline: "none", border: "none", color: "gray", width: "60px", color: `${isRowSelected(item?.id) ? "white" : "gray"}` ,fontSize:"12px"}} value={item.full_details.retail_price} />

                </div>
                <div className="col-4">
                  <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(item?.id) ? "white" : "gray"}` }} />
                </div>
              </div></td>:""}
            { datain?.columns.includes("Purchase Price")?<td  style={{ width: `${columnWidths[index]}px`, border: "1px solid lightgray" }} ><div className="row">
                <div className="col-8" style={{ overflow: "hidden" }}>
                  <input onBlur={()=>editData(datain.products[i])} onChange={(e) => {
                    const newData = [...datain.products];
                    newData[i].purchase_price = e.target.value;
                    setdataInventory(newData);
                  }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px", width: "60px" }} value={item.full_details.purchase_price} />

                </div>
                <div className="col-4">
                  <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(item?.id) ? "white" : "gray"}` }} />
                </div>
              </div></td>:""}
              {datain?.columns.includes("Stock Level")?<td style={{ textAlign: "center", width: `${columnWidths[index]}px`, border: "1px solid lightgray"  }}>
              <span style={{ padding: "0", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }}>{item?.full_details?.pro_inventory?.level ? item?.full_details?.pro_inventory?.level : "-"}</span>
              <input onBlur={()=>editData(datain.products[i])} onChange={(e) => {
                    const newData = [...datain.products];
                    newData[i].purchase_price = e.target.value;
                    setdataInventory(newData);
                  }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px", width: "60px" }} value={item.full_details.purchase_price} />
              </td>:""}
              {datain?.columns.includes("In Open Orders")?<td style={{ textAlign: "center", width: `${columnWidths[index]}px`, border: "1px solid lightgray"  }}><span style={{ padding: "0", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }}>0</span></td>:""}
              {datain?.columns.includes("Available")?<td style={{ textAlign: "center", width: `${columnWidths[index]}px`, border: "1px solid lightgray" , background: `${item?.full_details?.pro_inventory?.level > "0" ? "#AAC99E" : "#FF7782"}`}}><span style={{ padding: "0",fontSize:"12px" }}>{item?.full_details?.pro_inventory?.level}</span></td>:""}
              {datain?.columns.includes("Minimum Level")?<td  style={{background:`${item.full_details.min_level<0?"lightgray":''}`, width: `${columnWidths[index]}px`, border: "1px solid lightgray" }} ><div className="row">
                <div className="col-8" style={{ overflow: "hidden" }}>
                  <input onBlur={()=>editData(datain.products[i])} onChange={(e) => {
                    const newData = [...datain.products];
                    newData[i].min_level = e.target.value;
                    setdataInventory(newData);
                  }} style={{background:`${item.full_details.min_level<0?"lightgray":''}`, outline: "none", border: "none", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px", width: "60px" }} value={item.full_details.min_level} />

                </div>
                <div className="col-4">
                  <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(item?.id) ? "white" : "gray"}` }} />
                </div>
              </div></td>  :""   }
              {datain?.columns.includes("Tracked")?<td style={{ width: `${columnWidths[index]}px`, border: "1px solid lightgray" }}><Tracked data={item} isRowSelected={isRowSelected}/></td>:""}
             
            </tr>
            )
          })}
         
              </>
            :
                
             
              <tr onClick={(e) => getselect(item?.full_details?item?.full_details?.id:item.id, e)} style={{display:`${item?.archive==1?"none":""}`, background: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "#93B1C9" : ""}` }} className={`${i % 2 === 0 ? "bg" : ""} ${item?.full_details?.archive==1?"d-none":""}`}>
                <th scope="row"></th>
                <td>
                  <Check value={value} pro={item?.full_details?item?.full_details:item} wareHouse={wareHouse} setvalue={setvalue} data={item?.item_no} id={item.id} idx={i} />
                  </td>
             <td><div style={{ color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : ""}` }} className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input onBlur={()=>{
                      if(datain.products[i]?.full_details){

                        editData(datain.products[i]?.full_details)
                      }
                      else{
                        
                        editData(datain.products[i])
                      }
                    }}  onChange={(e) => {
                      const newData = [...datain.products];
                      newData[i].title = e.target.value;
                      if(newData[i]?.full_details){

                        newData[i].full_details.title = e.target.value;
                      }
                      setdataInventory({
                        product_type:datain.product_type,
                        columns:datain.columns,
                        products:newData,
                        variation:datain.variation
                      });
                      // editData(newData[i])
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : "gray"}`,fontSize:"12px" }} value={item.title} />
                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%" }} />
                  </div>
                </div></td>
              { datain?.columns.includes("Title")? <td><div className="row">
                  <div className="col-10" style={{ overflow: "hidden" }}>
                    <input onBlur={()=>{
                      if(datain.products[i]?.full_details){

                        editData(datain.products[i]?.full_details)
                      }
                      else{
                        
                        editData(datain.products[i])
                      }
                    }}  onChange={(e) => {
                      const newData = [...datain.products];
                      newData[i].barcode = e.target.value;
                      if(newData[i]?.full_details){

                
                        newData[i].full_details.barcode = e.target.value;
                      }
                      setdataInventory({
                        product_type:datain.product_type,
                        columns:datain.columns,
                        products:newData,
                        variation:datain.variation
                      });
                  
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : "gray"}`,fontSize:"12px" }} value={item.barcode} />
                  </div>
                  <div className="col-2">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : "gray"}` }} />
                  </div>
                </div></td>:""}
                <td><div className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input onBlur={()=>{
                      if(datain.products[i]?.full_details){

                        editData(datain.products[i]?.full_details)
                      }
                      else{
                        
                        editData(datain.products[i])
                      }
                    }} onChange={(e) => {
                      const newData = [...datain.products];
                      newData[i].retail_price = e.target.value;
                      if(newData[i]?.full_details){
                        
                        newData[i].full_details.retail_price = e.target.value;
                        // newData[i].full_details.title = e.target.value;
                      }
                      setdataInventory({
                        product_type:datain.product_type,
                        columns:datain.columns,
                        products:newData,
                        variation:datain.variation
                      });
                      
                    }} style={{ outline: "none", border: "none", color: "gray", width: "60px", color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : "gray"}` ,fontSize:"12px"}} value={item.retail_price} />

                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : "gray"}` }} />
                  </div>
                </div></td>
               <td><div className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input onBlur={()=>{
                      if(datain.products[i]?.full_details){

                        editData(datain.products[i]?.full_details)
                      }
                      else{
                        
                        editData(datain.products[i])
                      }
                    }} onChange={(e) => {
                      const newData = [...datain.products];
                      newData[i].purchase_price = e.target.value;
                      if(newData[i]?.full_details){
                        
                        newData[i].full_details.purchase_price = e.target.value;
                        // newData[i].full_details.title = e.target.value;
                      }
                      setdataInventory({
                        product_type:datain.product_type,
                        columns:datain.columns,
                        products:newData,
                        variation:datain.variation
                      });
                      // editData(newData[i])
                     
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : "gray"}`,fontSize:"12px", width: "60px" }} value={item.purchase_price} />

                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : "gray"}` }} />
                  </div>
                </div></td>
                {<td style={{ textAlign: "center" }}>
                  <span style={{ padding: "0", color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : "gray"}`,fontSize:"12px" }}>
                  {
  item?.pro_inventory?.length ? (() => {
    var value = 0;
    var resultArray = item.pro_inventory.map((itemss) => {
      value += itemss?.level || 0;
      return value;
    });
    return resultArray[resultArray.length - 1]; // Return the final accumulated value
  })() : "0"
}
                </span></td>}
                <td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : "gray"}`,fontSize:"12px" }}>0</span></td>
                {<td style={{ textAlign: "center", background: `${item?.full_details?.pro_inventory[0]?.level > "0" ? "#AAC99E" : "#FF7782"}`}}><span style={{ padding: "0",fontSize:"12px" }}>{item?.full_details?.pro_inventory[0]?.level? item?.full_details?.pro_inventory[0]?.level : 0}</span></td>}
                <td style={{background:`${item.min_level<0?"lightgray":""}`}}><div className="row">
                  <div className="col-8" style={{ overflow: "hidden" }}>
                    <input onBlur={()=>{
                      if(datain.products[i]?.full_details){

                        editData(datain.products[i]?.full_details)
                      }
                      else{
                        
                        editData(datain.products[i])
                      }
                    }} onChange={(e) => {
                      const newData = [...datain.products];
                      newData[i].min_level = e.target.value;
                      if(newData[i]?.full_details){
                        newData[i].full_details.min_level = e.target.value;
                        // newData[i].full_details.title = e.target.value;
                      }
                      setdataInventory({
                        product_type:datain.product_type,
                        columns:datain.columns,
                        products:newData,
                        variation:datain.variation
                      });
                      // editData(newData[i])
                     
                    }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : "gray"}`,fontSize:"12px", width: "60px" }} value={item?.min_level?item?.min_level:item?.full_details?.min_level} />

                  </div>
                  <div className="col-4">
                    <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(item?.full_details?item?.full_details?.id:item.id) ? "white" : "gray"}` }} />
                  </div>
                </div></td>
                <td><Tracked data={item?.full_details?item?.full_details:item} isRowSelected={isRowSelected}/></td>
                
              
              </tr>}
              </>
            )
          }) : <p style={{position:"absolute",width:"90%",padding:"1rem 2rem",textAlign:"center"}}>No Item</p>
          
          }
          {/* {showvariation1?datain?.products[0]?.products?.map((item,i)=>{
            return(
              <tr onClick={(e) => getselect(item.id, e)} style={{display:`${item?.archive==1?"none":""}`,  background: `${isRowSelected(item?.id) ? "#93B1C9" : ""}` }} className={`${i % 2 === 0 ? "bg" : ""}`}>
              <th scope="row"></th>
              <td className='ps-4'>
                <Check value={value} pro={item?.full_details} wareHouse={wareHouse} setvalue={setvalue} data={item.full_details.item_no} id={item.full_details.id} idx={i} />
                </td>
              {item?.full_details?.title?<td><div style={{ color: `${isRowSelected(item?.id) ? "white" : ""}` }} className="row">
                <div className="col-8" style={{ overflow: "hidden" }}>
                  <input onBlur={()=>editData(datain.products[i]?.full_details)} onChange={(e) => {
                    const newData = [...datain.products];
                    newData[i].title = e.target.value;
                    // console.log(newdata);
                    setdataInventory({
                      product_type:datain.product_type,
                      columns:datain.columns,
                      products:newData,
                      variation:datain.variation
                    });

                  }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }} value={item.full_details.title} />
                </div>
                <div className="col-4">
                  <FiEdit style={{ position: "absolute", right: "5%", top: "20%" }} />
                </div>
              </div></td>:""}
             <td><div className="row">
                <div className="col-10" style={{ overflow: "hidden" }}>
                  <input onBlur={()=>editData(datain.products[i]?.full_details)} onChange={(e) => {
                    const newData = [...datain.products];
                    newData[i].barcode = e.target.value;
                    setdataInventory(newData);
                  }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }} value={item.full_details.barcode} />
                </div>
                <div className="col-2">
                  <FiEdit style={{ position: "absolute", right: "5%", top: "20%", color: `${isRowSelected(item?.id) ? "white" : "gray"}` }} />
                </div>
              </div></td>
              <td><div className="row">
                <div className="col-8" style={{ overflow: "hidden" }}>
                  <input onBlur={()=>editData(datain.products[i]?.full_details)} onChange={(e) => {
                    const newData = [...datain.products];
                    newData[i].retail_price = e.target.value;
                    setdataInventory(newData);
                  }} style={{ outline: "none", border: "none", color: "gray", width: "60px", color: `${isRowSelected(item?.id) ? "white" : "gray"}` ,fontSize:"12px"}} value={item.full_details.retail_price} />

                </div>
                <div className="col-4">
                  <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(item?.id) ? "white" : "gray"}` }} />
                </div>
              </div></td>
             <td><div className="row">
                <div className="col-8" style={{ overflow: "hidden" }}>
                  <input onBlur={()=>editData(datain.products[i])} onChange={(e) => {
                    const newData = [...datain.products];
                    newData[i].purchase_price = e.target.value;
                    setdataInventory(newData);
                  }} style={{ outline: "none", border: "none", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px", width: "60px" }} value={item.full_details.purchase_price} />

                </div>
                <div className="col-4">
                  <FiEdit style={{ position: "absolute", right: "5%", top: "20%", width: "60px", color: `${isRowSelected(item?.id) ? "white" : "gray"}` }} />
                </div>
              </div></td>
              <td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }}>{item?.full_details?.pro_inventory?.level ? item?.full_details?.pro_inventory?.level : "-"}</span></td>
              <td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }}>0</span></td>
              <td style={{ textAlign: "center", background: `${item?.full_details?.pro_inventory?.level > "0" ? "#AAC99E" : "#FF7782"}`}}><span style={{ padding: "0",fontSize:"12px" }}>{item?.full_details?.pro_inventory?.level}</span></td>
              <td style={{ textAlign: "center" }}><span style={{ padding: "0", color: `${isRowSelected(item?.id) ? "white" : "gray"}`,fontSize:"12px" }}>0.00</span></td>
              <td><Tracked data={item} isRowSelected={isRowSelected}/></td>
             
            </tr>
            )
          }):""} */}

       
        </tbody>
      </table>
    </div>

  );
};

export default BasicTable;
