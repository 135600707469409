import React, { useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { LiaSave } from 'react-icons/lia';
import { BiSolidEdit } from 'react-icons/bi';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
//   p: 4,
};
const Tine = ({value,setTableData2,tableData2,index,getDesc}) => {
    const [data,setdata]=useState()
    console.log(value);
  const handleEditorChange = (content, editor) => {
    console.log('Content was updated:', content);
    setdata(content)
    const description=[...tableData2]
    description[index].description=content
    setTableData2(description)
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const adddata=()=>{
    getDesc()
    handleClose()
  }
  return (
    <>
          <button style={{border:"none",background:"#EAEBEC"}} onClick={handleOpen}><BiSolidEdit/></button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
        <div id='inventory_header' className='modal-header'>
                    <h5 className="modal-title" id="exampleModalLabel">Product Details</h5>
                    <div>
                      <button onClick={handleClose} type="button" className="close">
                        <span style={{ fontSize: "25px" }}  >×</span>
                      </button>
                    </div>
                  </div>
        <div style={{height:"70vh",padding:"1rem"}} className='tiny'>
  <ul className="nav nav-tabs mb-3" id="myTab0" role="tablist">
    <li className="nav-item" role="presentation">
      <button className="nav-link active" id="home-tab0" data-mdb-toggle="tab" data-mdb-target="#home0" type="button" role="tab" aria-controls="home" aria-selected="true">
        Home
      </button>
    </li>
    <li className="nav-item" role="presentation">
      <button className="nav-link" id="profile-tab0" data-mdb-toggle="tab" data-mdb-target="#profile0" type="button" role="tab" aria-controls="profile" aria-selected="false">
        Profile
      </button>
    </li>
  </ul>
  <div className="tab-content" id="myTabContent0">
    <div className="tab-pane fade show active" id="home0" role="tabpanel" aria-labelledby="home-tab0">
    <Editor 
      apiKey="5de5um7gyq4wrfqkjb9jwtc84j7035ahq1dln435ekhxkxic"
      // initialValue=
    value={value}
      init={{
        height: 350,
        
        // style_formats:{{bo}},
        menubar: true,
        plugins: 'code image', // Include the 'image' plugin
        toolbar:
          'undo redo | formatselect | ' +
          'bold italic backcolor | alignleft aligncenter ' +
          'alignright alignjustify | bullist numlist outdent indent | ' +
          'removeformat | image | premiumFeature1Button premiumFeature2Button | help', // Include the 'image' button
        image_title: true, // Enable the image title field
        automatic_uploads: true, // Enable automatic image uploads
        images_upload_url: '/your-image-upload-endpoint', // Replace with your image upload endpoint
        images_upload_handler: (blobInfo, success, failure) => {
          // Implement your image upload logic here
          // Example:
          // uploadImageToServer(blobInfo.blob(), success, failure);
        },
      }}
      onEditorChange={handleEditorChange}
    />
    </div>
    <div className="tab-pane fade" id="profile0" role="tabpanel" aria-labelledby="profile-tab0">
   {data}
    </div>
   
  </div>
</div>

<div className="modal-footer">
                    <button type="button" onClick={adddata} className="mx-2 my-3" style={{ border: "1px solid lightgray", cursor: "pointer", color: "white", padding: ".3rem 1rem", zIndex: "99", background: "#839AB4" }}>
                      <LiaSave />Save
                    </button>
                    {/* <button type="button" onClick={addinventory} className="">Save</button> */}
                    <button className="mx-2" type="button" onClick={handleClose} style={{ border: "1px solid lightgray", color: "gray", padding: ".3rem 1rem" }}>
                      Cancel
                    </button>
                  </div>
  {/* Tabs content */}

        </Box>
      </Modal>
    </>
  );
};

export default Tine;
