import { Add, Close, Delete} from '@mui/icons-material';
import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {FiFilter} from 'react-icons/fi'
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import { useStateContext } from '../Context/ContextProvider';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,

  };
function Suppliers() {
  const {getsuplier,supplier,setinvSupplier,inventoryData}=useStateContext()
  console.log(supplier);
    const [open, setOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
    };
    const open1 = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
      setAnchorEl(null);
    };
    useEffect(()=>{
      getsuplier()
    },[])
    const [row,setrow]=useState({
      name: "",
      supplier_code: "",
      barcode:"",
      lead_time: "",
      purchase_price: "",
      currency: "",
      min_order_qty: "",
      pack_size: ""
  })
    const addRow = (value) => {
      console.log(value);
      var array=tableData.filter((item)=>item.name==value)
      if(array.length>0){
        // alert("hello")
      }
      else{

        setrow({
          name: value,
          supplier_code: inventoryData.item_no,
          barcode: inventoryData.title,
          lead_time: "1",
          purchase_price: "0",
          currency: "GBP",
          min_order_qty: "0",
          pack_size: "0"
      })
      }
        setOpen(false);

    }
    useEffect(()=>{
      if(row.name){

        setTableData([...tableData, row])
      }
    },[row])
    console.log(row);
    const [tableData, setTableData] = useState([]);

    const handleInputChange = (e, rowIndex, columnName) => {
        const updatedData = [...tableData];
        updatedData[rowIndex][columnName] = e.target.value;
        setinvSupplier(updatedData)
        setTableData(updatedData);
    };
    const [multipulSelct,setmultipulSelct]=useState([])
    const [select,setselect]=useState([])
    const getselect = (id, event) => {
      if (event.ctrlKey || event.metaKey) {
        setselect([...select, id]);
        setmultipulSelct([...multipulSelct, id])
      } else {
        setselect([id]);
        setmultipulSelct([id])
      }
    }
    const isRowSelected = (id) => select.includes(id);
    const deleteFun = () => {
      var array = tableData.filter((item, i) => {
        return !select.includes(i);
      });
    
      setTableData(array)
    };
    return (
        <div className='Createsuppliers listing_discription'>
    
                <div className='row'>
                    <div className='col-8'>
                        <h5>Suppliers</h5>
                    </div>
                    <div className='col-4'>
                        <button onClick={handleOpen} type="button" className='btn1' >
                            <Add/> Add new
                        </button>
                        
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <div className='modal-heade'>
            <div className='row'>
                <div className='col-10'>
                <h6>Item Chooser</h6>
                </div>
                <div className='col-2'>
                    <button onClick={handleClose} className='closeBtn'>
                        <Close/>
                    </button>
                </div>
            </div>
            
          </div>
          <div className='modal_body'>
            <div className='table_head py-2 px-2'>
                <div className='row'>
                    <div className='col-11'>
                        <p>Name</p>
                    </div>
                    <div className='col-1'>
                        <FiFilter   
                           onClick={handleClick}
            size="small"
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}/>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open1}
        onClose={handleClose1}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: 'visible',
            border:"1px solid lightgray",
            borderRadius:"0px",
            bgcolor: '#f9f9f9',
        
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
              
            },
           
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem >
          <select style={{width:"100%",background:"white",border:"1px solid lightgray",padding:".3rem",color:"gray"}}>
            <option>contains</option>
            <option>dose not contains</option>
            <option>is equal to</option>
            <option>is not equal to</option>
            <option>Start with</option>
            <option>End with</option>
          </select>
        </MenuItem>
        <MenuItem >
          <input style={{width:"100%",background:"white",border:"1px solid lightgray",padding:".2rem",color:"gray"}}/>
        </MenuItem>
        <MenuItem >
          <select style={{width:"100%",background:"white",border:"1px solid lightgray",padding:".3rem",color:"gray"}}>
            <option>
                   And
            </option>
            <option>
                   or
            </option>
          </select>
        </MenuItem>
        <MenuItem >
          <select style={{width:"100%",background:"white",border:"1px solid lightgray",padding:".3rem",color:"gray"}}>
          <option>contains</option>
            <option>dose not contains</option>
            <option>is equal to</option>
            <option>is not equal to</option>
            <option>Start with</option>
            <option>End with</option>
          </select>
        </MenuItem>
        <MenuItem >
          <input style={{width:"100%",background:"white",border:"1px solid lightgray",padding:".2rem",color:"gray"}}/>
        </MenuItem>
        <button style={{border:"1px solid #44688C",background:"#44688C",color:"white",padding:".3rem 1.5rem",marginLeft:"20px"}}>Filter</button>
        <button style={{border:"1px solid lightgray",background:"lightgray",color:"gray",padding:".3rem 1.5rem",marginLeft:"20px"}}>Clear</button>
      </Menu>
                    </div>
                </div>
            </div>
            <div className='supplier_data'>
               {supplier?.map((item)=>{
                return(
                  <div className='py-1 px-2'>
                  <p onClick={()=>addRow(item.supplier_name)}>{item.supplier_name}</p>
                  </div>
                )
               })}
            </div>
          </div>
        </Box>
      </Modal>
                        <button onClick={deleteFun} type="button" className='btn2'>
                         <Delete/>   Delete
                        </button>
                    </div>
                </div>

            <div className='table-responsive'>

            <table className="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Supplier Code</th>
                        <th scope="col">Barcode</th>
                        <th scope="col">Lead time</th>
                        <th scope="col">Purches Price</th>
                        <th scope="col">Currency</th>
                        <th scope="col">Min Order Qty </th>
                        <th scope="col">Pack size</th>


                    </tr>
                </thead>
                <tbody>
                    { tableData.map((item, index) => {
                        return (
                            <tr onClick={(e)=>getselect(index, e)} style={{ background: `${isRowSelected(index) ? "#93B1C9" : ""}`,color: `${isRowSelected(index) ? "white" : ""}`}}>
                                <td><input onChange={(e) => handleInputChange(e, index, 'name')} value={item.name} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'supplier_code')} value={item.supplier_code} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'barcode')} value={item.barcode} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'lead_time')} value={item.lead_time} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'purchase_price')} value={item.purchase_price} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'currency')} value={item.currency} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'min_order_qty')} value={item.min_order_qty} /></td>
                                <td><input onChange={(e) => handleInputChange(e, index, 'pack_size')} value={item.pack_size} /></td>


                            </tr>
                        )
                    })}
                </tbody>
            </table>
            </div>

        </div>
    )
}

export default Suppliers