import React, { useEffect } from 'react'
import { Link, Outlet, useNavigate } from 'react-router-dom'
import Inventory from '../Pages/Inventory'
import { useStateContext } from '../Context/ContextProvider'
import { BsQuestionCircleFill } from 'react-icons/bs'
import logo from '../image/linnworks.png'
function SiderBar() {
  const {openTab}=useStateContext()
  const auth=JSON.parse(localStorage.getItem("user_token"))

  const navigate=useNavigate()
  useEffect(() => {
    if(!auth){
      navigate("/")
    }

  }, [])
  return (
    <>
    {auth&& <>
    <section  style={{zIndex:"9"}} id="inventory">
    <div className="container-fluid">
      <div style={{zIndex:"9"}} className="sidenav">
        <a href="#about" className="side-logo">
          <img src={logo} style={{width:"70%"}} alt='...'/>
        </a>
        <a className="nav-item nav-item1 dropright">
        </a><a className="nav-link nav-link-1 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa fa-solid fa-cubes nav-icons" /><br />Inventory
        </a>
        <ul style={{zIndex:"9"}} className="dropdown-menu extra_width">
          
          <div  style={{zIndex:"9"}} className='row'>
            <div className='col-6'>
            <li><a className="dropdown-item pb-3 heading-link pt-1" style={{fontSize:"15px",padding:"0"}}>Inventory</a></li>
            <li><Link className="dropdown-item" to={"/dashboard/Inventory"}>   <div className='row'>
              <div className='col-11'>
              <p>My inventory</p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div></Link></li>
          <li><Link to='/dashboard/warehouse' className="dropdown-item" >
            <div className='row'>
              <div className='col-11'>
              <p>Warehouse Transfer <span>New</span> </p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div>
            </Link></li>
            <li><Link to='/dashboard/New-stock-request' className="dropdown-item" >
            <div className='row'>
              <div className='col-11'>
              <p>Reorder Low Stock <span>New</span> </p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div>
            </Link></li>
            <li><Link to='/dashboard/warehouse' className="dropdown-item" >
            <div className='row'>
              <div className='col-11'>
              <p>FBA Stock Dashboard <span>New</span> </p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div>
            </Link></li>
            <li><Link to='/dashboard/Amazon-Vendor-centrel' className="dropdown-item" >
            <div className='row'>
              <div className='col-11'>
              <p>Amazon Vendor Central <span style={{background:"#BE3337"}}>Beta</span> </p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div>
            </Link></li>
            <li><Link to={'/dashboard/PurchaseOrder'} className="dropdown-item" >
            <div className='row'>
              <div className='col-11'>
              <p>Purchase Oders</p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div>
            </Link></li>
            <li><Link to={'/dashboard/stock-in'} className="dropdown-item" >
            <div className='row'>
              <div className='col-11'>
              <p>Stock In</p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div>
            </Link></li>
            <li><Link to={'/dashboard/stock-count'}  className="dropdown-item" >
            <div className='row'>
              <div className='col-11'>
              <p>Stock count</p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div>
            </Link></li>
            <li><Link to='/dashboard/scrab-item' className="dropdown-item" >
            <div className='row'>
              <div className='col-11'>
              <p>Scrap items</p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div>
            </Link></li>
            </div>
            <div className='col-6'>
                       
          <li><a className="dropdown-item pb-3 heading-link pt-1" href="#" style={{fontSize:"15px",padding:"0"}}>Inventory Settings</a></li>
          <li><Link  to={'/dashboard/variation'} className="dropdown-item" >
            <div className='row'>
              <div className='col-11'>
              <p>Variations</p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div>
            </Link></li>
          <li><Link to={'/dashboard/category'} className="dropdown-item" >  <div className='row'>
              <div className='col-11'>
              <p>Categories</p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div></Link></li>
          <li><Link to={'/dashboard/location'} className="dropdown-item" >    <div className='row'>
              <div className='col-11'>
              <p>Location</p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div></Link></li>
          <li><Link className="dropdown-item" to={"/dashboard/supplier"}>   <div className='row'>
              <div className='col-11'>
              <p>Suppliers</p>
              </div>
              <div className='col-1'>
             < BsQuestionCircleFill  className='icon_qusetion' />
              </div>
            </div></Link></li>
            </div>
          </div>

        </ul>
        
        <a className="nav-item nav-item1 dropright">
        </a><a className="nav-link nav-link-1 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa fa-solid fa-book nav-icons" /><br />Oders
        </a>
        {/* <ul className="dropdown-menu">
          <li><a className="dropdown-item pb-3 heading-link pt-1" href="#">Inventory</a></li>
          <li><a className="dropdown-item" href="#"> Suppliers <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
          <li><a className="dropdown-item" href="#">Warehouse Transfer <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
        </ul>
        <a className="nav-item nav-item1 dropright">
        </a><a className="nav-link nav-link-1 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa fa-solid fa-truck nav-icons" /><br />Shipping
        </a>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item pb-3 heading-link pt-1" href="#">Inventory</a></li>
          <li><a className="dropdown-item" href="#"> Suppliers <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
          <li><a className="dropdown-item" href="#">Warehouse Transfer <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
        </ul>
        <a className="nav-item nav-item1 dropright">
        </a><a className="nav-link nav-link-1 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa fa-solid fa-book nav-icons" /><br />Dashboard
        </a>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item pb-3 heading-link pt-1" href="#">Inventory</a></li>
          <li><a className="dropdown-item" href="#"> Suppliers <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
          <li><a className="dropdown-item" href="#">Warehouse Transfer <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
        </ul>
        <a className="nav-item nav-item1 dropright">
        </a><a className="nav-link nav-link-1 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa fa-solid fa-envelope nav-icons" /><br />Emails
        </a>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item pb-3 heading-link pt-1" href="#">Inventory</a></li>
          <li><a className="dropdown-item" href="#"> Suppliers <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
          <li><a className="dropdown-item" href="#">Warehouse Transfer <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
        </ul>
        <a className="nav-item nav-item1 dropright">
        </a><a className="nav-link nav-link-1 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa fa-solid fa-puzzle-piece nav-icons" /><br />Apps
        </a>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item pb-3 heading-link pt-1" href="#">Inventory</a></li>
          <li><a className="dropdown-item" href="#"> Suppliers <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
          <li><a className="dropdown-item" href="#">Warehouse Transfer <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
        </ul>
        <a className="nav-item nav-itemLast dropright">
        </a><a className="nav-link nav-link-1 dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          <i className="fa fa-solid fa-wrench nav-icons" /><br />Setting
        </a>
        <ul className="dropdown-menu">
          <li><a className="dropdown-item pb-3 heading-link pt-1" href="#">Inventory</a></li>
          <li><a className="dropdown-item" href="#"> Suppliers <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
          <li><a className="dropdown-item" href="#">Warehouse Transfer <span>< BsQuestionCircleFill  className='icon_qusetion' /></span></a></li>
        </ul> */}
      </div>
    
    </div>
    </section>
  <Outlet/>
  </>}
    </>
 
  )
}

export default SiderBar