import React, { useEffect, useState } from 'react'
import { useStateContext } from '../Context/ContextProvider';
import { Add,  Close,  Save } from '@mui/icons-material';
import { FiEdit } from "react-icons/fi"
import { RiDeleteBin5Line } from 'react-icons/ri'
import {LiaSave} from 'react-icons/lia'

function Category() {
  const [multipulSelct,setmultipulSelct]=useState([])
  const [select,setselect]=useState([])
  const getselect = (id, event) => {
    if (event.ctrlKey || event.metaKey) {
      setselect([...select, id]);
      setmultipulSelct([...multipulSelct, id])
    } else {
      setselect([id]);
      setmultipulSelct([id])
    }
  }
  const isRowSelected = (id) => select.includes(id);
  const { addCategory, datacetagory, allCetagore, editCategory, deletCategory } = useStateContext()
  useEffect(() => {
    datacetagory()
  }, [])
  console.log(allCetagore);
  const [data, setdata] = useState({
    name: ""
  })
  const [itemId,setitem]=useState()
  const valueget = (item) => {
    setdata({ ...data, name: item.name, address_1: item.address_1, address_2: item.address_2, city: item.city, region: item.region, country: item.country, postcode: item.postcode })
  }
  return (
    <div className='category'><div className='main_addCategory'>

      <div className='row'>
        <div className='col-9'></div>
        <div className='col-3'>

            {/* Button trigger modal */}
            <button onClick={()=>setdata({
              name: ""
            })} type="button" data-toggle="modal" data-target="#exampleModalLong" style={{fontSize:"14px",padding:"3px 10px"}}>
          <Add style={{fontSize:"14px"}}/> Add New
          </button>
            {/* Modal */}
            <div className="modal fade" id="exampleModalLong" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
              <div style={{width:"350px"}} className="modal-dialog" role="document">
                <div className="modal-content" >
                  <div className="modal-header">
                    <h6 className="modal-title" id="exampleModalLongTitle">Add New  Category</h6>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" ><Close style={{fontSize:"25px",fontWeight:"bold",color:"lightgray"}}/></span>
                    </button>
                  </div>
                  <div className="modal-body" >
                    <label>Name:</label>
                    <input value={data.name} type='text' onChange={(e) => setdata({ ...data, name: e.target.value })} placeholder='Add Category' />
                  </div>
                  <div className="modal-footer">
                    <button type="button" onClick={()=>addCategory(data)} data-dismiss="modal" className="btnn"><LiaSave /> Save</button>
                    <button type="button" className="btnn1" data-dismiss="modal">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
       

          {/* <button  ></button> */}
          <button disabled={multipulSelct?false:true} onClick={()=>deletCategory(multipulSelct)} style={{fontSize:"14px",padding:"3px 10px"}}><RiDeleteBin5Line style={{fontSize:"14px"}} /> Delete</button>
        </div>
      </div>

      <div className='table_box my-3'>
        <table className="table table-responsive">
          <thead>
            <tr style={{ background: "#F3F3F4" }}>
              <th scope="col"></th>
              <th style={{ width: "100%" }}>Name</th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {allCetagore?.map((item, i) => {
              return (
                <tr onClick={(e)=>getselect(item.id, e)} style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`,color: `${isRowSelected(item?.id) ? "white" : ""}`}} className={`${i % 2 === 0 ? "" : "bg"}`}>
                  <th scope="row"></th>
                  <td className={`${itemId==item.id?"text-white":""}`}>{item.name}</td>
                  <td>
                    
            
                    <button className={`${itemId==item.id?"text-white":""}`} type="button" onClick={() => valueget(item)} data-toggle="modal" data-target={`#exampleModalLong${item.id}`} style={{fontSize:"14px",padding:"3px 10px",background:'none',border:"none"}}>
                    <FiEdit />
          </button>
            {/* Modal */}
            <div className="modal fade" id={`exampleModalLong${item.id}`} tabIndex={-1} role="dialog" aria-labelledby={`exampleModalLongTitle${item.id}`} aria-hidden="true">
              <div style={{width:"350px"}} className="modal-dialog" role="document">
                <div className="modal-content" >
                  <div className="modal-header">
                    <h6 className="modal-title" id="exampleModalLongTitle">Add New Category</h6>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true" ><Close style={{fontSize:"25px",fontWeight:"bold",color:"lightgray"}}/></span>
                    </button>
                  </div>
                  <div className="modal-body" >
                    <label>Name:</label>
                    <input type='text' style={{color:"gray"}} onChange={(e) => setdata({ ...data, name: e.target.value })} value={data.name} placeholder='Add Category' />
                  </div>
                  <div className="modal-footer">
                    <button type="button"  onClick={()=>editCategory(data.name,item.id)} data-dismiss="modal" value={item.name} className="btnn"><LiaSave /> Save</button>
                    <button type="button" className="btnn1" data-dismiss="modal">Cancel</button>
                  </div>
                </div>
              </div>
            </div>
                    {/* <div className="modal fade" id={`exampleModalCenter${item.id}`} tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content my-5">
                          <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalCenterTitle">Edit Categore</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <label>Item Name:</label>
                            <input type='text' onChange={(e) => setdata({ ...data, name: e.target.value })} placeholder='Add Category' />
                          </div>
                          <div className="modal-footer">
                            <button type="button" className="btnn">Save changes</button>
                            <button type="button" className="btnn" data-dismiss="modal">Close</button>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </td>

                </tr>
              )
            })}


          </tbody>
        </table>

      </div>

    </div></div>
  )
}

export default Category