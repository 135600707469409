import React, { useState } from 'react'
import Topbar from './Topbar'
import { Close, Search } from '@mui/icons-material'
import {FaRegCalendarDays} from 'react-icons/fa6'
import { MdFilterListAlt, MdOutlineAddBox } from 'react-icons/md'
import { AiFillCaretDown } from 'react-icons/ai'
import { FaDownload } from 'react-icons/fa'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ItemStats from '../Component/ItemStats'
import ProssesedOrderDetail from '../Component/ProssesedOrderDetail'
const style = {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: '#F9F9F9',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
  };
function ProcessedOrder() {
   

    const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [value,setvalue]=useState([
    {
        country:"United State",
        order_id:"123456789",
        source:"AMAZON",
        name:"Men T-shirt |white AMAZON",
        order_date:"12-09-2023",
        sub_source:"AMAZON-US",
        email:"Jhon@gmail.com",
        traking_no:"839749201",
        total:"$12.00",
        processed_date:"15-09-2023",
        currency:"GBP"
    }
])
const [condition,setcondition]=useState(false)
  const search=()=>{
    const data=value
    if(!condition){

        data.unshift({
            country:"",
            order_id:"",
            source:"",
            name:"",
            order_date:"",
            sub_source:"",
            email:"",
            traking_no:"",
            total:"",
            processed_date:"",
            currency:""
        })
        setcondition(true)
    }
    else{
        data.shift({
            country:"",
            order_id:"",
            source:"",
            name:"",
            order_date:"",
            sub_source:"",
            email:"",
            traking_no:"",
            total:"",
            processed_date:"",
            currency:""
        })
        setcondition(false)
    }
    setvalue(data)
  }
  const [idx,setidx]=useState()
  const [showData,setShowData]=useState(false)
  const show=(index)=>{
        setidx(index)
        setShowData(!showData)
  }
  console.log(value);
  return (
    <div className='processed_orders'>
      <Topbar/>
      <div className='page_data' >
        <div style={{display:"flex",padding:"1rem"}}>
            <input style={{padding:".3rem",width:"300px",border:"1px solid lightgray"}}/>
            <button style={{padding:".3rem",border:"1px solid lightgray",background:"#EAEBEC"}}><Search/></button>
            <button style={{padding:".1rem .8rem",border:"1px solid lightgray",background:"#5C7FA5",color:"white"}} className='mx-1'><FaRegCalendarDays/></button>
            <button onClick={handleOpen} style={{padding:".3rem .8rem",border:"1px solid lightgray",background:"#EAEBEC"}} ><MdFilterListAlt/></button>
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div className='p-2'>

         <h6>Stock Item Filter</h6>
         <label style={{border:"1px solid lightgray",padding:".5rem",background:"#EEEEEE"}}>Stock Item</label>
         <input style={{border:"1px solid lightgray",padding:".5rem",width:"250px"}}/>
         <button style={{border:"1px solid lightgray",padding:".4.4rem"}}><Search/></button>
         <h6 style={{padding:"1rem 0 0 0"}}>Channel Filter</h6>
         <label className='my-2' style={{border:"1px solid lightgray",padding:".5rem 1.4rem",background:"#EEEEEE"}}>Source</label>
         <select style={{border:"1px solid lightgray",padding:".5rem",width:"250px"}}>
            <option></option>
         </select>
         <label style={{border:"1px solid lightgray",padding:".5rem",background:"#EEEEEE"}}>SubSource</label>
         <select style={{border:"1px solid lightgray",padding:".5rem",width:"250px"}}>
            <option></option>
         </select>
         
            </div>
        </Box>
      </Modal>
            <button style={{padding:".3rem 1rem",border:"1px solid lightgray",background:"#EAEBEC"}} className='ms-3'>Order Date</button>
            <div style={{border:"1px solid lightgray",padding:".3rem",height:"2.5rem"}}>
                <p style={{listStyle:"none"}}>25 Aug 2023 - 01 Sep 2023 <Close style={{fontSize:"15px"}}/></p>
            </div>
        </div>
        <div style={{display:"flex",background:"white",padding:".7rem 1rem",justifyContent:"space-between"}}>
           <p style={{fontSize:"17px"}}>Order: <span>673</span></p>
           <div>

           <button style={{padding:".4rem 1rem",border:"1px solid lightgray",background:"#EAEBEC"}}><FaDownload style={{fontSize:"12px",margin:"0 .5rem"}}/>Download</button>
           <button style={{padding:".4rem",border:"1px solid lightgray",background:"#EAEBEC"}}><AiFillCaretDown/></button>
           </div>
        </div>
        <div className='table_box table_extra' style={{margin:"1rem",width:"97%",border:"1px solid lightgray"}}>
                <div className='table-responsive'>
                    <table style={{ width: "100%" }} class="table table-sm">
                        <thead className='bg'>
                            <tr style={{whiteSpace:"nowrap"}}>

                                <th scope="col">
                                    <div className='row'>
                                        <div onClick={search} className='col-9'><MdFilterListAlt /></div>

                                    </div>

                                </th>

                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-9'>Country</div>

                                    </div>

                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-9'>Order Id</div>

                                    </div>
                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-9'>Source</div>

                                    </div>
                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-9'>Name</div>

                                    </div>
                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-9'>Order Date</div>

                                    </div>
                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-9'>SubSource</div>

                                    </div>
                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-9'>Email</div>

                                    </div>
                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-9'>Tracking Number</div>

                                    </div>
                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-9'>Total</div>

                                    </div>
                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-9'>Processed Date</div>

                                    </div>
                                </th>
                                <th scope="col">
                                    <div className='row'>
                                        <div className='col-9'>currency</div>

                                    </div>
                                </th>



                            </tr>
                        </thead>
                        <tbody>
                            {value?.map((item,i)=>{
        return(
            <>
        {i===0 && item.country=="" && condition?
           <tr className={`${i%2!==0?"bg":""}`}>
           <td>
          
           </td>
           <td><input style={{background:"none",border:"none",outline:"none",width:"100%"}} placeholder='country'/></td>
           <td><input  style={{background:"none",border:"none",outline:"none",width:"100%"}} placeholder='Order Id'/></td>
           <td><input  style={{background:"none",border:"none",outline:"none",width:"100%"}} placeholder='Source'/></td>
           <td><input  style={{background:"none",border:"none",outline:"none",width:"100%"}} placeholder='Name'/></td>
           <td><input  style={{background:"none",border:"none",outline:"none",width:"100%"}} placeholder='Order Date'/></td>
           <td><input  style={{background:"none",border:"none",outline:"none",width:"100%"}} placeholder='Sub Source'/></td>
           <td><input  style={{background:"none",border:"none",outline:"none",width:"100%"}} placeholder='Email'/></td>
           <td><input  style={{background:"none",border:"none",outline:"none",width:"100%"}} placeholder='Tracking Number'/></td>
           <td><input  style={{background:"none",border:"none",outline:"none",width:"100%"}} placeholder='Total'/></td>
           <td><input  style={{background:"none",border:"none",outline:"none",width:"100%"}} placeholder='Processed Date'/></td>
           <td><input  style={{background:"none",border:"none",outline:"none",width:"100%"}} placeholder='currency'/></td>
            
       
 
          
         </tr>:
         <>
         <tr className={`${i%2!==0?"bg":""}`}>
            <td>  <MdOutlineAddBox onClick={()=>show(i)}/></td>
            <td>{item.country}</td>
            <td>{item.order_id}</td>
            <td>{item.source}</td>
            <td>{item.order_date}</td>
            <td>{item.name}</td>
            <td>{item.sub_source}</td>
            <td>{item.email}</td>
            <td>{item.traking_no}</td>
            <td>{item.total}</td>
            <td>{item.processed_date}</td>
            <td>{item.currency}</td>
        
  
           
          </tr>
          {showData && idx==i ? <tr >
        <td colspan="12" style={{width:"100%"}}>
          <div className='variation_innerState'> 
        <ProssesedOrderDetail data={item}/>

          </div>
        </td>
       
      </tr>:""}
         </>
    
    }
         
          </>
        )
    })}
                        </tbody>
                    </table>
                </div>

            </div>
      </div>
    </div>
  )
}

export default ProcessedOrder