import React, { useEffect, useRef, useState } from 'react'
import BasicTable from '../Component/BasicTable'
import { Add, Delete } from '@mui/icons-material'
import SkuChanal from '../Component/SkuChanal';
import Suppliers from '../Component/Suppliers';
import ExtnedPro from '../Component/ExtnedPro';
import InventoryModal from '../Component/InventoryModal';
import Composition from '../Component/Composition';
import ProductIdentifiers from '../Component/ProductIdentifiers';
import { RiDeleteBin5Fill, RiQuestionLine } from 'react-icons/ri'
import ListingDescription from '../Component/ListingDescription';
import PostageDefination from '../Component/PostageDefination';
import StockForcasting from '../Component/StockForcasting';
import { useStateContext } from '../Context/ContextProvider';
import { VscChromeRestore } from 'react-icons/vsc'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Topbar from './Topbar';
import { LiaSave } from 'react-icons/lia';
import { AiOutlineCaretDown, AiOutlineCaretRight } from 'react-icons/ai';
import ItemStats from '../Component/ItemStats';
import AddView from '../Component/Edit/AddView';
import ExportCSV from '../Component/ExportCSV';
import View from './View';
import EditView from '../Component/Edit/EditView';
import SettingInventory from '../Component/Edit/SettingInventory';
import BatchNumber from '../Component/BatchNumber';
import AuditLog from '../Component/AuditLog';
import { useNavigate } from 'react-router-dom';
const style = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  // p: 4,
};
const style1 = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: 650,
  bgcolor: 'background.paper',
  boxShadow: 24,
};
function ChildModal({ close }) {
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opene = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosee = () => {
    setAnchorEl(null);
  };
  const [expand, setexpand] = useState(true)
  const [tax, settax] = useState(false)
  const { inventoryData, wareHouse,setinventory, WareHouse,sku, allCetagore, setinventoryData, addinventory, getinventoryFun, getinventory, handleImageChange, remove, Createimge } = useStateContext()
  const handleOpen = () => {
    close()
    setOpen(true);
  };
  const handleOpen1 = () => {
    // close()
    setOpen1(true);
  };
  const handleClose = () => {
    setOpen(false);
    setinventory({
      binrack: "",
      batch_no: "",
      status: "",
      sell_by: "",
      level: "",
      value: "",
      expires_on:"",
      warehouse_id: ""
    })
  };
  const handleClose1 = () => {
    setOpen1(false);
  };
  const auditLog=[{
    audit_date: "2023-10-12 09:05:56",
audit_text: "Product details inserted for SYQ-819-DXI",

audit_type: `Product details inserted for ${sku?.sku}`,


username:"admin@gmail.com"
  }]
  const inputRef = useRef();
  const [files, setFiles] = useState(null);

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const adddata = () => {
    addinventory()
    handleClose()
  }
  const modalopen=()=>{
    const check=getinventory?.products?.filter((item)=>{
      if(item.item_no==inventoryData.item_no){
        return true
      }
    })
    console.log(check);
    if(check?.length>0){
      handleOpen1()
    }
    else{
      handleOpen()
    }
  }
  return (
    <>
      <button type="button" disabled={inventoryData.title ? false : true} onClick={modalopen} className="mx-2 my-3" style={{ border: "1px solid lightgray", color: "white", padding: ".3rem 1rem", background: "#839AB4", cursor: `${inventoryData.title ? "pointer" : "not-allowed"}` }} data-toggle="modal" data-target="#exampleModal">
        <LiaSave />Add
      </button>
      <div id='inventory'>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{ overflowY: "scroll" }}
        >
          <Box sx={{ ...style, width: expand ? 1200 : 800 }}>
            <div  >
              <div  >
                <div className="modal-content">
                  <div id='inventory_header' className='modal-header'>
                    <h5 className="modal-title" id="exampleModalLabel">Product Details {inventoryData.item_no} |{inventoryData.title} </h5>
                    <div>
                      <AuditLog auditLog={auditLog} value={[inventoryData]}/>
                      <button onClick={() => setexpand(!expand)} type="button" className="close" >
                        <span ><VscChromeRestore style={{ fontSize: "25px" }} /></span>
                      </button>
                      <button onClick={handleClose} type="button" className="close">
                        <span style={{ fontSize: "25px" }}  >×</span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">General <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Images <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">Postage defination <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="false">Listing description <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-5" data-mdb-toggle="tab" href="#ex1-tabs-5" role="tab" aria-controls="ex1-tabs-5" aria-selected="false">Channel SKU <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-6" role="tab" aria-controls="ex1-tabs-6" aria-selected="false">Suppliers <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-7" role="tab" aria-controls="ex1-tabs-7" aria-selected="false">Extended properties <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-8" role="tab" aria-controls="ex1-tabs-8" aria-selected="false">Inventory <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-9" role="tab" aria-controls="ex1-tabs-9" aria-selected="false">Composition <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-10" data-mdb-toggle="tab" href="#ex1-tabs-10" role="tab" aria-controls="ex1-tabs-10" aria-selected="false">Item stats <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-11" data-mdb-toggle="tab" href="#ex1-tabs-11" role="tab" aria-controls="ex1-tabs-11" aria-selected="false">product identifiers <RiQuestionLine /></a>
                      </li>
                    </ul>

                    <div className="tab-content" id="ex1-content">
                      <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                        <div className='genral_inventory'>
                          <h5 style={{ margin: "0 1rem", color: "#5770AD" }} >General:</h5>
                          <div className='row'>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='col-3'>

                              <label for="item_number" >Item Number<span style={{ color: "red" }}>*</span>:</label>
                            </div>
                            <div className='col-9'>
                              <input value={inventoryData.item_no} disabled style={{ cursor: 'not-allowed' }} id='item_number' className='w-75 py-2 px-2 rounded border' type='text' placeholder='Item number' />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="item_title" >Item title<span style={{ color: "red" }}>*</span>:</label>
                            </div>
                            <div className='col-9'>
                              <input value={inventoryData.title} onChange={(e) => setinventoryData({ ...inventoryData, title: e.target.value })} id='item_title' className=' w-75 py-2 px-2 rounded border' type='text' />
                            </div>
                          </div><div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="item_barcode" >Barcode<span style={{ color: "red" }}>*</span>:</label>
                            </div>
                            <div className='col-9'>
                              <input value={inventoryData.barcode} id='item_barcode' onChange={(e) => setinventoryData({ ...inventoryData, barcode: e.target.value })} className=' w-75 py-2 px-2 rounded border' type='text' />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="item_price">Purchase price:</label>
                            </div>
                            <div className='col-9'>
                              <input id='item_price' onChange={(e) => setinventoryData({ ...inventoryData, purchase_price: e.target.value })} className='py-2 px-2 rounded border' type='number' />

                            </div>
                          </div><div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="Retail price">Retail Price:</label>
                            </div>
                            <div className='col-9'>
                              <input id='Retail price' onChange={(e) => setinventoryData({ ...inventoryData, retail_price: e.target.value })} className='py-2 px-2 rounded border' type='number' />

                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="Tax">Tax:</label>
                            </div>
                            <div className='col-9'>
                              <input id='Tax' disabled={tax} style={{ cursor: `${tax ? "not-allowed" : "pointeroko"}` }} onChange={(e) => setinventoryData({ ...inventoryData, tax_rate: e.target.value })} className='py-2 px-2 rounded border' type='number' />
                              <input id='contry_tax' onChange={(e) => settax(e.target.checked)} type='checkbox' className='mx-2' />
                              <label for="contry_tax">Country specific rate</label>

                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>

                            </div>
                            <div className='col-9'>
                              <input id='contry_tax' type='checkbox' className='mx-2' />
                              <label for="contry_tax">Scan serial number when processing orders</label>

                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="Categore">Category:</label>

                            </div>
                            <div className='col-9'>

                              <select id='Categore' onChange={(e) => setinventoryData({ ...inventoryData, category: e.target.value })} className=' w-25 py-2 px-2 rounded border' type='text' placeholder='Categore'>
                                <option hidden>Select Category</option>
                                {allCetagore?.map((item) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>

                                  )
                                })}
                              </select>
                            </div>
                          </div>
                          {/* setinventoryData
                          inventoryData */}
                          <BatchNumber inventoryData={inventoryData} setinventoryData={setinventoryData}/>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="meta_data">Meta Data:</label>


                            </div>
                            <div className='col-9'>
                              <textarea id='meta_data' onChange={(e) => setinventoryData({ ...inventoryData, meta_data: e.target.value })} className='w-50 py-2 px-2 rounded border' type='text' />

                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                        <div className='image_add'>
                          <label style={{ background: "#EAEBEC", padding: "0.5rem 1rem", border: "1px solid lightgray", margin: "0.2rem" }} for={"img"}>Add Image</label>
                          <button
                            id="demo-positioned-button"
                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            style={{ background: "#EAEBEC", borderRadius: "0px", padding: ".5rem", border: "1px solid lightgray" }}
                          >
                            <AiOutlineCaretDown />
                          </button>
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={opene}
                            onClose={handleClosee}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            sx={{ paddingTop: "0px", top: "40px", left: "-105px", position: "absolute" }}
                          >
                            <MenuItem style={{ background: "#EAEBEC", border: "1px solid lightgray" }} onClick={handleClosee}>
                              <label for={"img"}>upload from Computer</label>

                            </MenuItem>
                            <MenuItem style={{ background: "#EAEBEC", border: "1px solid lightgray" }} onClick={handleClosee}>upload from URL</MenuItem>
                          </Menu>
                          <input style={{ display: "none" }} id={"img"} type='file' multiple onChange={handleImageChange} accept='image/*' />
                          <div>
                            <div className='img_box'>

                              <Createimge />
                            </div>
                            <div
                              className="dropzone"
                              style={{ opacity: "0" }}
                              onDragOver={handleDragOver}
                              onDrop={handleImageChange}
                            >
                              <h1>Drag and Drop Files to Upload</h1>
                              <h1>Or</h1>
                              <input
                                type="file"
                                multiple
                                onChange={(event) => setFiles(event.target.files)}
                                hidden
                                accept="image/png,image/jpeg"
                                ref={inputRef}
                              />
                              <button onClick={() => inputRef.current.click()}>Select Files</button>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                        <PostageDefination />

                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
                        <ListingDescription />
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
                        <SkuChanal />

                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
                        <Suppliers />
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">
                        <ExtnedPro />

                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-8" role="tabpanel" aria-labelledby="ex1-tab-8">
                        <InventoryModal wareHouse={wareHouse} />
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-9" role="tabpanel" aria-labelledby="ex1-tab-9">
                        <Composition />

                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-10" role="tabpanel" aria-labelledby="ex1-tab-10">
                        <div className='item-state'>
                          <h5>Item Stats</h5>
                          <ItemStats />
                        </div>
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-11" role="tabpanel" aria-labelledby="ex1-tab-11">
                        <ProductIdentifiers />
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-12" role="tabpanel" aria-labelledby="ex1-tab-12">
                        <StockForcasting />
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-13" role="tabpanel" aria-labelledby="ex1-tab-13">
                        <div className='postage_defination'>
                          <h6>Postage defination</h6>
                          <div className='inp_1'>
                            <label>Default Oostal Service:</label>
                            <select>
                              <option>default</option>
                            </select>
                          </div>
                          <div className='inp_1'>
                            <label>Packing Group:</label>
                            <select style={{ marginLeft: "55px" }}>
                              <option>Pack-jiffy-bag</option>
                            </select>
                          </div>
                        </div>
                        <div className='postage_defination'>
                          <h6>Weight <span>(by default in 'Grams')</span></h6>
                          <div className='inp_1'>
                            <div className='row'>
                              <div className='col-3'>
                                <label>Grams:</label>
                                <input type='number' style={{ width: "90px" }} placeholder='grams' />
                              </div>
                              <div className='col-3'>
                                <label>Oz:</label>
                                <input type='number' style={{ width: "90px" }} placeholder='Oz' />
                              </div>
                              <div className='col-3'>
                                <label>Pounds:</label>
                                <input type='number' style={{ width: "80px" }} placeholder='pounds' />
                              </div>
                              <div className='col-3'>
                                <label>kg:</label>
                                <input type='number' style={{ width: "90px" }} placeholder='kg' />
                              </div>
                            </div>
                            {/* <label>Default Oostal Service:</label>
                  <select>
                    <option>default</option>
                  </select> */}
                          </div>

                        </div>
                        <div className='postage_defination'>
                          <h6>Dimention <span>(by default in 'Centimetres')</span></h6>
                          <div className='inp_1'>
                            <div className='row'>
                              <div className='col-3'>
                                <label>width:</label>
                                <input type='number' style={{ width: "90px" }} placeholder='width' />
                              </div>
                              <div className='col-3'>
                                <label>Height:</label>
                                <input type='number' style={{ width: "90px" }} placeholder='height' />
                              </div>
                              <div className='col-3'>
                                <label>Depth:</label>
                                <input type='number' style={{ width: "80px" }} placeholder='depth' />
                              </div>
                              <div className='col-3'>
                                <label>Volume:</label>
                                <input type='number' style={{ width: "80px" }} placeholder='volume' />
                              </div>
                            </div>

                          </div>

                        </div>
                        <div className='postage_defination'>
                          <div className='row'>
                            <div className='col-7'>
                              <h6>Parts</h6>
                            </div>
                            <div className='col-5'>
                              <button style={{ marginLeft: "70px" }}>+Add new</button>
                              <button><Delete />Delete</button>
                            </div>

                          </div>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Name</th>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Width</th>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Height</th>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Depth</th>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Weight</th>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Packing type</th>
                              </tr>
                            </thead>
                            <tbody>


                            </tbody>
                          </table>


                          {/* <div className='inp_1'>
                    <div className='row'>
                        <div className='col-3'>
                        <label>width:</label>
                        <input type='number' style={{width:"90px"}} placeholder='width'/>
                        </div>
                        <div className='col-3'>
                        <label>Height:</label>
                        <input type='number' style={{width:"90px"}} placeholder='height'/>
                        </div>
                        <div className='col-3'>
                        <label>Depth:</label>
                        <input type='number' style={{width:"80px"}} placeholder='depth'/>
                        </div>
                        <div className='col-3'>
                        <label>Volume:</label>
                        <input type='number' style={{width:"80px"}} placeholder='volume'/>
                        </div>
                    </div>
        
                </div> */}

                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" onClick={adddata} className="mx-2 my-3" style={{ border: "1px solid lightgray", cursor: "pointer", color: "white", padding: ".3rem 1rem", zIndex: "99", background: "#839AB4" }}>
                      <LiaSave />Save
                    </button>
                    {/* <button type="button" onClick={addinventory} className="">Save</button> */}
                    <button className="mx-2" type="button" onClick={handleClose} style={{ border: "1px solid lightgray", color: "gray", padding: ".3rem 1rem" }}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
        <Modal
            open={open1}
            onClose={handleClose1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style1}>
              <div style={{ padding: "1rem", background: "#F2DEDE", color: "#B94A48" }}>
                <p>Error</p>
              </div>
              <div style={{ background: "#FFF9F9", color: "gray", padding: "1rem" }}>

                <p style={{ fontSize: "13px" }}>Item with this SKU already exists.</p>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <button onClick={handleClose1} style={{ padding: ".5rem 2.5rem", color: "gray", background: "#F2DEDE", border: "1px solid #EEC2C8" }}>Close window</button>
                </div>
              </div>
            </Box>
          </Modal>
      </div>
    </>
  );
}

function Inventory() {
  const { getViewId, createView, setSelectedImages, inventoryData, getSku, delView, prodelete, allview, getallView, sku, searchBy, filter, datacetagory, search, wareHouse, WareHouse, allCetagore, setinventoryData, addinventory, getinventoryFun, getinventory, handleImageChange, remove, Createimge, getByCategory } = useStateContext()
  const [select, setselect] = useState([])

  const auth=JSON.parse(localStorage.getItem("user_token"))
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const openModal = () => {
    getSku()
    setSelectedImages([])
    setinventoryData({
      item_no:sku?.sku,
      title:"",
      barcode:"",
      purchase_price:"",
      retail_price:"",
      meta_data:"",
      tax_rate:"",
      category:"",
      batch_type:"",
      default_postal_service_id:"",
      packaging_group_id: "",
      weight_grams: "",
      weight_ounces: "",
      weight_pounds: "",
      weight_kilograms:"",
      height: '',
      width: '',
      depth: '',
      volume: '',
      category: "",
    })
    if (sku?.sku) {
      handleOpen()
    }
  }
  const navigate=useNavigate()
  useEffect(() => {
    getinventoryFun()
    datacetagory()
    WareHouse()
    getallView()
    if(!auth){
      navigate("/")
    }

  }, [])
  const [inpValue, setInpValue] = useState("")
  const searchinp = () => {
    if(inpValue){

      searchBy(inpValue)
    }
    else{
    getinventoryFun()

    }
  }
  const [anchorEl1, setAnchorEl1] = React.useState(null);
  const open1 = Boolean(anchorEl1);
  const handleClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl1(null);
  };
  const [delId, setdel] = useState()
  const delProduct = (id) => {
    setdel(id)
  }
  const defaultView = (name, value) => {
    const obj = {
      view_name: name,
      product_type: value,
      filters: [],
      columns: ['SKU', 'Title', 'Barcode Number', 'Retail Price', 'Purchase Price', 'Level', 'In Open Orders', 'Availble', 'Minimum Level', 'Tracked']

    }
    handleClose1()
    createView(obj)
  }
  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) {
      return text;
    }
    return text?.slice(0, maxLength) + '...';
  };
  return (
    <>
    {auth&&

    <div id="inventory">
      <Topbar />
      <div className="inventory_container border">
        <div className="firstRow">
          <select style={{ border: "1px solid lightgray", color: "gray", width: "150px", fontSize: "13px" }}>
            <option hidden>SKU / Title / Barcode</option>
            <option >Amazon ASIN</option>
            <option >Barcode</option>
            <option >Batch Number</option>
            <option >Batch number scan</option>
            <option >Batch Type</option>
            <option >Binrack</option>
            <option >Chanal SKU</option>
            <option >Default Supplier</option>
            <option >ebay Id</option>
            <option >Extended Property</option>
            <option >GeneralWhithSeprationgword</option>
            <option >Just In time</option>
            <option >Main Image</option>
            <option >SKU</option>
            <option >Title</option>
          </select>

          <div className="search-bar ">
            <div >
              <input onChange={(e) => setInpValue(e.target.value)} placeholder="Search by SKU, Title, Barcode" style={{ width: "200px", fontSize: "13px" }} />
            </div>
          </div>
          <select style={{ border: "1px solid lightgray", color: "gray", width: "200px", fontSize: "13px" }} onChange={(e) => getByCategory(e.target.value)}>
            <option value={0}>All Category</option>
            {allCetagore?.map((item) => {
              return (
                <option value={item.id}>{item.name}</option>
              )
            })}
          </select>
          <div onClick={searchinp} style={{ border: "1px solid lightgray", color: "gray" }} className="search_btn">
            <i className="fa fa-thin fa-search" /> Search
          </div>
        </div>
        <div className="secondRow">
          <ul style={{ zIndex: "1" }} className="nav nav-tabs nav-justified mb-3" id="ex1" role="tablist">
            <li className="nav-item" role="presentation">
              <a className="nav-link active" id="ex3-tab-1" data-mdb-toggle="tab" href="#ex3-tabs-1" role="tab" aria-controls="ex3-tabs-1" aria-selected="true">
                <div style={{ display: "flex", alignItems: "center" }}
                  aria-controls={open1 ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open1 ? 'true' : undefined}
                  onClick={getinventoryFun}>
                  Default<i onClick={handleClick1} className="fa fa-bars mx-2" />
                </div>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl1}
                  open={open1}
                  onClose={handleClose1}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <MenuItem className='menu_item' >
                    <div className='row'>
                      <div onClick={() => defaultView("Default", "Default")} className='col-9'>Default</div>
                      <View />
                    </div>
                  </MenuItem>
                  <MenuItem className='menu_item' >
                    <div className='row'>
                      <div className='col-9' onClick={handleClose1}>Channels</div>

                    </div>

                  </MenuItem>
                  <MenuItem className='menu_item' >
                    <div className='row'>
                      <div className='col-9' onClick={() => defaultView("Variation Parent", "variation_parent")}>Variation Parent</div>
                      <View />
                    </div>
                  </MenuItem>
                  <MenuItem className='menu_item' >
                    <div className='row'>
                      <div className='col-9' onClick={() => defaultView("Non variation Parent", "all columns")}>Non variation Parent</div>
                      <View />
                    </div></MenuItem>
                  <MenuItem className='menu_item' >
                    <div className='row'>
                      <div className='col-9' onClick={() => defaultView("Not part of a variation group", "all columns")}>Not part of a variation group</div>
                      <View />
                    </div></MenuItem>
                  <MenuItem className='menu_item' >
                    <div className='row'>
                      <div className='col-9' onClick={() => defaultView("All columns", "")}>All columns</div>
                      <View />
                    </div></MenuItem>
                </Menu></a>
            </li>
            {allview?.map((item) => {
              return (
                <li style={{ whiteSpace: "nowrap" }} className="nav-item mx-2" role="presentation">
                  <a onClick={() => getViewId(item.id)} className="nav-link" id="ex3-tab-2" data-mdb-toggle="tab" href="#ex3-tabs-2" role="tab" aria-controls="ex3-tabs-2" aria-selected="false"><div className='row'>
                    <div className='col-7'>
                      {truncateText(item.view_name, 9)}

                    </div>
                    <div style={{ display: "flex", justifyContent: "end" }} className='col-2 mx-2'>
                      <div className="dropdown" >
                        <button style={{ color: "gray", background: "none", border: "none" }} className="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="fa fa-sharp fa-solid fa-caret-down" />
                        </button>
                        <div id='view_dropParent' style={{ background: "white" }} className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <EditView editValue={item} />
                          <a onClick={() => delView(item.id)} id='drop_downView' className="dropdown-item">Delete View</a>
                        </div>
                      </div>
                    </div>
                  </div></a>
                </li>


              )
            })}
            <li>
              <AddView />
            </li>

          </ul>
          <div style={{ display: "none" }} className="tab-content" id="ex2-content">
            <div className="tab-pane fade show active" id="ex3-tabs-1" role="tabpanel" aria-labelledby="ex3-tab-1">
              Tab 1 content
            </div>
            <div className="tab-pane fade" id="ex3-tabs-2" role="tabpanel" aria-labelledby="ex3-tab-2">
              Tab 2 content
            </div>
            <div className="tab-pane fade" id="ex3-tabs-3" role="tabpanel" aria-labelledby="ex3-tab-3">
              Tab 3 content
            </div>
          </div>
          {/* Tabs content */}
          <div className="secondSection">
            <select onChange={(e) => filter(e.target.value)} style={{ border: "1px solid lightgray", color: "gray", width: "200px", height: "35px" }}>
              <option value={0}>Default</option>

              {wareHouse?.map((item) => {
                return (
                  <option value={item.id}>{item.name}</option>

                )
              })}
            </select>
            <button type="button" onClick={openModal} className="mx-2" style={{ border: "1px solid lightgray", color: "gray", height: "35px" }}>
              + Add new
            </button>
            <Modal
              keepMounted
              open={open}
              onClose={handleClose}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box sx={style}>
                <div style={{ height: "70px", padding: "1.5rem 1rem", background: "#5D7B9E", color: "white" }}>
                  <h5>Add New Item</h5>
                </div>
                <div className='row my-4'>
                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", color: "gray" }} className='col-5'>
                    <label>Item number:</label>
                  </div>
                  <div className='col-7'>
                    <input onChange={(e) => setinventoryData({ ...inventoryData, item_no: e.target.value })} value={inventoryData.item_no} style={{ border: "1px solid lightgray", padding: ".3rem", width: "210px" }} />
                  </div>
                </div>
                <div className='row my-4'>
                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", color: "gray" }} className='col-5'>
                    <label>Item title:</label>
                  </div>
                  <div className='col-7'>
                    <input value={inventoryData.title} onChange={(e) => setinventoryData({ ...inventoryData, title: e.target.value })} style={{ border: "1px solid lightgray", padding: ".3rem", width: "210px" }} />
                  </div>
                </div>
                <div className='row my-4'>
                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", color: "gray" }} className='col-5'>
                    <label>Barcode:</label>
                  </div>
                  <div className='col-7'>
                    <input value={inventoryData.barcode} onChange={(e) => setinventoryData({ ...inventoryData, barcode: e.target.value })} style={{ border: "1px solid lightgray", padding: ".3rem", width: "210px" }} />
                  </div>
                </div>
                <div style={{ height: "70px", borderTop: "1px solid lightgray", background: "#F0F0F0" }}>
                  <div className='row'>
                    <div style={{ position: "relative" }} className='col-10'>
                      <div style={{ position: "absolute", right: "0%" }}>

                        <ChildModal close={handleClose} />
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "end" }} className='col-2'>

                      <button className="mx-2 my-3" type="button" onClick={handleClose} style={{ border: "1px solid lightgray", color: "gray", padding: ".3rem 1rem" }}>
                        Cancel
                      </button>
                    </div>
                  </div>

                </div>
              </Box>
            </Modal>
            {/* <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style, width: 400 }}>
          <h2 id="parent-modal-title">Text in a modal</h2>
          <p id="parent-modal-description">
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </p>
          <ChildModal />
        </Box>
      </Modal> */}
            {/* */}
            {/* <button type="button"onClick={handleOpen} className="mx-2" style={{ border: "1px solid lightgray", color: "gray" }}>
              + Add new
            </button> */}
            {/* <Modal
              keepMounted
              open={open}
              onClose={handleClose}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box sx={style}>
                <div style={{ height: "70px", padding: "1.5rem 1rem", background: "#5D7B9E", color: "white" }}>
                  <h5>Add New Item</h5>
                </div>
                <div className='row my-4'>
                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", color: "gray" }} className='col-5'>
                    <label>Item number:</label>
                  </div>
                  <div className='col-7'>
                    <input onChange={(e) => setinventoryData({ ...inventoryData, item_no: e.target.value })} value={inventoryData.item_no} style={{ border: "1px solid lightgray", padding: ".3rem", width: "210px" }} />
                  </div>
                </div>
                <div className='row my-4'>
                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", color: "gray" }} className='col-5'>
                    <label>Item title:</label>
                  </div>
                  <div className='col-7'>
                    <input onChange={(e) => setinventoryData({ ...inventoryData, title: e.target.value })} style={{ border: "1px solid lightgray", padding: ".3rem", width: "210px" }} />
                  </div>
                </div>
                <div className='row my-4'>
                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", color: "gray" }} className='col-5'>
                    <label>Barcode:</label>
                  </div>
                  <div className='col-7'>
                    <input style={{ border: "1px solid lightgray", padding: ".3rem", width: "210px" }} />
                  </div>
                </div>
                <div style={{ height: "70px", borderTop: "1px solid lightgray", background: "#F0F0F0" }}>
                  <div className='row'>
                    <div className='col-7'></div>
                    <div className='col-5'>
                      <button type="button" disabled={inventoryData.title ? false : true} onClick={handleClose} className="mx-2 my-3" style={{ border: "1px solid lightgray", color: "white", padding: ".3rem 1rem", background: "#839AB4", cursor: `${inventoryData.title ? "pointer" : "not-allowed"}` }} data-toggle="modal" data-target="#exampleModal">
                        <LiaSave />   Add
                      </button>
                      <button type="button" onClick={handleClose} className="mx-2" style={{ border: "1px solid lightgray", color: "gray", padding: ".3rem 1rem" }}>
                        Cancel
                      </button>
                    </div>
                  </div>

                </div>
              </Box>
            </Modal> */}

            <div className="btn-group exportBtnGrop">
              <ExportCSV />
            </div>
            <button onClick={() => prodelete(select)} style={{ color: "gray" }} className="deleteBtn">
              <RiDeleteBin5Fill /> Delete
            </button>
            <SettingInventory id={select} />
          </div>
        </div>
        <div className="tableSection my-2">
          <BasicTable setselect={setselect} select={select} delProduct={delProduct} datain={getinventory} delId={delId} wareHouse={wareHouse} />
          {/* {getinventory?.length?<BasicTable datain={getinventory} wareHouse={wareHouse} />:<p style={{textAlign:"center"}}>No item to show</p>} */}
        </div>
      </div>
    </div>
    }
    </>

  )
}

export default Inventory