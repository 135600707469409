import { Search } from '@mui/icons-material'
import React from 'react'
import Topbar from './Topbar'

function AmazonVendor() {
  return (
    <>
    <div className='amazon_vendor'>
    <Topbar/>
        <h2>Amazon Vendor Central - Purchase Orders</h2>
        <div className='table_box'>
            <div className='input'>
            <label>
                <Search style={{color:"#4A6BDB"}}/>
            </label>
            <input placeholder='Search'/>
            </div>
            <div className='table-responsive'>

            <table class="table table-sm">
  <thead>
    <tr>
      <th scope="col">
        <span>SubSource</span>
      </th>
      <th scope="col">
        <span>PO No.</span>
      </th>
      <th scope="col">
        <span>Type</span>
      </th>
      <th scope="col">
        <span>Order Date</span>
      </th>
      <th scope="col">
        <span>State</span>
      </th>
      <th scope="col">
        <span>Delivery Win...</span>
      </th>
      <th scope="col">
        <span>Delivery Win...</span>
      </th>
      <th scope="col">
        <span>No Item</span>
      </th>
      <th scope="col">
        <span>Value</span>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr>
      {/* <td></td>
      <td>Mark</td>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
      <td>@fat</td> */}
    </tr>
  </tbody>
</table>
  </div>

        </div>
    </div>
      </>
  )
}

export default AmazonVendor