
import React, { useEffect, useState } from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { Add, Delete } from '@mui/icons-material';

function ExtnedPro({data,setvalue,value}) {
  const{extandPro,setextandPro}=useStateContext()
  console.log(data);
    const addRow=()=>{
        var obj={
            name:"",
            value:"",
            type:"",
         
        }
  


        setTableData([...tableData,obj])
 
 
    }
    const [tableData, setTableData] = useState([]);
    useEffect(()=>{
        if(data){
            setTableData(data)
        }
    },[data])
    
      const handleInputChange = (e, rowIndex, columnName) => {
        const updatedData = [...tableData];
        updatedData[rowIndex][columnName] = e.target.value;
        setextandPro(updatedData)
        setTableData(updatedData);
        setvalue({...value,extendedproperties:updatedData})

      };
      const [multipulSelct,setmultipulSelct]=useState([])
      const [select,setselect]=useState([])
      const getselect = (id, event) => {
        if (event.ctrlKey || event.metaKey) {
          setselect([...select, id]);
          setmultipulSelct([...multipulSelct, id])
        } else {
          setselect([id]);
          setmultipulSelct([id])
        }
      }
      const isRowSelected = (id) => select.includes(id);
      const deleteFun = () => {
        var array = tableData.filter((item, i) => {
          return !select.includes(i);
        });
      
        setTableData(array)
        setvalue({...value,extendedproperties:array})

      };
  return (
<div className='Createsuppliers listing_discription'>
    
    <div className='row'>
        <div className='col-8'>
            <h5>Extended Properties:</h5>
        </div>
        <div className='col-4'>
            <button onClick={addRow}  type="button" className='btn1' >
                <Add/> Add new
            </button>
            

            <button onClick={deleteFun} type="button" className='btn2'  >
             <Delete/>   Delete
            </button>
        </div>
    </div>

<div className='table-responsive'>

<table className="table table-striped">
    <thead>
        <tr>
        <th scope="col">Name</th>
      <th scope="col">Value</th>
      <th scope="col">Type</th>


        </tr>
    </thead>
    <tbody>
        {tableData.map((item, index) => {
            return (
              <tr onClick={(e)=>getselect(index, e)} style={{ background: `${isRowSelected(index) ? "#93B1C9" : ""}`,color: `${isRowSelected(index) ? "white" : ""}`}}>
              <td><input onChange={(e) => handleInputChange(e, index, 'name')} value={item.name}/></td>
              <td><input onChange={(e) => handleInputChange(e, index, 'value')} value={item.value}/></td>
              <td><input onChange={(e) => handleInputChange(e, index, 'type')} value={item.type}/></td>
 
            </tr>
            )
        })}
    </tbody>
</table>
</div>

</div>
  )
}

export default ExtnedPro