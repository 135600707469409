import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Add, Close, Delete} from '@mui/icons-material';
import { FiSave } from 'react-icons/fi';
import { VscChromeRestore } from 'react-icons/vsc';
import { useStateContext } from '../Context/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 800,
    bgcolor: '#F9F9F9',
    boxShadow: 24,
};
const stylee = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: '#F9F9F9',
    boxShadow: 24,
};
function View() {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRows1, setSelectedRows1] = useState([]);

    const handleRowClick = (rowIndex, event) => {
        if (event.ctrlKey || event.metaKey) {
            if (selectedRows.includes(rowIndex)) {
                setSelectedRows(selectedRows.filter(row => row !== rowIndex));
            } else {
                setSelectedRows([...selectedRows, rowIndex]);
            }
        } else {
            setSelectedRows([rowIndex]);
        }
    };
    const handleRowClick1 = (rowIndex, event) => {
        if (event.ctrlKey || event.metaKey) {
            if (selectedRows1.includes(rowIndex)) {
                setSelectedRows1(selectedRows1.filter(row => row !== rowIndex));
            } else {
                setSelectedRows1([...selectedRows1, rowIndex]);
            }
        } else {
            setSelectedRows1([rowIndex]);
        }
    };

    const isRowSelected = (rowIndex) => selectedRows.includes(rowIndex);
    const isRowSelected1 = (rowIndex) => selectedRows1.includes(rowIndex);
    const header = ["In Open Orders", " Height", " Extended Property ", "GeneralWithSeparatingWords", " Just In Time", " Level", " Main image", " Minimum Level ", "Modified Date", " Purchase", " Depth", " Due", " eBay Id ", "Batch Type", " Bin Rack ", "Category", " Channel SKU ", "Creation Date ", "Daily Average Consumption", " Default Supplier", " Amazon ASIN", " Available", "Barcode", " Batch Number", " Batch Number Scan Required"]
    const header1 = ['SKU', 'Title', 'Barcode Number','Retail Price',  'Purchase Price',  'Bin Rack',  'Category', 'Created Date', 'Default Supplier',  'Depth',  'Height', 'Modified Date', 'Primary Image', 'Stock Value', 'Variation Group Name', 'Weight', 'Width']
    const [open, setOpen] = useState(false);
    const [open3, setOpen3] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [open1, setOpen1] = useState(false);
    const handleOpen1 = () => setOpen1(true);
    const handleClose1 = () => setOpen1(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);
    const [data, setdata] = useState()
    console.log(open);
    const selectData = () => {
        var array=[]
        selectedRows.map((item,i)=>{
            var obj={
                column:item,
                operator:"Equals",
                value:"",
          
            }
            array.push(obj)
        })
        setdata(array)
        setSelectedRows([])
        handleClose1()
    }
    const [data1,setdata1]=useState(['SKU', 'Title', 'Barcode Number','Retail Price', 'Purchase Price', 'Stock Level','In Open Orders', 'Availble', 'Minimum Level','Tracked'])
    const selectData1 = () => {
        var array=[]
        selectedRows1.map((item)=>{
            setdata1([...data1,item])
        })
        handleClose3()
    }
    const handleClose2 = (value,i,varibale) => {
        const updatedData = [...data];
        console.log(i);
        updatedData[i][varibale] = value;
        setdata(updatedData);
    };
    const handleChage=(value,i,varibale)=>{
        const updatedData = [...data1];
        console.log(i);
        updatedData[i][varibale] = value;
        updatedData.map((item)=>{

            setdata1(updatedData)
        })
    }
    console.log(data);    
    const {createView}=useStateContext()
    const [inpdata,setinpdata]=useState({
            view_name:"",
            product_type:"",
            filters:[],
            columns:[]
        })
    const save=()=>{
        const obj={
            view_name:inpdata.view_name,
            product_type:inpdata.product_type,
            filters:data,
            columns:data1
        }
        console.log(obj);
        createView(obj)
    }
    return (
        <div className='col-3'>
            <button onClick={handleOpen}><Add style={{ fontSize: "15px" }} /></button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                        <h5>Inventory View Editor</h5>
                        <div>
                            <button style={{ border: "none", background: "none" }}><VscChromeRestore style={{ color: "whitesmoke", fontSize: "20px" }} /></button>
                            <button onClick={() => handleClose()} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                        </div>
                    </div>
                    <div style={{ height: "75vh", padding: "1rem 2rem", overflowX: "hidden" }}>

                        <div style={{ borderBottom: "1px solid lightgray", padding: "1rem 0" }} className='row'>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='col-3'>
                                <label style={{ textAlign: "end" ,fontSize:"13px"}}>View Name:</label>
                            </div>
                            <div className='col-9'>
                                <input onChange={(e)=>setinpdata({...inpdata,view_name:e.target.value})} type='text' style={{ padding: ".3rem", border: "1px solid lightgray", width: "100%" }} />
                            </div>
                        </div>
                        <div style={{ borderBottom: "1px solid lightgray", padding: "1rem 0" }} className='row'>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='col-3 my-3'>
                                <label style={{ textAlign: "end",fontSize:"13px" }}>Product type:</label>
                            </div>
                            <div className='col-9 my-3'>
                                <select onChange={(e)=>setinpdata({...inpdata,product_type:e.target.value})} style={{ padding: ".3rem", border: "1px solid lightgray", width: "200px",fontSize:"13px" }}>
                                    <option value={"All Products"}>All Products</option>
                                    <option value={"variation_parent"}>Variation Parents</option>
                                    <option value={"Non Variation Parents"}>Non Variation Parents</option>
                                    <option value={"Non part of Variation Group"}>Non part of Variation Group</option>
                                </select>
                            </div>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='col-3 my-3'>
                                <label style={{ textAlign: "end",fontSize:"13px" }}>Only changed products:
                                </label>
                            </div>
                            <div className='col-9 my-3'>
                                <input type='checkbox' style={{ padding: ".3rem", border: "1px solid lightgray" }} />
                            </div>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='col-3'>
                                <label style={{ textAlign: "end",fontSize:"13px" }}>Include archived:
                                </label>
                            </div>
                            <div className='col-9 my-3'>
                                <select style={{ padding: ".3rem", border: "1px solid lightgray", width: "200px" ,fontSize:"13px"}}>
                                    <option></option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <div style={{ color: "#5185D5", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                                <h6>Filter</h6>
                                <div>
                                    <button onClick={handleOpen1} style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", margin: "0 .5rem", color: "gray" }}><Add />Add new</button>
                                    <Modal
                                        open={open1}
                                        onClose={handleClose1}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={stylee}>
                                            <div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                                                <h5>Filter Chooser</h5>
                                                <div>
                                                    {/* <button style={{ border: "none", background: "none" }}>Ex</button> */}
                                                    <button onClick={handleClose1} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                                                </div>
                                            </div>
                                            <div className='view_section'>


                                                {/* Tabs navs */}
                                                <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">Product Details</a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Extended Properties</a>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">Channels</a>
                                                    </li>
                                                </ul>
                                                {/* Tabs navs */}
                                                {/* Tabs content */}
                                                <div className="tab-content" id="ex1-content">
                                                    <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                                                        <div style={{ border: "1px solid lightgray", height: "60vh", overflowY: "scroll", margin: ".5rem" }} className='table-responsive'>

                                                            <table className="table table-striped">
                                                                <thead style={{ border: "1px solid lightgray" }}>
                                                                    <tr>
                                                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3", fontWeight: "lighter" }} scope="col">Name</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>

                                                                    {header.map((item, rowIndex) =>
                                                                        <tr
                                                                            key={rowIndex}
                                                                            className={isRowSelected(item) ? 'selcted_tabledata' : ''}
                                                                            onClick={(event) => handleRowClick(item, event)}
                                                                        >
                                                                            <td>{item}</td>
                                                                        </tr>
                                                                    )}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                                                        <div style={{ border: "1px solid lightgray", height: "60vh", overflowY: "scroll", margin: ".5rem" }} className='table-responsive'>

                                                            <table className="table table-striped">
                                                                <thead style={{ border: "1px solid lightgray" }}>
                                                                    <tr>
                                                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3", fontWeight: "lighter" }} scope="col">Name</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {header.map((item) => {
                                                                        return (
                                                                            <tr>

                                                                                <td>{item}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                                                        <div style={{ border: "1px solid lightgray", height: "60vh", overflowY: "scroll", margin: ".5rem" }} className='table-responsive'>

                                                            <table className="table table-striped">
                                                                <thead style={{ border: "1px solid lightgray" }}>
                                                                    <tr>
                                                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3", fontWeight: "lighter" }} scope="col">Name</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {header.map((item) => {
                                                                        return (
                                                                            <tr>

                                                                                <td>{item}</td>
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ border: "1px solid lightgray", background: "#F0F0F0", display: "flex", justifyContent: "end", padding: "1rem" }}>
                                                <div>
                                                    <button onClick={selectData} style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button>
                                                    <button style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                                                </div>
                                            </div>
                                            {/* Tabs content */}
                                        </Box>
                                    </Modal>
                                    <button style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", color: "gray" }}><Delete />Delete</button>
                                </div>
                            </div>
                        </div>
                        <div style={{ border: "1px solid lightgray", height: "30vh" }} className='table-responsive'>

                            <table className="table table-striped">
                                <thead style={{ border: "1px solid lightgray" }}>
                                    <tr>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Name</th>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Condition</th>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Value</th>




                                    </tr>
                                </thead>
                                <tbody>
                                    {data?.map((item,i) => {
                                        return (
                                            <tr>

                                                <td>{item.column}</td>
                                                <td>
                                                    <select style={{width:"200px",outline:"none",border:"none",background:"none"}} onChange={(e)=>handleClose2(e.target.value,i,"operator")} value={item.operator}>
                                                        {/* <option value={"none"}>none</option> */}
                                                        <option value={"equals"}>equal</option>
                                                        <option value={"Contains"}>Contains</option>
                                                        <option value={"Not contains"}>Not contains</option>
                                                        <option value={"not equal"}>not equal</option>
                                                    </select>
                                                </td>
                                                <td><input style={{width:"200px",outline:"none",border:"none",background:"none"}} onChange={(e)=>handleClose2(e.target.value,i,"value")}/> </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div style={{ color: "#5185D5", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                                <h6>Columns:</h6>
                                <div>
                                    <button onClick={handleOpen3} style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", margin: "0 .5rem", color: "gray" }}><Add />Add new</button>
                                    <Modal
                                        open={open3}
                                        onClose={handleClose3}
                                        aria-labelledby="modal-modal-title"
                                        aria-describedby="modal-modal-description"
                                    >
                                        <Box sx={stylee}>
                                            <div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                                                <h5>Filter Chooser</h5>
                                                <div>
                                                    {/* <button style={{ border: "none", background: "none" }}>Ex</button> */}
                                                    <button onClick={handleClose3} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                                                </div>
                                            </div>
                                            <div className='view_section'>


                                            <div style={{ border: "1px solid lightgray", height: "60vh", overflowY: "scroll", margin: ".5rem" }} className='table-responsive'>

<table className="table table-striped">
    <thead style={{ border: "1px solid lightgray" }}>
        <tr>
            <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3", fontWeight: "lighter" }} scope="col">Name</th>
        </tr>
    </thead>
    <tbody>

        {header1.map((item, rowIndex) =>
            <tr
                key={rowIndex}
                className={isRowSelected1(item) ? 'selcted_tabledata' : ''}
                onClick={(event) => handleRowClick1(item, event)}
            >
                <td>{item}</td>
            </tr>
        )}
    </tbody>
</table>
</div>
                                     
                                            </div>
                                            <div style={{ border: "1px solid lightgray", background: "#F0F0F0", display: "flex", justifyContent: "end", padding: "1rem" }}>
                                                <div>
                                                    <button onClick={selectData1} style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button>
                                                    <button style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                                                </div>
                                            </div>
                                            {/* Tabs content */}
                                        </Box>
                                    </Modal>
                                    <button style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", color: "gray" }}><Delete />Delete</button>
                                </div>
                            </div>
                        </div>
                        <div style={{ border: "1px solid lightgray", height: "30vh" }} className='table-responsive'>

                            <table className="table table-striped">
                                <thead style={{ border: "1px solid lightgray" }}>
                                    <tr>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Name</th>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Sort Direction</th>





                                    </tr>
                                </thead>
                                <tbody>
                                {data1?.map((item,i) => {
                                        return (
                                            <tr>

                                                <td>{item}</td>
                                                <td>
                                                    <select style={{outline:"none",border:"none",background:"none"}} onChange={(e)=>handleChage(e.target.value,i,"sort_direction")} >
                                                        <option value={"none"}>none</option>
                                                        <option value={"equal"}>equal</option>
                                                    </select>
                                                </td>
                                                
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                        <div>
                            <div style={{ color: "#5185D5", padding: "1rem", display: "flex", justifyContent: "space-between" }}>

                                <h6>Channels:</h6>
                                <div>
                                    <button style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", margin: "0 .5rem", color: "gray" }}><Add />Add new</button>
                                    <button style={{ border: "1px solid lightgray", background: "#E9EAEB", padding: ".3rem .7rem", color: "gray" }}><Delete />Delete</button>
                                </div>
                            </div>
                        </div>
                        <div style={{ border: "1px solid lightgray", height: "30vh" }} className='table-responsive'>

                            <table className="table table-striped">
                                <thead style={{ border: "1px solid lightgray" }}>
                                    <tr>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">Source</th>
                                        <th style={{ border: "1px solid lightgray", padding: ".3rem", background: "#F2F3F3" }} scope="col">SubSource</th>




                                    </tr>
                                </thead>
                                <tbody>

                                </tbody>
                            </table>
                        </div>

                    </div>
                    <div style={{ border: "1px solid lightgray", background: "#F0F0F0", display: "flex", justifyContent: "end", padding: "1rem" }}>
                        <div>
                            <button onClick={save} style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button>
                            <button style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                        </div>
                    </div>
                </Box>
            </Modal>

        </div>
    )
}

export default View