import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close } from '@mui/icons-material';
import { FiSave } from 'react-icons/fi';
import { useStateContext } from '../Context/ContextProvider';
import { useState } from 'react';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 370,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
  };
function BatchNumber({setinventoryData,inventoryData}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const {setinventory,inventory,}=useStateContext()
    const [value,setvalue]=useState({
        binrack: "",
        batch_no: "",
        status: "",
        sell_by: "",
        unit_cost:"",
        level: "",
        value: "",
        priority:"",
        expires_on:"",
        warehouse_id: ""
    })
    console.log(value);
    const save=()=>{
        setinventory(value)
        handleClose()
        // console.log("heelo");
    }
  return (
    <>
    <div className='row'>
    <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
      <label for="Batch Type">Batch Type:</label>

    </div>
    <div className='col-9'>
      <select id='Batch Type' onChange={(e) => {
        setinventoryData({ ...inventoryData, batch_type: e.target.value })
        handleOpen()
        }} className='w-25 py-2 px-2 rounded border' type='text' placeholder='Batch Type'>
        <option hidden>none</option>
        <option value={0}>Ordered by sell date</option>
        <option value={1}>Ordered by priority</option>

      </select>
    </div>
        {inventoryData.batch_type==0?<Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
  <div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                        <h5>Business pricing rules - io</h5>
                        <div>
                            {/* <button style={{ border: "none", background: "none" }}><VscChromeRestore style={{ color: "whitesmoke", fontSize: "20px" }} /></button> */}
                            <button onClick={() => handleClose()} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                        </div>
                    </div>
                    <div className='main_box' style={{height:"70vh",background:"white"}}>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Batch No:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input onChange={(e)=>setvalue({...value,batch_no:e.target.value})}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Sell by:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input onChange={(e)=>{
                                    setvalue({...value,sell_by:e.target.value,priority:""})
                                    // setvalue({...value,priority:""})
                                    }}  type='date' style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Expires:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input onChange={(e)=>setvalue({...value,expires_on:e.target.value})}  type='date' style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Bin/rack:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input onChange={(e)=>setvalue({...value,binrack:e.target.value})}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Quantity:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input onChange={(e)=>setvalue({...value,level:e.target.value})}  type='number' style={{border:"1px solid lightgray",padding:".3rem",width:"200px"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Unit cost:</label>
                            </div>
                            <div style={{padding:"1rem"}} className='col-8'>
                                <input type='number' onChange={(e)=>{
                                    setvalue({...value,unit_cost:e.target.value,value:e.target.value*value.level})
                                    // setvalue({...value,value:e.target.value*value.level})
                                    }} style={{border:"1px solid lightgray",padding:".3rem",width:"200px"}}/>
                            </div>
                        </div>
                        <div style={{paddingLeft:"1rem"}} className='row'>
                            <div style={{padding:"1rem"}} className='col-3'>
                                <label style={{fontSize:"13px"}}>Status:</label>
                            </div>
                            <div  style={{padding:"1rem"}} className='col-8'>
                                <select onChange={(e)=>setvalue({...value,status:e.target.value})} style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}>
                                    <option value={"Available"}>Available</option>
                                    <option value={"Restricted"}>Restricted</option>
                                    <option value={"Damaged"}>Damaged</option>
                                    <option value={"Expired"}>Expired</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ backgroundColor: "#f0f0f0", borderTop: "1px solid #dee2e6!important",padding:"1.3rem",paddingRight:"0",width:"100%",margin:"0%" }}>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                        <button onClick={save}  style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button>
                            <button onClick={handleClose} style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                        </div>
                    </div>
  </Box>
</Modal>:  <Modal
open={open}
onClose={handleClose}
aria-labelledby="modal-modal-title"
aria-describedby="modal-modal-description"
>
<Box sx={style}>
<div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                      <h5>Business pricing rules - io</h5>
                      <div>
                          {/* <button style={{ border: "none", background: "none" }}><VscChromeRestore style={{ color: "whitesmoke", fontSize: "20px" }} /></button> */}
                          <button onClick={() => handleClose()} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                      </div>
                  </div>
                  <div className='main_box' style={{height:"70vh",background:"white"}}>
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Batch No:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                              <input  onChange={(e)=>setvalue({...value,batch_no:e.target.value})}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                          </div>
                      </div>
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Priority:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                              <input onChange={(e)=>{
                                    setvalue({...value,sell_by:"",priority:e.target.value})
                                    // setvalue({...value,priority:e.target.value})
                                    }}   type='number' style={{border:"1px solid lightgray",padding:".3rem",width:"200px"}}/>
                          </div>
                      </div>
                  
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Bin/rack:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                              <input  onChange={(e)=>setvalue({...value,binrack:e.target.value})}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}/>
                          </div>
                      </div>
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Quantity:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                              <input type='number'  onChange={(e)=>setvalue({...value,level:e.target.value})}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px"}}/>
                          </div>
                      </div>
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Unit cost:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                              <input type='number' onChange={(e)=>{
                                    setvalue({...value,unit_cost:e.target.value,value:e.target.value*value.level})
                                    // setvalue({...value,value:e.target.value*value.level})
                                    }}  style={{border:"1px solid lightgray",padding:".3rem",width:"200px"}}/>
                          </div>
                      </div>
                      <div style={{paddingLeft:"1rem"}} className='row'>
                          <div style={{padding:"1rem"}} className='col-3'>
                              <label style={{fontSize:"13px"}}>Status:</label>
                          </div>
                          <div style={{padding:"1rem"}} className='col-8'>
                          <select onChange={(e)=>setvalue({...value,status:e.target.value})} style={{border:"1px solid lightgray",padding:".3rem",width:"200px",outline:"none"}}>
                                    <option value={"Available"}>Available</option>
                                    <option value={"Restricted"}>Restricted</option>
                                    <option value={"Damaged"}>Damaged</option>
                                    <option value={"Expired"}>Expired</option>
                                </select>
                          </div>
                      </div>
                  </div>
                  <div className='row' style={{ backgroundColor: "#f0f0f0", borderTop: "1px solid #dee2e6!important",padding:"1.3rem",paddingRight:"0",width:"100%",margin:"0%" }}>
                      <div style={{ display: "flex", justifyContent: "end" }}>
                      <button  onClick={save} style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button>
                          <button onClick={handleClose} style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                      </div>
                  </div>
</Box>
</Modal>}
  </div>
    </>

  )
}

export default BatchNumber