import React, { useEffect, useState } from 'react'
import PostageDefination from './PostageDefination';
import ListingDescription from './ListingDescription';
import SkuChanal from './SkuChanal';
import Suppliers from './Suppliers';
import ExtnedPro from './ExtnedPro';
import ItemStats from '../ItemStats'
import InventoryModal from './InventoryModal';
import ProductIdentifiers from './ProductIdentifiers';
import Composition from './Composition';
import { RiQuestionLine } from 'react-icons/ri';
import { VscChromeRestore } from 'react-icons/vsc';
import { Delete } from '@mui/icons-material';
import { useStateContext } from '../../Context/ContextProvider';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
// import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
// import Topbar from './Topbar';
import { LiaSave } from 'react-icons/lia';
import { AiOutlineCaretDown } from 'react-icons/ai'
import AuditLog from '../AuditLog';
const style = {
  position: 'absolute',
  top: '10%',
  left: '50%',
  transform: 'translate(-50%, 0%)',
  width: 500,
  bgcolor: 'background.paper',
  boxShadow: 24,
  // p: 4,
};
function Check({ data, id, pro, setvalue, value }) {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const opene = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClosee = () => {
    setAnchorEl(null);
  };
  const [expand, setexpand] = useState(true)
  const [tax, settax] = useState(false)
  const[auditLog,setlog]=useState([])
  console.log(auditLog,"qwertyuiopoiuytwertyuiuytrewertyui");
  const { editData,base_url,token, inventoryData,imgBase_url, setSelectedImages, filter, datacetagory, search, wareHouse, WareHouse, allCetagore, setinventoryData, addinventory, getinventoryFun, getinventory, handleImageChange, remove, Createimge } = useStateContext()
  const handleOpen = () => {
    setvalue(pro)
    setOpen(true);
    setSelectedImages([])
    fetch(`${base_url}auditproduct/${pro?.id}`,{
      headers:{
        'Authorization': token,
      }
    })
      .then(res => res.json())
      .then(resp => {
    console.log(resp);
    setlog(resp)
  
      })

  };
  const handleClose = () => {
    setOpen(false);
  };
  console.log(pro);
  const sendData = () => {
    editData(value)
    handleClose()
  }
  const delimage=(id)=>{

  let array=value?.images?.filter((item)=>{
      if(item.id!==id){
        return item
      }
    })
    setvalue({...value,images:array})
    const image = JSON.stringify(array);
  const data = new FormData();

    data.append(`pro_imgs[]`, image);
  data.append(`product_id`, value.id)
  fetch(`${base_url}update-image/${value.id}`,{
    method: "POST",
    headers: {
        'Authorization': token,
    },
    body: data

  })
  .then(res => res.json())
  .then(resp => {
console.log(resp);
// getinventoryFun()

})
}
console.log(wareHouse);
  console.log(pro,"pro");
  return (
    <>
      <button type="button" onClick={handleOpen} style={{ border: "none", color: "#0D9CFB", background: "none", fontSize: "12px" }}>
        {data}
      </button>
      <div id='inventory'>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
          style={{ overflowY: "scroll" }}
        >
          <Box sx={{ ...style, width: expand ? 1200 : 800 }}>
            <div  >
              <div  >
                <div className="modal-content">
                  <div id='inventory_header' className='modal-header'>
                    <h5 className="modal-title" id="exampleModalLabel">Product Details {data} |{value?.title} </h5>
                    <div>
                      <AuditLog auditLog={auditLog} value={value?.pro_inventory}/>
                      <button onClick={() => setexpand(!expand)} type="button" className="close" >
                        <span ><VscChromeRestore style={{ fontSize: "25px" }} /></span>
                      </button>
                      <button onClick={handleClose} type="button" className="close">
                        <span style={{ fontSize: "25px" }}  >×</span>
                      </button>
                    </div>
                  </div>
                  <div className="modal-body">
                    <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
                      <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">General <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Images <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">Postage definition <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="false">Listing descriptions <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-5" data-mdb-toggle="tab" href="#ex1-tabs-5" role="tab" aria-controls="ex1-tabs-5" aria-selected="false">Chanel SKU <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-6" role="tab" aria-controls="ex1-tabs-6" aria-selected="false">Suppliers <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-7" role="tab" aria-controls="ex1-tabs-7" aria-selected="false">Extended properties <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-8" role="tab" aria-controls="ex1-tabs-8" aria-selected="false">Inventory <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-9" role="tab" aria-controls="ex1-tabs-9" aria-selected="false">Composition <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-10" data-mdb-toggle="tab" href="#ex1-tabs-10" role="tab" aria-controls="ex1-tabs-10" aria-selected="false">Item stats <RiQuestionLine /></a>
                      </li>
                      <li className="nav-item" role="presentation">
                        <a className="nav-link" id="ex1-tab-11" data-mdb-toggle="tab" href="#ex1-tabs-11" role="tab" aria-controls="ex1-tabs-11" aria-selected="false">product identifiers <RiQuestionLine /></a>
                      </li>
                    </ul>

                    <div className="tab-content" id="ex1-content">
                      <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
                        <div className='genral_inventory'>
                          <h5 style={{ margin: "0 1rem", color: "#5770AD" }} >General:</h5>
                          <div className='row'>
                            <div style={{ display: "flex", justifyContent: "end", alignItems: "center" }} className='col-3'>

                              <label for="item_number" >Item Number:</label>
                            </div>
                            <div className='col-9'>
                              <input value={value?.item_no} disabled style={{ cursor: 'not-allowed' }} id='item_number' className='w-75 py-2 px-2 rounded border' type='text' placeholder='Item number' />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="item_title" >Item title:</label>
                            </div>
                            <div className='col-9'>
                              <input value={value?.title} onChange={(e) => setvalue({ ...value, title: e.target.value })} id='item_title' className=' w-75 py-2 px-2 rounded border' type='text' />

                            </div>
                          </div><div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="item_barcode" >Barcode:</label>
                            </div>
                            <div className='col-9'>
                              <input value={value?.barcode} id='item_barcode' onChange={(e) => setvalue({ ...value, barcode: e.target.value })} className=' w-75 py-2 px-2 rounded border' type='text' />
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="item_price">Purchase Price:</label>
                            </div>
                            <div className='col-9'>
                              <input id='item_price' onChange={(e) => setvalue({ ...value, purchase_price: e.target.value })} value={value?.purchase_price} className='py-2 px-2 rounded border' type='number' />

                            </div>
                          </div><div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="Retail price">Retail Price:</label>
                            </div>
                            <div className='col-9'>
                              <input id='Retail price' onChange={(e) => setvalue({ ...value, retail_price: e.target.value })} value={value?.retail_price} className='py-2 px-2 rounded border' type='number' />

                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="Tax">Tax:</label>
                            </div>
                            <div className='col-9'>
                              <input id='Tax' disabled={tax} style={{ cursor: `${tax ? "not-allowed" : "pointeroko"}` }} onChange={(e) => setvalue({ ...value, tax: e.target.value })} value={value?.tax} className='py-2 px-2 rounded border' type='number' />
                              <input id='contry_tax' onChange={(e) => settax(e.target.checked)} type='checkbox' className='mx-2' />
                              <label for="contry_tax">Country specific rate</label>

                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>

                            </div>
                            <div className='col-9'>
                              <input id='contry_tax' type='checkbox' className='mx-2' />
                              <label for="contry_tax">Scan serial number when processing orders</label>

                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="Categore">Category:</label>

                            </div>
                            <div className='col-9'>

                              <select id='Categore' value={value?.category} onChange={(e) => setvalue({ ...value, category: e.target.value })} className=' w-25 py-2 px-2 rounded border' type='text' placeholder='Categore'>
                                <option hidden>Select Categore</option>
                                {allCetagore?.map((item) => {
                                  return (
                                    <option value={item.id}>{item.name}</option>

                                  )
                                })}
                              </select>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="Batch Type">Batch Type:</label>

                            </div>
                            <div className='col-9'>
                              <select id='Batch Type' onChange={(e) => setvalue({ ...value, batch_type: e.target.value })} className='w-25 py-2 px-2 rounded border' type='text' placeholder='Batch Type'>
                                <option hidden>Batch type</option>
                                <option value={0}>Ordered by sell date</option>
                                <option value={1}>Ordered by priority</option>

                              </select>
                            </div>
                          </div>
                          <div className='row'>
                            <div className='col-3' style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                              <label for="meta_data">Meta Data:</label>


                            </div>
                            <div className='col-9'>
                              <textarea id='meta_data' value={value?.meta_data} onChange={(e) => setvalue({ ...value, meta_data: e.target.value })} className='w-50 py-2 px-2 rounded border' type='text' />

                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
                        <div className='image_add'>
                          <label style={{ background: "#EAEBEC", padding: "0.5rem 1rem", border: "1px solid lightgray", margin: "0.2rem" }} for={"img"}>Add Image</label>
                          <button
                            id="demo-positioned-button"
                            aria-controls={open ? 'demo-positioned-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleClick}
                            style={{ background: "#EAEBEC", borderRadius: "0px", padding: ".5rem", border: "1px solid lightgray" }}
                          >
                            <AiOutlineCaretDown />
                          </button>
                          <Menu
                            id="demo-positioned-menu"
                            aria-labelledby="demo-positioned-button"
                            anchorEl={anchorEl}
                            open={opene}
                            onClose={handleClosee}
                            anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            sx={{ paddingTop: "0px", top: "40px", left: "-105px", position: "absolute" }}
                          >
                            <MenuItem style={{ background: "#EAEBEC", border: "1px solid lightgray" }} onClick={handleClosee}>
                              <label for={"img"}>upload from Computer</label>

                            </MenuItem>
                            <MenuItem style={{ background: "#EAEBEC", border: "1px solid lightgray" }} onClick={handleClosee}>upload from URL</MenuItem>
                          </Menu>
                          <input style={{ display: "none" }} id={"img"} type='file' multiple onChange={handleImageChange} accept='image/*' />
                          <div>
                            <div className='img_box'>

                              <Createimge />
                              {
                                value?.images?.map((item, i) => {
                                  return (
                                    <>{i === 0 ?
                                      <div>
                                        <div style={{display:"flex",justifyContent:"space-between"}}>
                                        <p className='mx-1'>Mian image</p>
                                          <div style={{cursor:"pointer",fontWeight:"bold"}} onClick={()=>delimage(item.id)}>x</div>
                                        </div>
                                         
                                        <div style={{ border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column" }} className='main_img'>
                                          <img className='show_pic' src={imgBase_url+item.image_path} />
                                        </div>
                                      </div>
                                      :
                                      <div>
                                        <div style={{display:"flex",justifyContent:"end"}}>
                                        {/* <p className='mx-1'>Mian image</p> */}
                                          <div style={{cursor:"pointer",fontWeight:"bold"}} onClick={()=>delimage(item.id)}>x</div>
                                        </div>
                                      <div  style={{ border: "1px solid lightgray", display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column",marginTop:"25px" }} className='main_img'>
                                        {/* <div onClick={()=>delimage(item.id)}>x</div> */}
                                        <img className='show_pic' src={imgBase_url + item.image_path} />

                                      </div>
                                      </div>
                                    }
                                    </>

                                  )
                                })
                              }
                            </div>
                        
                          </div>

                        </div>
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
                        <PostageDefination data={value} setvalue={setvalue} />

                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
                        <ListingDescription value={value} setvalue={setvalue} data={value?.listing_description} pricing_descriptions={value?.pricing_descriptions} product_descriptions={value?.product_descriptions} title_descriptions={value?.title_descriptions} />
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
                        <SkuChanal setvalue={setvalue} value={value} data={value?.channel_sku} />

                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-6" role="tabpanel" aria-labelledby="ex1-tab-6">
                        <Suppliers data={value?.pro_supplier} setvalue={setvalue} value={value} />
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-7" role="tabpanel" aria-labelledby="ex1-tab-7">
                        <ExtnedPro setvalue={setvalue} value={value} data={value?.extendedproperties} />

                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-8" role="tabpanel" aria-labelledby="ex1-tab-8">
                        <InventoryModal data={pro?.pro_inventory} setvalue1={setvalue} value1={value} wareHouse={wareHouse} ware_id={value?.warehouse_id} />
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-9" role="tabpanel" aria-labelledby="ex1-tab-9">
                        <Composition sku={data} setvalue1={setvalue} value1={value} data={pro?.product_compositions} />
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-10" role="tabpanel" aria-labelledby="ex1-tab-10">
                        <div className='item-state'>
                          <h5>Item Stats</h5>

                          <ItemStats data={value} />
                        </div>
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-11" role="tabpanel" aria-labelledby="ex1-tab-11">
                        <ProductIdentifiers setvalue={setvalue} value={value} data={value?.pro_identifier} />
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-12" role="tabpanel" aria-labelledby="ex1-tab-12">
                      </div>
                      <div className="tab-pane fade" id="ex1-tabs-13" role="tabpanel" aria-labelledby="ex1-tab-13">
                        <div className='postage_defination'>
                          <h6>Postage defination</h6>
                          <div className='inp_1'>
                            <label>Default Oostal Service:</label>
                            <select>
                              <option>default</option>
                            </select>
                          </div>
                          <div className='inp_1'>
                            <label>Packing Group:</label>
                            <select style={{ marginLeft: "55px" }}>
                              <option>Pack-jiffy-bag</option>
                            </select>
                          </div>
                        </div>
                        <div className='postage_defination'>
                          <h6>Weight <span>(by default in 'Grams')</span></h6>
                          <div className='inp_1'>
                            <div className='row'>
                              <div className='col-3'>
                                <label>Grams:</label>
                                <input type='number' style={{ width: "90px" }} placeholder='grams' />
                              </div>
                              <div className='col-3'>
                                <label>Oz:</label>
                                <input type='number' style={{ width: "90px" }} placeholder='Oz' />
                              </div>
                              <div className='col-3'>
                                <label>Pounds:</label>
                                <input type='number' style={{ width: "80px" }} placeholder='pounds' />
                              </div>
                              <div className='col-3'>
                                <label>kg:</label>
                                <input type='number' style={{ width: "90px" }} placeholder='kg' />
                              </div>
                            </div>

                          </div>

                        </div>
                        <div className='postage_defination'>
                          <h6>Dimention <span>(by default in 'Centimetres')</span></h6>
                          <div className='inp_1'>
                            <div className='row'>
                              <div className='col-3'>
                                <label>width:</label>
                                <input type='number' style={{ width: "90px" }} placeholder='width' />
                              </div>
                              <div className='col-3'>
                                <label>Height:</label>
                                <input type='number' style={{ width: "90px" }} placeholder='height' />
                              </div>
                              <div className='col-3'>
                                <label>Depth:</label>
                                <input type='number' style={{ width: "80px" }} placeholder='depth' />
                              </div>
                              <div className='col-3'>
                                <label>Volume:</label>
                                <input type='number' style={{ width: "80px" }} placeholder='volume' />
                              </div>
                            </div>

                          </div>

                        </div>
                        <div className='postage_defination'>
                          <div className='row'>
                            <div className='col-7'>
                              <h6>Parts</h6>
                            </div>
                            <div className='col-5'>
                              <button style={{ marginLeft: "70px" }}>+Add new</button>
                              <button><Delete />Delete</button>
                            </div>

                          </div>
                          <table className="table table-striped">
                            <thead>
                              <tr>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Name</th>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Width</th>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Height</th>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Depth</th>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Weight</th>
                                <th style={{ borderRight: "1px solid lightgray", padding: "0 .5rem" }} scope="col">Packing type</th>
                              </tr>
                            </thead>
                            <tbody>


                            </tbody>
                          </table>



                        </div>

                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" onClick={sendData} className="mx-2 my-3" style={{ border: "1px solid lightgray", color: "white", padding: ".3rem 1rem", background: "#496C94" }}>
                      <LiaSave />Save
                    </button>
                    {/* <button type="button" onClick={addinventory} className="">Save</button> */}
                    <button className="mx-2" type="button" onClick={handleClose} style={{ border: "1px solid lightgray", color: "gray", padding: ".3rem 1rem" }}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  )
}

export default Check