import { Delete } from '@mui/icons-material'
import React, { useState } from 'react'
import { useStateContext } from '../../Context/ContextProvider';

function PostageDefination({data,setvalue}) {
  const {inventoryData,setinventoryData}=useStateContext()
  console.log(inventoryData,"hellooooooooo");
  const gram=(value)=>{
    setinventoryData({...inventoryData,weight_grams:value,weight_ounces:value/28.35,weight_pounds:value/453.6,weight_kilograms:value/1000})
    setvalue({...data,weight_grams:value,weight_ounces:value/28.35,weight_pounds:value/453.6,weight_kilograms:value/1000})
  }
  
    
  return (
    <>
                <div className='postage_defination'>
                <h6>Postage defination</h6>
                <div className='inp_1'>
                  <label>Default Postal Service:</label>
                  <select onChange={(e)=>setinventoryData({...inventoryData,default_postal_service_id:e.target.value})} >
                    <option value={1}>default</option>
                    <option value={1}>default</option>
                    <option value={1}>default</option>
                  </select>
                </div>
                <div className='inp_1'>
                  <label>Packing Group:</label>
                  <select onChange={(e)=>setinventoryData({...inventoryData,packaging_group_id:e.target.value})} style={{marginLeft:"55px"}}>
                    <option value={1}>Pack-jiffy-bag</option>
                    <option value={1}>Pack-jiffy-bag</option>
                    <option value={1}>Pack-jiffy-bag</option>
                  </select>
                </div>  
              </div>
              <div className='postage_defination'>
                <h6>Weight <span>(by default in 'Grams')</span></h6>
                <div className='inp_1'>
                    <div className='row'>
                        <div className='col-3'>
                        <label>Grams:</label>
                        <input value={data?.weight_grams}  onChange={(e)=>gram(e.target.value)} type='number' style={{width:"90px"}} placeholder='grams'/>
                        </div>
                        <div className='col-3'>
                        <label>Oz:</label>
                        <input value={data?.weight_ounces} disabled type='number'  style={{width:"90px"}} placeholder='Oz'/>
                        </div>
                        <div className='col-3'>
                        <label>Pounds:</label>
                        <input value={data?.weight_pounds}  disabled type='number'  style={{width:"80px"}} placeholder='pounds'/>
                        </div>
                        <div className='col-3'>
                        <label>kg:</label>
                        <input value={data?.weight_kilograms}  disabled type='number'  style={{width:"90px"}} placeholder='kg'/>
                        </div>
                    </div>
              
                </div>
         
              </div>
              <div className='postage_defination'>
                <h6>Dimention <span>(by default in 'Centimetres')</span></h6>
                <div className='inp_1'>
                    <div className='row'>
                        <div className='col-3'>
                        <label>width:</label>
                        <input value={data?.width} onChange={(e)=>setvalue({...data,width:e.target.value})} type='number' style={{width:"90px"}} placeholder='width'/>
                        </div>
                        <div className='col-3'>
                        <label>Height:</label>
                        <input value={data?.height} onChange={(e)=>setvalue({...data,height:e.target.value})} type='number' style={{width:"90px"}} placeholder='height'/>
                        </div>
                        <div className='col-3'>
                        <label>Depth:</label>
                        <input  value={data?.depth} onChange={(e)=>setvalue({...data,depth:e.target.value,volume:data.width*data.height*e.target.value})} type='number' style={{width:"80px"}} placeholder='depth'/>
                        </div>
                        <div className='col-3'>
                        <label>Volume:</label>
                        <input type='number' disabled value={data?.volume} style={{width:"80px"}} placeholder='volume'/>
                        </div>
                    </div>
        
                </div>
         
              </div>
              {/* <div className='postage_defination'>
                <div className='row'>
                    <div className='col-7'>
                    <h6>Parts</h6>
                    </div>
                    <div className='col-5'>
                        <button style={{marginLeft:"70px"}}>+Add new</button>
                        <button><Delete/>Delete</button>
                    </div>

                </div>
                <table className="table table-striped">
<thead>
<tr>
  <th style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} scope="col">Name</th>
  <th style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} scope="col">Width</th>
  <th style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} scope="col">Height</th>
  <th style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} scope="col">Depth</th>
  <th style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} scope="col">Weight</th>
  <th style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} scope="col">Packing type</th>
</tr>
</thead>
<tbody>
<tr>
  <th scope="row" style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >Mark</th>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >25</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >40</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >20</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >70</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >Boxes-small Box</td>

</tr>
<tr>
  <th scope="row" style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >Mark</th>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >25</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >40</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >20</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >70</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >Boxes-Large Box</td>

</tr>
<tr>
  <th scope="row" style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >Devied</th>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >25</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >40</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >20</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >70</td>
  <td style={{borderRight:"1px solid lightgray",padding:"0 .5rem"}} >Boxes-small Box</td>

</tr>
</tbody>
</table>
              </div> */}
    </>
  )
}

export default PostageDefination