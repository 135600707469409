import React from 'react'
import { useStateContext } from '../../Context/ContextProvider'
import { Menu, MenuItem } from '@mui/material'
import DuplicatePro from './DuplicatePro';

function SettingInventory({id}) {
    const {archive}=useStateContext()
  const [anchorEl1, setAnchoCrEl1] = React.useState(null);
    const open1 = Boolean(anchorEl1);
    const handleClick1 = (event) => {
      setAnchoCrEl1(event.currentTarget);
    };
    const handleClose1 = () => {
      setAnchoCrEl1(null);
    };
    const send=()=>{
        handleClose1()
        console.log(id);
        archive(id[0])
    }
  return (
    <>   <div
    aria-controls={open1 ? 'basic-menu' : undefined}
    aria-haspopup="true"
    aria-expanded={open1 ? 'true' : undefined}
    onClick={handleClick1}
    style={{ color: "gray" }} className="settingBtn">
    <i className="fa fa-solid fa-gear" />
  </div>
  <Menu
              id="basic-menu"
              anchorEl={anchorEl1}
              open={open1}
              onClose={handleClose1}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem className='menu_item' style={{width:"170px"}} >
                <div className='row'>
                <DuplicatePro id={id}/>
                 
                </div>
              </MenuItem>
              <MenuItem className='menu_item' >
                <div className='row'>
                  <div className='col-9' onClick={handleClose1}>Print labels</div>
                  {/* <View/> */}
                </div>

              </MenuItem>
              <MenuItem onClick={send} className='menu_item' >
                <div className='row'>
                  <div className='col-9' >Archive Item</div>
                 
                </div>
              </MenuItem>
       
            </Menu>
  </>
  )
}

export default SettingInventory