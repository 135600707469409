import React, { useEffect, useState } from 'react'
import { AiOutlineSearch } from 'react-icons/ai'
import { MdLocationOn } from 'react-icons/md'
import { HiRefresh } from 'react-icons/hi'
 import { IoIosCheckmark } from 'react-icons/io'
import { BiChevronDown, BiSolidTruck } from 'react-icons/bi'
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { Link } from 'react-router-dom'
import { useStateContext } from '../Context/ContextProvider'

function WeraHouse() { 
  const{getTrasfer,getTrasferData,wareHouse,WareHouse,getallTransfer}=useStateContext()
    console.log(getTrasferData,"dataatattata");
    useEffect(()=>{
        getTrasfer()
    WareHouse()

    },[])
   const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  return (
    <div className='warehouse_main'>
      <div className='row'>
        <div className='col-6'>
          <h5>Werahouse transfer</h5>

        </div>
        <div className='col-6'>
        <div>
      <Button
        // id="fade-button"
        className='btnn'
        style={{textTransform:"capitalize",fontWeight:"bold"}}
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
       New Transfer
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        <MenuItem onClick={handleClose}>
          <Link to={'/dashboard/New-stock-transfer'} style={{textDecoration:"none",color:"black"}}>
          New Stock Transfer <span style={{background:"#C82D34",padding:".1rem .7rem",margin:"0 10px",color:"white",borderRadius:"50px"}}>Beta</span>
          </Link>
          </MenuItem>
        <MenuItem onClick={handleClose}>
        <Link to={'/dashboard/New-stock-request'} style={{textDecoration:"none",color:"black"}}>
        
          New Stock Request <span style={{background:"#C82D34",padding:".1rem .7rem",margin:"0 10px",color:"white",borderRadius:"50px"}}>Beta</span>
        </Link>
          </MenuItem>
        <MenuItem onClick={handleClose}>New  FBA Stock <span style={{background:"#329E7E",padding:".1rem .7rem",margin:"0 40px",color:"white",borderRadius:"50px"}}>New</span>
        </MenuItem>
      </Menu>
    </div>


          {/* <button className='btnn'>New Transfer</button> */}
        </div>
      </div>
      <div className='ware_house'>
        <div className='row'>
          <div className='col-2'>
            <label><AiOutlineSearch className='icon' /></label>
            <input type='text' placeholder='Seacrh transfers...' />
          </div>
          <div className='col-2'>
            <label><MdLocationOn className='icon' /></label>
            <select onChange={(e)=>getallTransfer(e.target.value)} style={{outline:"none"}}>
              <option hidden>Default</option>
              <option value={0}>All location</option>

              {wareHouse?.map((item) => {
                return (
                  <option value={item.id}>{item.name}</option>

                )
              })}
            </select>
          </div>
          <div className='col-7'>
            <span>Last updated an hour ago</span>
            <button><HiRefresh /></button>
          </div>



        </div>
        {getTrasferData?.map((item)=>{
       return(
        <div className='row my-5'>
          <div style={{display:"flex",alignItems:"center"}}>

        <BiChevronDown style={{fontSize:"23px"}}/><h6 style={{fontWeight:"bold",marginTop:".5rem"}}>{item.name}</h6>
          </div>
        <div className='col-4'>
          <h6>Outbound</h6>
          {item?.outbound?.map((item)=>{
              return(
                <>
                {item?.status!=="in transit"?   
                <Link style={{textDecoration:"none",color:"black"}} to={item?.status=="reciveing" ?`/dashboard/mark-reciving/${item.transfer_id}`:`/dashboard/trsnfer/${item.transfer_id}`}>
                  <div className='card my-2'>
              <div style={{padding:"1rem"}}>
                {}
                {item.status=="draft"?
             <div style={{color:"black",background:"#E5E6E7"}} className='status_recive'>
             <span>{item.status}</span>
             </div>:""  
            }
              {item.status=="receiving"?
             <div className='status'>
             <BiSolidTruck className='icons' /><span>{item.status}</span>
           </div>:""  
            }
              {item.status=="recevied"?
            <div className='status_recive'>
            <IoIosCheckmark className='icons' /><span>{item.status}</span>
            </div>:""  
            }
              <p>Ref:<span>{item.transfer_id}</span></p>
              <div className='row'>
                <div className='col-4'>
                  <span>Created:<span>{item.transferred_at.split("T")[0]}</span></span>
                </div>
                <div style={{ border: "none" }} className='col-4'>
                  <span>Updated:<span>{item.transferred_at.split("T")[0]}</span></span>
                </div>

              </div>
              <div className='row'>
                <div style={{ border: "none" }} className='col-4'>
                  <span>From:<span>{item.from_warehouse.name}</span></span>
                </div>

              </div>
              <div className='row'>
                <div style={{ border: "none" }} className='col-4'>
                  <span>Destination:<span>{item.to_warehouse.name}</span></span>
                </div>

              </div>
            </div>
            <div className='card_bottom'>
              <span>{item.product_count} products</span>
              <span>{item.total_quantity}qty sent </span>
              {item.status=="received"?
              <span style={{border:"none"}}>18 qty received</span>
            :""  
            }
            </div>
            </div>
            </Link> 

            :""
                  
                }
                </>
          
              )
          })}
          {/* <div className='card'>
            <div style={{padding:"1rem"}}>

            <div className='status_recive'>
              <IoIosCheckmark className='icons' /><span>Received</span>
            </div>
            <p>Ref:<span>WT78604042143029</span></p>
            <div className='row'>
              <div className='col-4'>
                <span>Created:<span>4 Apr 2022</span></span>
              </div>
              <div style={{ border: "none" }} className='col-4'>
                <span>Updated:<span>4 Apr 2022</span></span>
              </div>

            </div>
            <div className='row'>
              <div style={{ border: "none" }} className='col-4'>
                <span>From:<span>Default</span></span>
              </div>

            </div>
            <div className='row'>
              <div style={{ border: "none" }} className='col-4'>
                <span>Destination:<span>Brighton Warehouse</span></span>
              </div>

            </div>
          </div>
          <div className='card_bottom'>
            <span>2 products</span>
            <span>25 qty sent </span>
            <span style={{border:"none"}}>18 qty received</span>
          </div>
          </div> */}
        </div>
        <div className='col-4'>
          <h6>In transit</h6>
         
          {item?.outbound?.map((item)=>{
              return(
                <>
                {item?.status=="in transit"?   
                                <Link style={{textDecoration:"none",color:"black"}} to={`/dashboard/start-reciving/${item.transfer_id}`}>
                   <div className='card my-2'>
              <div style={{padding:"1rem"}}>

              <div className='status'>
                <BiSolidTruck className='icons' /><span>In transit</span>
              </div>
              <p>Ref:<span>{item.transfer_id}</span></p>
              <div className='row'>
                <div className='col-4'>
                  <span>Created:<span>{item.transferred_at.split("T")[0]}</span></span>
                </div>
                <div style={{ border: "none" }} className='col-4'>
                  <span>Updated:<span>{item.transferred_at.split("T")[0]}</span></span>
                </div>

              </div>
              <div className='row'>
                <div style={{ border: "none" }} className='col-4'>
                  <span>From:<span>{item.from_warehouse.name}</span></span>
                </div>

              </div>
              <div className='row'>
                <div style={{ border: "none" }} className='col-4'>
                  <span>Destination:<span>{item.to_warehouse.name}</span></span>
                </div>

              </div>
            </div>
            <div className='card_bottom'>
              <span>{item.product_count} products</span>
              <span>{item.total_quantity}qty sent </span>
              {item.status=="received"?
              <span style={{border:"none"}}>18 qty received</span>
            :""  
            }            </div>
            </div>
            </Link>
            :""
                  
                }
                </>
          
              )
          })}
       {item?.inbound?.map((item)=>{
              return(
                <>
                {item?.status=="in transit"?
                                                <Link style={{textDecoration:"none",color:"black"}} to={`/dashboard/start-reciving/${item.transfer_id}`}>
                <div className='card my-2'>
              <div style={{padding:"1rem"}}>

              <div className='status'>
                <BiSolidTruck className='icons' /><span>In transit</span>
              </div>
              <p>Ref:<span>{item.transfer_id}</span></p>
              <div className='row'>
                <div className='col-4'>
                  <span>Created:<span>{item.transferred_at.split("T")[0]}</span></span>
                </div>
                <div style={{ border: "none" }} className='col-4'>
                  <span>Updated:<span>{item.transferred_at.split("T")[0]}</span></span>
                </div>

              </div>
              <div className='row'>
                <div style={{ border: "none" }} className='col-4'>
                  <span>From:<span>{item.from_warehouse.name}</span></span>
                </div>

              </div>
              <div className='row'>
                <div style={{ border: "none" }} className='col-4'>
                  <span>Destination:<span>{item.to_warehouse.name}</span></span>
                </div>

              </div>
            </div>
            <div className='card_bottom'>
              <span>{item.product_count} products</span>
              <span>{item.total_quantity}qty sent </span>
              {item.status=="received"?
              <span style={{border:"none"}}>18 qty received</span>
            :""  
            }            </div>
            </div>
            </Link>
            :""
                  
                }
                </>
          
              )
          })}

        </div>
        <div style={{ border: "none" }} className='col-4'>
          <h6>Inbound</h6>
          {item?.inbound?.map((item)=>{
              return(
                <>
                {item?.status!=="in transit"?
                                                <Link style={{textDecoration:"none",color:"black"}} to={item?.status=="receiving" ?`/dashboard/mark-reciving/${item.transfer_id}`:`/dashboard/trsnfer/${item.transfer_id}`}>
                <div className='card my-2'>
              <div style={{padding:"1rem"}}>

              {/* <div className='status_recive'>
              <IoIosCheckmark className='icons' /><span>{item.status}</span>
              </div> */}
              {item.status=="draft"?
             <div style={{color:"black",background:"#E5E6E7"}} className='status_recive'>
             <span>{item.status}</span>
             </div>:""  
            }
              {item.status=="receiving"?
             <div className='status'>
             <BiSolidTruck className='icons' /><span>{item.status}</span>
           </div>:""  
            }
              {item.status=="recevied"?
            <div className='status_recive'>
            <IoIosCheckmark className='icons' /><span>{item.status}</span>
            </div>:""  
            }
              {/* {item.status=="draft"?
             <div className='status_recive'>
             <span>{item.status}</span>
             </div>:""  
            } */}
              <p>Ref:<span>{item.transfer_id}</span></p>
              <div className='row'>
                <div className='col-4'>
                  <span>Created:<span>{item.transferred_at.split("T")[0]}</span></span>
                </div>
                <div style={{ border: "none" }} className='col-4'>
                  <span>Updated:<span>{item.transferred_at.split("T")[0]}</span></span>
                </div>

              </div>
              <div className='row'>
                <div style={{ border: "none" }} className='col-4'>
                  <span>From:<span>{item.from_warehouse.name}</span></span>
                </div>

              </div>
              <div className='row'>
                <div style={{ border: "none" }} className='col-4'>
                  <span>Destination:<span>{item.to_warehouse.name}</span></span>
                </div>

              </div>
            </div>
            <div className='card_bottom'>
              <span>{item.product_count} products</span>
              <span>{item.total_quantity}qty sent </span>
              {item.status=="received"?
              <span style={{border:"none"}}>18 qty received</span>
            :""  
            }            </div>
            </div>
            </Link>
            :""
                  
                }
                </>
          
              )
          })}

        </div>
      </div>
       )
      })
    }
      </div>
    </div>
  )
}

export default WeraHouse