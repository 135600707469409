import React, { useState } from 'react'
import Topbar from './Topbar'
import { Download, Search } from '@mui/icons-material'
import { FiFilter } from 'react-icons/fi'
// import { DateRangePicker } from 'rsuite';
// import 'rsuite/dist/styles/rsuite-default.css';
function StockCount() {

  return (
    <div className='stock_count'>
        <Topbar/>
        <div className='row'>
            <div className='col-9'>
                {/* <DateRangePicker/>/ */}
                <input type='datetime-local' placeholder='start date'/>
                <button className='mx-2 p-2'><FiFilter/></button>
                <button className='mx-2'><Search/></button>
            </div>
            <div className='col-3'>
                    <select disabled={true}>
                <option>

                   Default
                </option>
                    </select>
                    <button className='mx-2'><Download/>Exprot</button>
            </div>
        </div>
        <div className='table_box'>
            

        </div>
        <div className='last_data'>
            <p>Total Discrepancy</p>
            <p><span>Quantity:</span><span className='span'>0</span><span>Value:</span><span className='span'>£0.00</span></p>
        </div>
    </div>
  )
}
export default StockCount