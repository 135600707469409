import React, { useEffect, useState } from 'react'
import Topbar from './Topbar'
import { BiBook, BiEnvelope, BiPrinter, BiSave, BiSolidMessageRounded } from 'react-icons/bi'
import { FaRegNewspaper } from 'react-icons/fa'
import { RiDeleteBin5Line } from 'react-icons/ri'
import { useStateContext } from '../Context/ContextProvider'
import { FiFilter } from 'react-icons/fi'
import { ImDownload2 } from 'react-icons/im'
import AddProductPO from '../Component/AddProductPO'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import swal from 'sweetalert'
const style = {
  position: 'absolute',
  top: '25%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,

};
function CreateNewOp() {
  const { createPo, WareHouse, wareHouse, currency,
    currencyList, getsuplier, supplier, } = useStateContext()
    var time=new Date().getHours()+":"+new Date().getMinutes()
  const [data, setdata] = useState({
    status: "PENDING",
    reference: "",
    supplier_ref: "",
    date: "",
    location: "",
    supplier: "",
    expected: "",
    unit_cost_amounts: "",
    invoicing_currency: "",
    invoicing_currency: "",
    total_amount: "14",
    products: [],
    time:time
  })

  const azab = (products) => {
    const array = []
    products.map((item) => {
      var obj = {
        id: item.id,
        item_no: item.item_no,
        product_id: item?.pro_inventory?.product_id,
        qty: "1",
        line_total: parseInt(item?.purchase_price?item?.purchase_price:"0") + parseInt(item.tax == null ? "0" : item.tax),
        unit_cost: item?.purchase_price,
        retail_price: item?.retail_price,
        tax: item?.tax == null ? "0" : item?.tax,
        title: item?.title

      }
      array.push(obj)
    })
    setdata({ ...data, products: array })
  }
  console.log(data);
  useEffect(() => {
    let ren = ""
    var value = ""
    for (let i = 0; i < 10; i++) {
      ren = String.fromCharCode(Math.floor(Math.random() * 10) + 48)
      value += ren

    }
    console.log(value);
    setdata({ ...data, reference: value })
    WareHouse()
    getsuplier()
    currency()
  }, [])
  console.log(data);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [multipulSelct, setmultipulSelct] = useState([])
  const [select, setselect] = useState([])
  const getselect = (id, event) => {
    if (event.ctrlKey || event.metaKey) {

      setselect([...select, id]);
      setmultipulSelct([...multipulSelct, id])
    } else {
      setselect([id]);
      setmultipulSelct([id])
    }
  }
  const isRowSelected = (id) => select.includes(id);
  const delitem=()=>{
    var array;
    if(select.length>0){
      
    //   array=data?.products?.filter((item)=>{
    //     return(
    //      array= select?.filter((items)=>{
    //         if(item.id!==items){
    //           return item
    //         }
    //       })
    //     )
  
    //  })
     var array = data?.products?.filter((item, i) => {
      return !select.includes(item.id);
    });
  
    // setTableData(array)
    setdata({...data,products:array})
    }else{
      swal({
        title: "Product not selected",
        text: "change the warehouse ship from or destination Warehouse",
        icon: "warning",
        dangerMode: true,
    });
    }
    console.log(array);
  //  setdata({...data,products:array})
  //  setselect([])
  }
  return (
    <div className='create_po'>
      <Topbar />
      <div className='row'>
        <div className='col-5'>
          <h6>Purchase Order Edit - PO{data.reference}</h6>
        </div>
        <div className='col-7'>
          <button style={{cursor:"not-allowed"}}><FaRegNewspaper /> Properties</button>
          <button style={{cursor:"not-allowed"}}><BiBook /> Order Log</button>
          <button style={{cursor:"not-allowed"}}><BiSolidMessageRounded /> Notes</button>
          <button style={{ background: "#859CB6", color: "whitesmoke" }} onClick={() => createPo(data)}><BiSave /> Save</button>
          <button><RiDeleteBin5Line /> Delete</button>
          <button><BiEnvelope /> Email</button>
          <button><BiPrinter /> Print</button>
        </div>
      </div>
      <div className='row '>
        <div className='col-5'>

          <label>Status :</label>
          <input type='text' disabled={true} style={{ cursor: "not-allowed" }} value={data.status} className='form-control' />
          <button onClick={handleOpen} className='mx-2'> To Open</button>
          <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {data.products.length==0?<>
            <div style={{padding:"1rem",background:"#FFE7BE",color:"#866329"}}>
            <p>Cannot move to open</p>
          </div>
          <div style={{background:"#FFFCF5",color:"gray",padding:"1rem"}}>
           
            <p style={{fontSize:"13px"}}>At least one of the purchase order items should have a positive quantity before moving to open.</p>
            {/* <p style={{fontSize:"13px"}}>Stock Due for the selected location will be incremented by the quantity of items on order.</p> */}
            <div style={{display:"flex",justifyContent:"center"}}>
              {/* <button style={{padding:".5rem 2.5rem",color:"gray",background:"#D9EDF7",border:"1px solid lightgray",margin:'0 10px'}}>Yes</button> */}
              <button onClick={handleClose}  style={{padding:".5rem 2.5rem",color:"#866329",background:"#FFE7BE",border:"1px solid lightgray"}}>Close window</button>
            </div>
          </div>
          </>:<>
            <div style={{padding:"1rem",background:"#F2DEDE",color:"#B94A48"}}>
            <p>Purchase order has unsaved changes</p>
          </div>
          <div style={{background:"#FFF9F9",color:"gray",padding:"1rem"}}>
           
            <p style={{fontSize:"13px"}}>Save purchase order before moving it to OPEN</p>
            {/* <p style={{fontSize:"13px"}}>Stock Due for the selected location will be incremented by the quantity of items on order.</p> */}
            <div style={{display:"flex",justifyContent:"center"}}>
              {/* <button style={{padding:".5rem 2.5rem",color:"gray",background:"#D9EDF7",border:"1px solid lightgray",margin:'0 10px'}}>Yes</button> */}
              <button onClick={handleClose}  style={{padding:".5rem 2.5rem",color:"#B94A48",background:"#F2DEDE",border:"1px solid lightgray"}}>Close window</button>
            </div>
          </div>
          </>}
        </Box>
      </Modal>
        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Reference:</label>
            </div>
            <div className='col-8'>
              <input type='text' disabled={false} style={{ cursor: "not-allowed" }} value={`PO${data.reference}`} />
            </div>
          </div>
        </div>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Supplier Ref:</label>
            </div>
            <div className='col-8'>
              <input onChange={(e) => setdata({ ...data, supplier_ref: e.target.value })} type='text' />

            </div>
          </div>

        </div>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Date:</label>
            </div>
            <div className='col-8'>
              <input onChange={(e) => setdata({ ...data, date: e.target.value })} type='date' />

            </div>
          </div>

        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Location:</label>
            </div>
            <div className='col-8'>
              {/* <input type='text'  /> */}
              <select onChange={(e) => setdata({ ...data, location: e.target.value })}>
                <option hidden></option>

                {wareHouse?.map((item) => {

                  return (

                    <option value={item.id}>{item.name}</option>
                  )

                })

                }
              </select>

            </div>
          </div>

        </div>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label >Supplier:</label>
            </div>
            <div className='col-8'>
              <select onChange={(e) => setdata({ ...data, supplier: e.target.value })}>
                <option hidden></option>
                {supplier?.map((item) => {

                  return (

                    <option value={item.id}>{item.supplier_name}</option>
                  )

                })

                }
              </select>

            </div>
          </div>

        </div>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Expected:</label>
            </div>
            <div className='col-8'>
              <input onChange={(e) => setdata({ ...data, expected: e.target.value })} type='date' />

            </div>
          </div>

        </div>
      </div>
      <div className='row my-2'>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Invoicing currency:</label>
            </div>
            <div className='col-8'>
              <select onChange={(e) => setdata({ ...data, invoicing_currency: e.target.value })} style={{ width: "80px" }}>
                <option hidden></option>
                {currencyList?.map((item) => {
                  return (
                    <option value={item.id}>{item.name}</option>

                  )
                })}

              </select>

            </div>
          </div>

        </div>
        <div className='col-4'>
          <div className='row'>
            <div className='col-4'>
              <label>Unit Cost amounts:</label>
            </div>
            <div className='col-8'>
              <select onChange={(e) => setdata({ ...data, unit_cost_amounts: e.target.value })}>
                <option hidden></option>
                <option value={"Tax Exclusive"}>Tax Exclusive</option>
                <option value={"Tax Inclusive"}>Tax Inclusive</option>
                <option value={"No Tax"}>No Tax</option>
              </select>

            </div>
          </div>

        </div>
        <div style={{display:"flex",justifyContent:"end"}} className='col-12'>
          <AddProductPO azab={azab} setdata={setdata} data={data} />
          <button onClick={delitem}><RiDeleteBin5Line className='mx-1' />Delete Item</button>
          <button><ImDownload2 className='mx-1' />Export</button>

        </div>

      </div>
      <div className='table_box'>
        <div className='table-responsive'>

          <table class="table table-sm">
            <thead>
              <tr>

                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>SKU</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>

                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Sup.Code</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Spu.Barcode</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Supplier</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Title</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Quantity</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Unit Cost</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Tax Rate</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Tax</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>
                <th scope="col">
                  <div className='row'>
                    <div className='col-9'>Line Total</div>
                    <div className='col-2'><FiFilter /></div>
                  </div>
                </th>



              </tr>
            </thead>
            <tbody>
              {data?.products.map((item, i) => {
                return (
                  <tr onClick={(e) => getselect(item.id, e)} style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`, color: `${isRowSelected(item?.id) ? "white" : ""}` }}>
                    <td><input style={{ border: "none", background: "none", color: "gray" }} onChange={(e) => {
                      const newData = [...data.products];
                      newData[i].item_no = e.target.value;
                      setdata({ ...data, products: newData });
                    }} value={item.item_no} /></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><input style={{ border: "none", background: "none", color: "gray" }} onChange={(e) => {
                      const newData = [...data.products];
                      newData[i].title = e.target.value;
                      setdata({ ...data, products: newData });
                    }} value={item.title} /></td>

                    <td><input onChange={(e) => {
                      const newData = [...data.products];
                      newData[i].qty = e.target.value;
                      setdata({ ...data, products: newData });
                    }} style={{ border: "none", background: "none", color: "gray" }} value={item.qty} /></td>

                    <td><input onChange={(e) => {
                      const newData = [...data.products];
                      newData[i].unit_cost = e.target.value;
                      setdata({ ...data, products: newData });
                    }} style={{ border: "none", background: "none", color: "gray" }} value={item.unit_cost} /></td>
                    <td><input onChange={(e) => {
                      const newData = [...data.products];
                      newData[i].tax = e.target.value;
                      setdata({ ...data, products: newData });
                    }} style={{ border: "none", background: "none", color: "gray" }} value={item.tax} /></td>
                    <td>{"0.8%"}</td>
                    <td>{item.line_total}</td>
                    {/* <td><LiaEdit style={{fontSize:"22px"}}/></td> */}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>

      </div>
    </div>
  )
}

export default CreateNewOp