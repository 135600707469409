import React from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { Close, Delete } from '@mui/icons-material';
import { PiPrinter } from 'react-icons/pi';
import { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
// import { useStateContext } from '../../Context/ContextProvider';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',

    boxShadow: 24,

};
function Print({data}) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });
console.log(data);
    return (

        <>
            <button onClick={handleOpen}><PiPrinter /> Print</button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ padding: "1rem", display: "flex", justifyContent: "space-between", background: "#44688C", color: "white" }}>
                        <h5>Audit Log</h5><button onClick={handleClose} style={{ background: "none", border: "none" }}><Close style={{ color: "lightcyan" }} /></button>
                    </div>
                    <div ref={componentRef} style={{ padding: "1rem" }}>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <div>
                                <p style={{ lineHeight: "20px",margin:"0" }}>Company Name</p>
                                <p style={{ lineHeight: "20px",margin:"0" }}>123 Building Street, City, Country, Zip</p>
                                <p style={{ lineHeight: "20px",margin:"0" }}>Phone: +1 234 567 8901</p>
                                <p style={{ lineHeight: "20px",margin:"0" }}>Email: example@example.com</p>
                            </div>
                            <div style={{ width: "200px" }}>
                                <h4>Purchase Order</h4>
                                <div className='row p-2'>
                                    <div className='col-4'>
                                        <p style={{ fontWeight: "500",margin:"0" }}>Date:</p>
                                    </div>
                                    <div className='col-8'>
                                        <p style={{ fontWeight: "500",margin:"0" }}>{data?.date}</p>
                                    </div>
                                    <div className='col-4'>
                                        <p style={{ fontWeight: "500",margin:"0" }}>PO No:</p>
                                    </div>
                                    <div className='col-8'>
                                        <p style={{ fontWeight: "500",margin:"0" }}>{data?.reference}</p>
                                    </div>
                                    <div className='col-4'>
                                        <p style={{ fontWeight: "500",margin:"0" }}>Ref:</p>
                                    </div>
                                    <div className='col-8'>
                                        <p style={{ fontWeight: "500",margin:"0" }}>PO{data?.supplier_ref}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row2 mt-3' style={{ display: "flex", width: "100%", gap: "10px" }}>
                            <div className='supplier' style={{ width: "30%", }}>
                                <h6 style={{ background: "#c0c0c0", padding: ".3rem", fontWeight: "700" }}>Supplier</h6>
                                <div style={{ border: "1px solid",width:"100%",minHeight:"100px"}}>
                                    <p style={{ lineHeight: "20px" }}>{data?.supplier_name}</p>
                                    {/* <p style={{ lineHeight: "20px" }}>Cm Shoes</p> */}
                                </div>
                            </div>
                            <div className='deliverTo' style={{ width: "30%"}}>
                                <h6 style={{ background: "#c0c0c0", padding: ".3rem", fontWeight: "700" }}>Deliver to</h6>
                                <div style={{ border: "1px solid",width:"100%",minHeight:"100px"}}>
                                    <p style={{lineHeight:"20px",margin:"0"}}>Deleted 2</p>
                                    <p style={{lineHeight:"20px",margin:"0"}}>
                                        36C Broughton Street
                                        Manchester
                                        UK
                                        M8 8NN
                                    </p>
                                </div>
                            </div>
                            <div className='deliverdate' style={{ width: "30%" }}>
                                <div className='quotedDate'>
                                    <h6 style={{ background: "#c0c0c0", padding: ".3rem", fontWeight: "700", textAlign:"center" }}>Quoted Dilevry Date</h6>
                                    <div style={{textAlign:"center"}}>
                                        <p style={{lineHeight:"20px",margin:"0"}}>Sep 22, 2023</p>
                                    </div>
                                </div>
                                <div className='info'>
                                    <h6 style={{ background: "#c0c0c0", padding: ".3rem", fontWeight: "700" }}>info</h6>
                                    <div>
                                        <p style={{lineHeight:"20px",margin:"0"}}>
                                            Currency: GBP
                                        </p>
                                        <p style={{lineHeight:"20px",margin:"0"}}>
                                            Conversion Rate: 1.00
                                        </p>
                                        <p style={{lineHeight:"20px",margin:"0"}}>
                                            Payment Stauts: UNPAID
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <br />
                        <table style={{ width: "100%", border: "1px solid" }}>
                            <thead style={{ border: "1px solid" }}>
                                <tr>
                                    <th style={{ border: "1px solid",background:"#c0c0c0" }}>Item</th>
                                    <th style={{ border: "1px solid",background:"#c0c0c0" }}>Description</th>
                                    <th style={{ border: "1px solid",background:"#c0c0c0" }}>Color</th>
                                    <th style={{ border: "1px solid",background:"#c0c0c0" }}>Size</th>
                                    <th style={{ border: "1px solid",background:"#c0c0c0" }}>Qty</th>
                                    <th style={{ border: "1px solid",background:"#c0c0c0" }}>Unit Cost</th>
                                    <th style={{ border: "1px solid",background:"#c0c0c0" }}>Total Cost</th>
                                    <th style={{ border: "1px solid",background:"#c0c0c0" }}>Tax</th>
                                    <th style={{ border: "1px solid",background:"#c0c0c0" }}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ border: "1px solid" }}>1001</td>
                                    <td style={{ border: "1px solid" }}>Product Description</td>
                                    <td style={{ border: "1px solid" }}>Red</td>
                                    <td style={{ border: "1px solid" }}>M</td>
                                    <td style={{ border: "1px solid" }}>10</td>
                                    <td style={{ border: "1px solid" }}>$10</td>
                                    <td style={{ border: "1px solid" }}>$100</td>
                                    <td style={{ border: "1px solid" }}>$10</td>
                                    <td style={{ border: "1px solid" }}>$110</td>
                                </tr>
                                {/* Additional items go here */}
                            </tbody>
                        </table>
                        <br />
                        <div style={{display:"flex",justifyContent:"end"}} className="footer">
                            <div >
                            <p>Sub Total: $100</p>
                            <p>Tax Total: $10</p>
                            <p>Grand Total: $110</p>

                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "end", padding: "1rem",border:"1px solid lightgray" }}>
                        <button onClick={handlePrint} style={{ background: "#44688C", border: "none", padding: ".5rem 1rem",color:"white" }}>Print</button>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default Print