import { Add, Delete } from '@mui/icons-material'
import React from 'react'
import {BsFillBuildingFill}from 'react-icons/bs'
function ReorderLowStock() {
  return (
    <div className='reorder_lowStock'>
        <div className='row m-3'>
            <div className='col-6'>

        <h5>Reorder Low stock-to Minimum Level</h5>
            </div>
            <div className='col-6'>
                <div className='inp_box'>

            <label className='mx-2'>Destination:</label>
            <div style={{border:"1px solid lightgray",borderRadius:"5px",padding:'.1rem',width:"200px",display:"inline-block",background: "white"}}><BsFillBuildingFill className='icons'/><select style={{border:"none",width:"91%"}}>
                <option>Default</option>
                </select></div>
                </div>
            </div>
        </div>
        <div className="accordion m-3" id="accordionPanelsStayOpenExample">
    <div className="accordion-item my-2">
      <h2 className="accordion-header" id="panelsStayOpen-headingOne">
        <button className="accordion-button" style={{fontWeight:"bold"}} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
        SUPPLIERS <span style={{color:"gray",fontSize:"10px"}} className='mx-2'>item 17</span>
        <button className='createOp'><Add/>Create OP</button>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseOne" className="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
        <div className="accordion-body">
        <table className="table responsive-sm">
<thead>
<tr style={{background:"#F3F3F3"}}>

  <th scope="col" ><div style={{width:"400px"}} >Product Detail</div></th>
  <th scope="col"  ><div>Availble</div>
  </th>
  <th scope="col"><div>Due</div></th>
  <th scope="col"><div>Availble + Due</div>
  </th>
  <th scope="col"><div>Minimum Order Qty</div></th>
  <th scope="col"><div>Minimum Level</div></th>
  <th scope="col"><div>Qty Request</div></th>
  <th scope="col"><div>Padding</div></th>
  <th scope="col"><div>Qty to Order</div></th>
  <th scope="col"></th>
</tr>
</thead>
<tbody>

            <tr>
  
  <td><div style={{display:"flex",flexWrap:"wrap"}} >
        
    <div>
  <img src='https://ipsf.net/wp-content/uploads/2021/12/dummy-image-square.webp' alt='...'/>
    </div>
    <div>

    <h6>T-Shirt</h6>
  <span style={{fontWeight:"bold",color:"#2895d8"}}>SKU2345678900987654</span>
    </div>
    </div></td>
  <td>1</td>
  <td>1</td>
  <td>1</td>
  <td>1</td>
  <td>1</td>
  <td>12</td>
  <td>0</td>
  <td><input  style={{border:"1px solid lightgray",width:"100px",color:"gray",paddingLeft:".5rem"}}value={10}/></td>
  <td><Delete/></td>
</tr>


</tbody>
</table>
        </div>
      </div>
    </div>
    <div className="accordion-item my-2">
      <h2 className="accordion-header" id="panelsStayOpen-headingTwo">
        <button className="accordion-button collapsed" type="button" style={{fontWeight:"bold"}} data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
        SUPPLIERS <span style={{color:"gray",fontSize:"10px"}} className='mx-2'>item 17</span>
        <button className='createOp'><Add/>Create OP</button>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseTwo" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingTwo">
      <div className="accordion-body">
        <table className="table responsive-sm">
<thead>
<tr style={{background:"#F3F3F3"}}>

  <th scope="col" ><div style={{width:"400px"}} >Product Detail</div></th>
  <th scope="col"  ><div>Availble</div>
  </th>
  <th scope="col"><div>Due</div></th>
  <th scope="col"><div>Availble + Due</div>
  </th>
  <th scope="col"><div>Minimum Order Qty</div></th>
  <th scope="col"><div>Minimum Level</div></th>
  <th scope="col"><div>Qty Request</div></th>
  <th scope="col"><div>Padding</div></th>
  <th scope="col"><div>Qty to Order</div></th>
  <th scope="col"></th>
</tr>
</thead>
<tbody>

            <tr>
  
  <td><div style={{display:"flex",flexWrap:"wrap"}} >
        
    <div>
  <img src='https://ipsf.net/wp-content/uploads/2021/12/dummy-image-square.webp' alt='...'/>
    </div>
    <div>

    <h6>T-Shirt</h6>
  <span style={{fontWeight:"bold",color:"#2895d8"}}>SKU2345678900987654</span>
    </div>
    </div></td>
  <td>1</td>
  <td>1</td>
  <td>1</td>
  <td>1</td>
  <td>1</td>
  <td>12</td>
  <td>0</td>
  <td><input  style={{border:"1px solid lightgray",width:"100px",color:"gray",paddingLeft:".5rem"}}value={10}/></td>
  <td><Delete/></td>
</tr>


</tbody>
</table>
        </div>
      </div>
    </div>
    <div className="accordion-item my-2">
      <h2 className="accordion-header" id="panelsStayOpen-headingThree">
        <button className="accordion-button collapsed" style={{fontWeight:"bold"}} type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
        SUPPLIERS <span style={{color:"gray",fontSize:"10px"}} className='mx-2'>item 17</span>
        <button className='createOp'><Add/>Create OP</button>
        </button>
      </h2>
      <div id="panelsStayOpen-collapseThree" className="accordion-collapse collapse" aria-labelledby="panelsStayOpen-headingThree">
      <div className="accordion-body">
        <table className="table responsive-sm">
<thead>
<tr style={{background:"#F3F3F3"}}>

  <th scope="col" ><div style={{width:"400px"}} >Product Detail</div></th>
  <th scope="col"  ><div>Availble</div>
  </th>
  <th scope="col"><div>Due</div></th>
  <th scope="col"><div>Availble + Due</div>
  </th>
  <th scope="col"><div>Minimum Order Qty</div></th>
  <th scope="col"><div>Minimum Level</div></th>
  <th scope="col"><div>Qty Request</div></th>
  <th scope="col"><div>Padding</div></th>
  <th scope="col"><div>Qty to Order</div></th>
  <th scope="col"></th>
</tr>
</thead>
<tbody>

            <tr>
  
  <td><div style={{display:"flex",flexWrap:"wrap"}} >
        
    <div>
  <img src='https://ipsf.net/wp-content/uploads/2021/12/dummy-image-square.webp' alt='...'/>
    </div>
    <div>

    <h6>T-Shirt</h6>
  <span style={{fontWeight:"bold",color:"#2895d8"}}>SKU2345678900987654</span>
    </div>
    </div></td>
  <td>1</td>
  <td>1</td>
  <td>1</td>
  <td>1</td>
  <td>1</td>
  <td>12</td>
  <td>0</td>
  <td><input  style={{border:"1px solid lightgray",width:"100px",color:"gray",paddingLeft:".5rem"}}value={10}/></td>
  <td><Delete/></td>
</tr>


</tbody>
</table>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default ReorderLowStock