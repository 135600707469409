import { Add, Delete } from '@mui/icons-material'
import React, { useEffect, useState } from 'react'
import { BiSolidEdit } from 'react-icons/bi'
import { FiSave, FiFilter } from 'react-icons/fi'
import { useStateContext } from '../Context/ContextProvider'
import { RiDeleteBin5Line } from 'react-icons/ri'
function Location() {
  const { createWareHouse, deletWarehouse, wareHouse, WareHouse, editWareHouse } = useStateContext()
  console.log(wareHouse);
  const [itemId, setitem] = useState()
  const [data, setdata] = useState({
    name: "",
    address_1: "",
    address_2: "",
    city: "",
    region: "",
    postcode: "",
    country: ""

  })
  console.log(data);
  useEffect(() => {
    WareHouse()
  }, [])
  const valueget = (item) => {
    setdata({ ...data, name: item.name, address_1: item.address_1, address_2: item.address_2, city: item.city, region: item.region, country: item.country, postcode: item.postcode })
  }
  const [multipulSelct, setmultipulSelct] = useState([])
  const [select, setselect] = useState([])
  const getselect = (id, event) => {
    if (event.ctrlKey || event.metaKey) {

      setselect([...select, id]);
      setmultipulSelct([...multipulSelct, id])
    } else {
      setselect([id]);
      setmultipulSelct([id])
    }
  }
  const isRowSelected = (id) => select.includes(id);
  return (
    <div className='location_main'>
      <div className='row'>
        <div className='col-10'></div>
        <div className='col-2'>
          <button onClick={() => setdata({
            name: "",
            address_1: "",
            address_2: "",
            city: "",
            region: "",
            postcode: "",
            country: ""
          })} type="button" data-toggle="modal" data-target="#exampleModalCenter" style={{ fontSize: "14px", padding: "3px 10px" }}>
            <Add style={{ fontSize: "14px" }} /> Add New
          </button>
          <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalCenterTitle">Location Detail</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <div>
                    <ul className="nav nav-tabs mb-3" id="myTab0" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button className="nav-link active" id="home-tab0" data-mdb-toggle="tab" data-mdb-target="#home0" type="button" role="tab" aria-controls="home" aria-selected="true">
                          Detail
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button className="nav-link" id="profile-tab0" data-mdb-toggle="tab" data-mdb-target="#profile0" type="button" role="tab" aria-controls="profile" aria-selected="false">
                          To
                        </button>
                      </li>

                    </ul>
                    <div className="tab-content" id="myTabContent0">
                      <div className="tab-pane fade show active" id="home0" role="tabpanel" aria-labelledby="home-tab0">
                        <div className='row'>
                          <div className='col-3'>
                            <label>Name:</label>
                          </div>
                          <div className='col-9'>
                            <input value={data.name} onChange={(e) => setdata({ ...data, name: e.target.value })} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3'>
                            <label>Address1:</label>
                          </div>
                          <div className='col-9'>
                            <input value={data.address_1} onChange={(e) => setdata({ ...data, address_1: e.target.value })} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3'>
                            <label>Address2:</label>
                          </div>
                          <div className='col-9'>
                            <input value={data.address_2} onChange={(e) => setdata({ ...data, address_2: e.target.value })} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3'>
                            <label>City/Town:</label>
                          </div>
                          <div className='col-9'>
                            <input value={data.city} onChange={(e) => setdata({ ...data, city: e.target.value })} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3'>
                            <label>Region:</label>
                          </div>
                          <div className='col-9'>
                            <input value={data.region} onChange={(e) => setdata({ ...data, region: e.target.value })} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3'>
                            <label>Postel Code:</label>
                          </div>
                          <div className='col-9'>
                            <input value={data.postcode} type='number' onChange={(e) => setdata({ ...data, postcode: e.target.value })} />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='col-3'>
                            <label>Country:</label>
                          </div>
                          <div className='col-9'>
                            <input value={data.country} onChange={(e) => setdata({ ...data, country: e.target.value })} />
                          </div>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="profile0" role="tabpanel" aria-labelledby="profile-tab0">
                        Tab 2 content
                      </div>

                    </div>
                  </div>

                </div>
                <div className="modal-footer">
                  <button type="button" onClick={() => createWareHouse(data)} data-dismiss="modal"className="btnn"><FiSave /> Save</button>
                  <button type="button" className="btnn1" data-dismiss="modal">Cancel</button>
                </div>
              </div>
            </div>
          </div>
          <button onClick={() => deletWarehouse(select)} style={{ fontSize: "14px", padding: "3px 10px" }}><RiDeleteBin5Line style={{ fontSize: "14px" }} /> Delete</button>
        </div>
      </div>
      <div className='table_box'>
        <table className="table table-responsive">
          <thead>
            <tr style={{ background: "#F3F3F4" }}>
              <th scope="col"></th>
              <th style={{ width: "1000px" }} scope="col"><div className='row'>
                <div className='col-11'>Name</div>
                <div style={{ position: "relative" }} className='col-1'>
                  <FiFilter style={{ position: "absolute", right: "15%", top: "15%" }} />
                </div>
              </div></th>
              <th style={{ width: "20px" }} className='px-2' scope="col">IC</th>
              <th style={{ width: "100px" }} scope="col"></th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody>
            {wareHouse?.map((item, i) => {
              return (
                <tr onClick={(e) => getselect(item.id, e)} style={{ background: `${isRowSelected(item.id) ? "#93B1C9" : ""}`, color: `${isRowSelected(item?.id) ? "white" : ""}` }} className={`${i % 2 === 0 ? "" : "bg"}`}>
                  <td scope="row"></td>
                  <td style={{color: `${isRowSelected(item?.id) ? "white" : ""}`}}>{item.name}</td>
                  <td><input type='checkbox' /></td>
                  <td><button >Automition</button></td>
                  <td>
                    <button onClick={() => valueget(item)} type="button" data-toggle="modal" data-target={`#exampleModalCenter${i}`}>
                      <BiSolidEdit />
                    </button>
                    <div className="modal fade" id={`exampleModalCenter${i}`} tabIndex={-1} role="dialog" aria-labelledby={`exampleModalCenterTitle${i}`} aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                          <div className="modal-header">
                            <h5 className="modal-title" id={`exampleModalCenterTitle${i}`}>Location Detail</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <span aria-hidden="true">×</span>
                            </button>
                          </div>
                          <div className="modal-body">
                            <div>
                              <ul className="nav nav-tabs mb-3" id="myTab0" role="tablist">
                                <li className="nav-item" role="presentation">
                                  <button className="nav-link active" id="home-tab0" data-mdb-toggle="tab" data-mdb-target="#home0" type="button" role="tab" aria-controls="home" aria-selected="true">
                                    Detail
                                  </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                  <button className="nav-link" id="profile-tab0" data-mdb-toggle="tab" data-mdb-target="#profile0" type="button" role="tab" aria-controls="profile" aria-selected="false">
                                    To
                                  </button>
                                </li>

                              </ul>
                              <div className="tab-content" id="myTabContent0">
                                <div className="tab-pane fade show active" id="home0" role="tabpanel" aria-labelledby="home-tab0">
                                  <div className='row'>
                                    <div className='col-3'>
                                      <label>Name:</label>
                                    </div>
                                    <div className='col-9'>
                                      <input value={data.name} onChange={(e) => setdata({ ...data, name: e.target.value })} />
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-3'>
                                      <label>Address1:</label>
                                    </div>
                                    <div className='col-9'>
                                      <input value={data.address_1} onChange={(e) => setdata({ ...data, address_1: e.target.value })} />
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-3'>
                                      <label>Address2:</label>
                                    </div>
                                    <div className='col-9'>
                                      <input value={data.address_2} onChange={(e) => setdata({ ...data, address_2: e.target.value })} />
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-3'>
                                      <label>City/Town:</label>
                                    </div>
                                    <div className='col-9'>
                                      <input value={data.city} onChange={(e) => setdata({ ...data, city: e.target.value })} />
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-3'>
                                      <label>Region:</label>
                                    </div>
                                    <div className='col-9'>
                                      <input value={data.region} onChange={(e) => setdata({ ...data, region: e.target.value })} />
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-3'>
                                      <label>Postel Code:</label>
                                    </div>
                                    <div className='col-9'>
                                      <input value={data.postcode} type='number' onChange={(e) => setdata({ ...data, postcode: e.target.value })} />
                                    </div>
                                  </div>
                                  <div className='row'>
                                    <div className='col-3'>
                                      <label>Country:</label>
                                    </div>
                                    <div className='col-9'>
                                      <input value={data.country} onChange={(e) => setdata({ ...data, country: e.target.value })} />
                                    </div>
                                  </div>
                                </div>
                                <div className="tab-pane fade" id="profile0" role="tabpanel" aria-labelledby="profile-tab0">
                                  Tab 2 content
                                </div>

                              </div>
                            </div>

                          </div>
                          <div className="modal-footer">
                            <button type="button" onClick={() => editWareHouse(item.id, data)}  data-dismiss="modal" className="btnn"><FiSave /> Save</button>
                            <button type="button" className="btnn1" data-dismiss="modal">Cancel</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>

                </tr>
              )
            })}


          </tbody>
        </table>

      </div>
    </div>
  )
}

export default Location