
import React, { useState } from 'react'
import { useStateContext } from '../Context/ContextProvider'
import { Add, Delete } from '@mui/icons-material';

function ExtnedPro() {
  const{extandPro,setextandPro}=useStateContext()
  console.log(extandPro);
    const addRow=()=>{
        var obj={
            name:"",
            value:"",
            type:"",
         
        }
        setTableData([...tableData,obj])
    }
    const [tableData, setTableData] = useState([]);
    
      const handleInputChange = (e, rowIndex, columnName) => {
        const updatedData = [...tableData];
        updatedData[rowIndex][columnName] = e.target.value;
        setextandPro(updatedData)
        setTableData(updatedData);
      };
      const [multipulSelct,setmultipulSelct]=useState([])
      const [select,setselect]=useState([])
      const getselect = (id, event) => {
        if (event.ctrlKey || event.metaKey) {
          setselect([...select, id]);
          setmultipulSelct([...multipulSelct, id])
        } else {
          setselect([id]);
          setmultipulSelct([id])
        }
      }
      const isRowSelected = (id) => select.includes(id);
      const deleteFun = () => {
        var array = tableData.filter((item, i) => {
          return !select.includes(i);
        });
      
        setTableData(array)
      };
  return (
<div className='Createsuppliers listing_discription'>
    
    <div className='row'>
        <div className='col-8'>
            <h4>Extended Properties:</h4>
        </div>
        <div className='col-4'>
            <button onClick={addRow}  type="button" className='btn1' >
                <Add/> Add new
            </button>
            

            <button onClick={deleteFun} type="button" className='btn2' >
             <Delete/>   Delete
            </button>
        </div>
    </div>

<div className='table-responsive'>

<table className="table table-striped">
    <thead>
        <tr>
        <th scope="col">Name</th>
      <th scope="col">Value</th>
      <th scope="col">Type</th>


        </tr>
    </thead>
    <tbody>
        {tableData.map((item, index) => {
            return (
              <tr onClick={(e)=>getselect(index, e)} style={{ background: `${isRowSelected(index) ? "#93B1C9" : ""}`,color: `${isRowSelected(index) ? "white" : ""}`}}>
              <td><select style={{border:"none",outline:"none",background:"none"}} onChange={(e) => handleInputChange(e, index, 'name')} value={item.name}>
                <option hidden></option>
                <option value={"All Sizes_Trun"}>All Sizes_Trun</option>
                <option value={"All Colours_Trun"}>All Colours_Trun</option>
                <option value={"AllSizes"}>AllSizes</option>
                <option value={"En_Season"}>En_Season</option>
                <option value={"En_pattern"}>En_pattern</option>
                <option value={"ye names me ayenge"}>ye names me ayenge</option>
                </select></td>
              <td><input   onChange={(e) => handleInputChange(e, index, 'value')} value={item.value}/></td>
              <td>
              <select style={{border:"none",outline:"none",background:"none"}} onChange={(e) => handleInputChange(e, index, 'type')} value={item.type}>
                <option hidden></option>
                <option value={"attribute"}>attribute</option>
                <option value={"channel"}>channel</option>
                <option value={"compatibility"}>compatibility</option>
                <option value={"condition"}>condition</option>
                <option value={"fulfilment sku"}>fulfilment sku</option>
                <option value={"other"}>other</option>
                </select>
                {/* <input onChange={(e) => handleInputChange(e, index, 'type')} value={item.type}/> */}
                </td>
 
            </tr>
            )
        })}
    </tbody>
</table>
</div>

</div>
  )
}

export default ExtnedPro