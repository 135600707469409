import React, { useEffect, useState } from 'react'
import { ImDownload2 } from 'react-icons/im'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { VscChromeRestore } from 'react-icons/vsc';
import { HiQuestionMarkCircle } from 'react-icons/hi2';
import { GrFormClose } from 'react-icons/gr';
import { IoInformationCircle } from 'react-icons/io5'
import { Close, Search } from '@mui/icons-material';
import { BsCheckLg } from 'react-icons/bs'
import Checkbox from '@mui/material/Checkbox';
import { useStateContext } from '../Context/ContextProvider';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: "90vh",
    bgcolor: '#F9F9F9',
    // border: '2px solid #000',
    boxShadow: 24,

};

function ExportCSV() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { expoort } = useStateContext()
    const [exportData, setexport] = useState(
        {
            delimiter: ",",
            encoding: "UTF-8",
            exportType: "Inventory View",
            selectedColumns: [],
            productIds: []
        }
    )
    console.log(exportData);
    const [selectedValues, setSelectedValues] = useState([
        "Available",

"Due",

"In open orders",

"just in time",
 
"Minimum",

"Stock level",
 
"Stock value",

"Daily Average Consumption",

"Barcode",

"Batch Number",

"Batch Type",

"Cetagory",

"Created Date",

"Default Supplier",

"Depth",

"Height",

"Width"
    ]);

    // Function to update the selected values array
    const updateSelectedValues = (value) => {
        if (selectedValues.includes(value)) {
            // Value is already selected, remove it
            setSelectedValues(selectedValues.filter((item) => item !== value));
        } else {
            // Value is not selected, add it
            setSelectedValues([...selectedValues, value]);
        }
    };
    useEffect(()=>{
        setexport({...exportData,selectedColumns:selectedValues})

    },[selectedValues])
    const [expand, setexpand] = useState(false)
    return (
        <><button onClick={handleOpen} style={{ border: "1px solid lightgray", color: "gray" }} type="button" className="actionBtn"><ImDownload2 />Export</button>
            <button type="button" style={{ color: "gray" }} className="dropdown-toggle dropdown-toggle-split dropdown-split " data-bs-toggle="dropdown" aria-expanded="false">
                <i className="fa fa-sharp fa-solid fa-caret-down" />
            </button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, width: expand ? 1200 : 1000 }} >
                    <div className='export_header'>
                        <h5 style={{ color: "gray" }}>Export File</h5>
                        <div>
                            <HiQuestionMarkCircle style={{ fontSize: "20px", color: "lightgray", marginRight: "10px" }} />
                            <VscChromeRestore onClick={() => setexpand(!expand)} style={{ fontSize: "20px", color: "lightgray", marginRight: "10px" }} />
                            <GrFormClose onClick={handleClose} style={{ fontSize: "20px", color: "lightgray", marginRight: "10px" }} />
                        </div>
                    </div>
                    <div className='export_dataBox'>
                        <p>File Option <IoInformationCircle style={{ fontSize: "20px", color: "#6C8AEC", marginRight: "10px" }} /></p>
                        <div className='row my-3'>
                            <div className='col-5'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <label>Delimiter:</label>
                                    </div>
                                    <div className='col-3'>
                                        <select onChange={(e) => setexport({ ...exportData, delimiter: e.target.value })}>
                                            <option value={","}>comma</option>
                                            <option value={","}>tab</option>
                                            <option value={","}>pipe</option>
                                            <option value={","}>colon</option>
                                            <option value={","}>semicolon</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className='col-7'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <label>Escape character:</label>
                                    </div>
                                    <div className='col-3'>
                                        <select >
                                            <option value={`"`}>"</option>
                                            <option value={`'`}>'</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <div className='col-5'>
                                <div className='row'>
                                    <div className='col-3'>
                                        <label>Encoding:</label>
                                    </div>
                                    <div className='col-3'>
                                        <select onChange={(e) => setexport({ ...exportData, encoding: e.target.value })}>
                                            <option value={"UTF-8"}>UTF-8</option>
                                            <option value={"UTF-8"}>UTF-16</option>
                                            <option value={"UTF-8"}>UTF-16(Big endian)</option>
                                            <option value={"UTF-8"}>Shift-jis</option>

                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <p>Export Options <IoInformationCircle style={{ fontSize: "20px", color: "#6C8AEC", marginRight: "10px" }} /></p>
                        <div className='row'>
                            <div className='col-5'>
                                <div className='row'>




                                    <div className='col-3'>
                                        <label>Export type:</label>
                                    </div>
                                    <div className='col-3'>
                                        <select onChange={(e) => setexport({ ...exportData, exportType: e.target.value })}>
                                            <option value={"Inventory View"}>Inventory View</option>
                                            <option value={"Inventory View"}>Listing Description</option>
                                            <option value={"Inventory View"}>Extended Properties</option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='export_option'>

                            <div className='row'>
                                <div className='col-5'>
                                    <h6>Available:</h6>
                                    {/* <div className='inp_box'>
                                        <button>
                                            <Search />
                                        </button>
                                        <input placeholder='Search' />
                                    </div> */}
                                    <div className="accordion accordion-flush" id="accordionFlushExample">
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                                                    <Checkbox defaultChecked {...label}  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                                                    Stock
                                                </button>
                                            </h2>
                                            <div id="flush-collapseOne" className="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <div>

                                                        <Checkbox defaultChecked onChange={() => updateSelectedValues("Available")} {...label}  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Available</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label}  onChange={() => updateSelectedValues("Due")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Due</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label}  onChange={() => updateSelectedValues("In open orders")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">In open orders</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label}  onChange={() => updateSelectedValues("just in time")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">just in time</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label}  onChange={() => updateSelectedValues("Minimum")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">
                                                            Minimum</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label}  onChange={() => updateSelectedValues("Stock level")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Stock level</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label}  onChange={() => updateSelectedValues("Stock value")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Stock value</label>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingTwo">

                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
                                                    <Checkbox defaultChecked {...label}  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                                                    General
                                                </button>
                                            </h2>
                                            <div id="flush-collapseTwo" className="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Daily Average Consumption")}  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Daily Average Consumption</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Barcode")}  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Barcode</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Batch Number")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Batch Number</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Batch Type")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Batch Type</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Cetagory")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Cetagory</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Created Date")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Created Date</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Default Supplier")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Default Supplier</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Depth")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Depth</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Height")}  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Height</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Width")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Width</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Primary Image")}  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Primary Image</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Modified Date")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Modified Date</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Purchase price")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Purchase price</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Retail price")}  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Retail price</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Serial Number")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Serial Number</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Title")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Title</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Tracked")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Tracked</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Variation Group Name")}  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Variation Group Name</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Weight")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Weight</label>

                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="accordion-item">
                                            <h2 className="accordion-header" id="flush-headingThree">

                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                                                    <Checkbox defaultChecked {...label}  sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />
                                                    Location
                                                </button>
                                            </h2>
                                            <div id="flush-collapseThree" className="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                                <div className="accordion-body">
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Bin Rack")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Bin Rack</label>

                                                    </div>
                                                    <div>

                                                        <Checkbox defaultChecked {...label} onChange={() => updateSelectedValues("Location")} sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }} />

                                                        <label className="mx-2">Location</label>

                                                    </div>


                                                </div>                                            </div>
                                        </div>
                                    </div>



                                </div>
                                <div className='col-7'>
                                    <h6>selected:</h6>

                                    <ul>
                                        {selectedValues.map((option, index) => (
                                            <li key={index}>{option}</li>
                                        ))}
                                    </ul>




                                </div>
                            </div>
                        </div>


                    </div>
                    <div style={{ background: "#F0F0F0", padding: "1.4rem", borderTop: "1px solid gray", display: "flex", justifyContent: "end" }}>
                        <button className='mx-3' style={{ padding: ".5rem 1.5rem", borderRadius: "5px", border: "1px solid lightgray" }} onClick={handleClose} >Cancel</button>
                        <button style={{ padding: ".5rem 1rem", color: "white", borderRadius: "5px", border: "none", background: "#4867DF" }} onClick={() => expoort(exportData)} >Export</button>

                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default ExportCSV