import React, { useEffect, useState } from 'react'
// import { useStateContext } from '../../Context/ContextProvider'
import { Add, Delete } from '@mui/icons-material';
import ChildModal from '../ChildModal';
import AddComposition from './AddComposition';
import { useStateContext } from '../Context/ContextProvider';

function Composition({data,setvalue,value,sku}) {
  const{setcomposition}=useStateContext()
  console.log(data);

    const [tableData, setTableData] = useState([]);
    const [product, setproduct] = useState([]);
    useEffect(()=>{
            setTableData(product)   
            setcomposition(product)
   
    },[product])
      console.log(product);
      const handleInputChange = (e, rowIndex, columnName) => {
        const updatedData = [...tableData];
        updatedData[rowIndex][columnName] = e.target.value;
        // setextandPro(updatedData[updatedData.length-1])
        setTableData(updatedData);
        setcomposition(updatedData)
        // setvalue({...value,extendedproperties:updatedData})

      };
      const [multipulSelct,setmultipulSelct]=useState([])
      const [select,setselect]=useState([])
      const getselect = (id, event) => {
        if (event.ctrlKey || event.metaKey) {
          setselect([...select, id]);
          setmultipulSelct([...multipulSelct, id])
        } else {
          setselect([id]);
          setmultipulSelct([id])
        }
      }
      const isRowSelected = (id) => select.includes(id);
      const deleteFun = () => {
        var array = tableData.filter((item, i) => {
          return !select.includes(i);
        });
      
        setTableData(array)
      };
  return (
<div className='Createsuppliers listing_discription'>
    
    <div className='row'>
        <div className='col-8'>
            <h5>Composition:</h5>
        </div>
        <div className='col-4'>
               <AddComposition setdata={setproduct} data={product} sku={sku}/>
       
            

            <button onClick={deleteFun} type="button" className='btn2'  >
             <Delete/>   Delete
            </button>
        </div>
    </div>

<div className='table-responsive'>

<table className="table table-striped">
    <thead>
        <tr>
        <th scope="col">SKU</th>
      <th scope="col">Item Title</th>
      <th scope="col">Quantity</th>
        </tr>
    </thead>
    <tbody>
    {tableData.map((item,index)=>{
            return(
                <tr onClick={(e)=>getselect(index, e)} style={{ background: `${isRowSelected(index) ? "#93B1C9" : ""}`,color: `${isRowSelected(index) ? "white" : ""}`}}>
                <td><input onChange={(e) => handleInputChange(e, index, 'item_item_no')} value={item.item_item_no}/></td>
                <td><input onChange={(e) => handleInputChange(e, index, 'item_title')} value={item.item_title}/></td>
                <td><input onChange={(e) => handleInputChange(e, index, 'quantity')} value={item.quantity}/></td>
   
              </tr>
            )
        })}
    </tbody>
</table>
</div>

</div>
  )
}

export default Composition