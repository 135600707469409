import React, { useRef, useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useStateContext } from '../../Context/ContextProvider';
import { LiaSave } from 'react-icons/lia';
import Check from '../duplicat/Check';
import swal from 'sweetalert';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 500,
  bgcolor: 'background.paper',
//   border: '2px solid #000',
  boxShadow: 24,
//   p: 4,
};
function DuplicatePro({id}) {
  const inputRef = useRef(null);
    const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const [value,setvalue]=useState()
    const handleOpen1 = () => {

    setOpen1(true);
 
  };
    const handleOpen = () => setOpen(true);
    const {getSku,sku,getinventory,getinventoryFun,base_url,token}=useStateContext()
    const handleClose = () => setOpen(false);
    console.log(getinventory);
    const [data,setdata]=useState({
        sku:"",
        title:"",
        barcode:"",
        copy_images:false,
        product_id:id[0]
    })
    console.log(id);
    const send=()=>{
        // duplicate(data)
        handleClose()
        fetch(`${base_url}products/duplicate`,{
          method: "POST",
          headers: {
              'Content-Type': "application/json",
              "Accept": 'application/json',
              'Authorization': token,
          },
          body: JSON.stringify(data)
      
        })
        .then(res => res.json())
        .then(resp => {
        console.log(resp);
        setvalue(resp.new_product)
        handleOpen1()
        getinventoryFun()
        })
    }
    const getdata=()=>{
      if(id[0]){

        getSku()
       getinventory?.products?.filter((item)=>{
          if(item.id==id[0]){
            console.log(item);
            setdata({
              sku:sku?.sku,
              title:item.title,
              barcode:item.barcode,
              copy_images:false,
              product_id:item.id
          })
          }
          else if(item?.full_details?.id==id[0]){
            setdata({
              sku:sku?.sku,
              title:item?.full_details?.title,
              barcode:item?.full_details?.barcode,
              copy_images:false,
              product_id:item?.full_details?.id
          })
          }
        })
        if(sku?.sku){
  
          handleOpen()
        }
      }
      else{
        swal({
          title: "Select Product",
          text: "select product for duplicate",
          icon: "warning",
          dangerMode: true,
        });
      }
    }
    console.log(data);
  return (
    <>                        <div onClick={getdata}  className='col-9'>Duplicate item

    </div>
    <Modal
              keepMounted
              open={open}
              onClose={handleClose}
              aria-labelledby="keep-mounted-modal-title"
              aria-describedby="keep-mounted-modal-description"
            >
              <Box sx={style}>
                <div style={{ height: "70px", padding: "1.5rem 1rem", background: "#5D7B9E", color: "white" }}>
                  <h5>Duplicate Item</h5>
                </div>
                <div className='row my-4'>
                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", color: "gray" }} className='col-5'>
                    <label>Item number:</label>
                  </div>
                  <div className='col-7'>
                    <input value={data.sku} onChange={(e)=>setdata({...data,sku:e.target.value})} style={{ border: "1px solid lightgray", padding: ".3rem", width: "210px" }} />
                  </div>
                </div>
                <div className='row my-4'>
                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", color: "gray" }} className='col-5'>
                    <label>Item title:</label>
                  </div>
                  <div className='col-7'>
                    <input value={data.title} ref={inputRef} onKeyDown={(e)=>{
                     
                    }} onChange={(e)=>setdata({...data,title:e.target.value})}  style={{ border: "1px solid lightgray", padding: ".3rem", width: "210px" }} />
                  </div>
                </div>
                <div className='row my-4'>
                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", color: "gray" }} className='col-5'>
                    <label>Barcode:</label>
                  </div>
                  <div className='col-7'>
                    <input value={data.barcode} onChange={(e)=>setdata({...data,barcode:e.target.value})}  style={{ border: "1px solid lightgray", padding: ".3rem", width: "210px" }} />
                  </div>
                </div>
                <div className='row my-4'>
                  <div style={{ display: "flex", justifyContent: "end", alignItems: "center", color: "gray" }} className='col-5'>
                    <label>Copy Images:</label>
                  </div>
                  <div className='col-7'>
                    <input onChange={(e)=>setdata({...data,copy_images:e.target.checked?1:0})}  type='checkbox' />
                  </div>
                </div>
                <div style={{ height: "70px", borderTop: "1px solid lightgray", background: "#F0F0F0" }}>
                  <div className='row'>
                    <div style={{ position: "relative" }} className='col-10'>
                      <div style={{ position: "absolute", right: "0%" }}>

                      <button type="button" disabled={data.title ? false : true} onClick={send} className="mx-2 my-3" style={{ border: "1px solid lightgray", color: "white", width:"100px",padding: ".3rem 1rem", background: "#839AB4", cursor: `${data.title ? "pointer" : "not-allowed"}` }} data-toggle="modal" data-target="#exampleModal">
                        <LiaSave />   Add
                      </button>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "end" }} className='col-2'>

                   
                      <button className="mx-2 my-3" type="button" onClick={handleClose} style={{ border: "1px solid lightgray", color: "gray", padding: ".3rem 1rem" }}>
                        Cancel
                      </button>
                    </div>
                  </div>

                </div>
              </Box>
            </Modal>
            <Check open={open1} setOpen={setOpen1} value={value} setvalue={setvalue}/>
            </>
  )
}

export default DuplicatePro