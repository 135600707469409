import React from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Add, Close } from '@mui/icons-material';
import { FiDelete, FiSave } from 'react-icons/fi';
import { RiDeleteBin5Line } from 'react-icons/ri';
import { useState } from 'react';
import { useEffect } from 'react';
const style = {
    position: 'absolute',
    top: '5%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: 700,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
};
function ListingRule({value,setValue,idx,rule,price}) {
    useEffect(()=>{
        setTableData(value[idx].rules)
    },[])
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false)
        // setTableData([])
    };
    const [discount_type,setdiscount]=useState("Fixed Price")
    const [tableData,setTableData]=useState([])
    console.log(tableData);
    var array=[]
    const addRow=()=>{
        console.log("hello");
        
      var obj=  {
            discount_type:discount_type,
            from_qty:"0",
            to_qty:"-",
            fixed_price:"0"
            
        }

        setTableData([...tableData,obj])
    }
    console.log(value);
    const handelsave=()=>{
        if(error){
            alert("error")
        }
        else{

            let newData=[...value]
            newData[idx].rules=tableData
            setValue(newData)
            handleClose()
        }

    }
    const [multipulSelct,setmultipulSelct]=useState([])
      const [select,setselect]=useState([])
      const getselect = (id, event) => {
        if (event.ctrlKey || event.metaKey) {
          setselect([...select, id]);
          setmultipulSelct([...multipulSelct, id])
        } else {
          setselect([id]);
          setmultipulSelct([id])
        }
      }
      const isRowSelected = (id) => select.includes(id);
      const deleteFun = () => {
        var array = tableData.filter((item, i) => {
          return !select.includes(i);
        });
      
        setTableData(array)
      };
      console.log(rule);
      const [error,seterror]=useState(true)
      const check=(price1,index)=>{
          console.log(error);
        if(price1!=="0" && price1<price){
            seterror(false)
        }
        else if(tableData[index-1]?.fixed_price && tableData[index-1].fixed_price>price1){
          
            seterror(false)
       
        }
        else {
            seterror(true)
        }
      }
      
    return (
        <>
            <button disabled={rule} onClick={handleOpen}  style={{ border: "1px solid lightgray", background: "#f0f0f0", color: "black", padding: ".1rem .6rem", margin: "0 .5rem",cursor:`${rule?"not-allowed":"pointer"}` }}>{value[idx].rules?.length}</button>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ color: "white", background: "#44688C", padding: "1rem", display: "flex", justifyContent: "space-between" }}>
                        <h5>Business pricing rules - io</h5>
                        <div>
                            {/* <button style={{ border: "none", background: "none" }}><VscChromeRestore style={{ color: "whitesmoke", fontSize: "20px" }} /></button> */}
                            <button onClick={() => handleClose()} style={{ border: "none", background: "none" }}><Close style={{ color: "whitesmoke" }} /></button>
                        </div>
                    </div>
                    <div style={{ padding: "1rem" }}>
                        <div className='row'>
                            <div className='col-12'>
                                <label style={{ marginLeft: "100px" }}>Business price:</label>
                                <input value={price} onChange={(e)=>{
                                       let newData=[...value]
                                       newData[idx].price=e.target.value
                                       setValue(newData)
                                }} style={{ marginLeft: "10px", border: "1px solid lightgray", width: "200px", padding: ".3rem 0" }} type='text' />
                            </div>
                            <div className='col-12 my-2'>
                                <label style={{ marginLeft: "100px" }}>Discount type:</label>
                                <select onChange={(e)=>setdiscount(e.target.value)} style={{ marginLeft: "15px", border: "1px solid lightgray", width: "200px", padding: ".3rem 0" }} type='text'>
                                    <option value={"Fixed Price"}>Fixed Price</option>
                                    <option value={"Percent Discount"}>percent Discount</option>
                                </select>
                            </div>
                        </div>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <button onClick={addRow} style={{ border: "1px solid lightgray", padding: ".5rem 1rem", margin: "0 1rem" }}><Add /> Add New</button>
                            <button onClick={deleteFun} style={{ border: "1px solid lightgray", padding: ".5rem 1rem" }}><RiDeleteBin5Line /> Delete selcted</button>
                        </div>

                    </div>
                    <div className='row' style={{ padding: "1rem" }}>
                        <div className='col-12'>
                            <div className='table w-100 border' style={{ minHeight: "40vh" }}>
                                <table>
                                    <thead>
                                    <tr>
                                    <th style={{ border: "1px solid #dee2e6",borderTop:"0", fontWeight: "normal", padding: ".5rem .6rem", width: "1%", background: "#f6f6f6" }}>From Quality</th>
                                    <th style={{ border: "1px solid #dee2e6",borderTop:"0",borderLeft:"0" ,fontWeight: "normal", padding: ".5rem .6rem", width: "1%", background: "#f6f6f6" }}>To Quantity</th>
                                    <th style={{ border: "1px solid #dee2e6", borderTop:"0",borderRight:"0",fontWeight: "normal", padding: ".5rem .6rem", width: "1%", background: "#f6f6f6" }}>{discount_type}</th>
                                </tr>
                                    </thead>
                                    <tbody>
                                    {tableData?.map((item,index)=>{
                                        return(
                                      
                                              <tr  onClick={(e)=>getselect(index, e)} style={{ background: `${isRowSelected(index) ? "#93B1C9" : ""}`,color: `${isRowSelected(index) ? "white" : ""}`}}>
                                    <td style={{ padding: ".5rem .6rem" }}>
                                        <input onChange={(e)=>{
                                            let newData=[...tableData]
                                            newData[index].from_qty=e.target.value
                                            newData[index].to_qty=parseInt(e.target.value)+10
                                            if(index>0 && e.target.value>newData[index-1].to_qty && e.target.value>parseInt(newData[index-1].from_qty)+10){
                                                newData[index-1].to_qty=parseInt(e.target.value)-1
                                            }
                                            setTableData(newData)
                                        }} 
                                        type='number'
                                        value={item.from_qty}
                                        style={{background:"none",border:"none",outline:"none"}}
                                        />
                                    </td>
                                    <td style={{ padding: ".5rem .6rem" }}>
                                        {item.to_qty}
                                    </td>
                                    <td style={{ padding: ".5rem .6rem",background: `${error ? "#FF7782" : "" }` }}>
                                    <input onChange={(e)=>{
                                            let newData=[...tableData]
                                            newData[index].fixed_price=e.target.value
                                            // newData[index].to_quantity=e.target.value+10
                                            setTableData(newData)
                                            check(item.fixed_price,index)
                                            
                                        }} 
                                        value={item.fixed_price}
                                        type='number'
                                        style={{background:"none",border:"none",outline:"none"}}
                                        />
                                    </td>
                                </tr>
                                            
                                        )
                                    })
                                }
                                    </tbody>
                                </table>
                            
                               
                              
                            </div>
                        </div>
                    </div>
                    <div className='row' style={{ backgroundColor: "#f0f0f0", borderTop: "1px solid #dee2e6!important",padding:"1.3rem",paddingRight:"0",width:"100%",margin:"0%" }}>
                        <div style={{ display: "flex", justifyContent: "end" }}>
                        <button onClick={handelsave} style={{ border: "1px solid lightgray", background: "#5C7FA5", color: "whitesmoke", padding: ".5rem 1rem", margin: "0 .5rem" }}><FiSave style={{ color: "whitesmoke" }} /> Save</button>
                            <button onClick={handleClose} style={{ border: "1px solid lightgray", background: "#EAEBEC", padding: ".5rem 1rem" }}>Cancel</button>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>

    )
}

export default ListingRule