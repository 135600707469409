import { Add, Close, Delete} from '@mui/icons-material';
import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {FiSave} from 'react-icons/fi'
import { useStateContext } from '../Context/ContextProvider';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,

  };
function ProductIdentifiers() {
  const{setproductIdentifier,productIdentifier}=useStateContext()
    const [open, setOpen] = React.useState(false);
    console.log(productIdentifier);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleOpen = () => {
      setOpen(true);
      setrow({
        product_identifier:"",
        identifier_type:"",
        site:"",
      })
    };
    const handleClose = () => {
      setOpen(false);
    };
    const open1 = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose1 = () => {
      setAnchorEl(null);
    };
    const [row,setrow]=useState({
      product_identifier:"",
      identifier_type:"",
      site:"",
    })
    const addRow = () => {




        setTableData([...tableData, row])


    }
    const [tableData, setTableData] = useState([
       



    ]);

    const handleInputChange = (e, rowIndex, columnName) => {
        const updatedData = [...tableData];
        updatedData[rowIndex][columnName] = e.target.value;
        setproductIdentifier(updatedData)
        setTableData(updatedData);
    };
    const getData=()=>{
      addRow()
      handleClose()
    }
    const [multipulSelct,setmultipulSelct]=useState([])
    const [select,setselect]=useState([])
    const getselect = (id, event) => {
      if (event.ctrlKey || event.metaKey) {
        setselect([...select, id]);
        setmultipulSelct([...multipulSelct, id])
      } else {
        setselect([id]);
        setmultipulSelct([id])
      }
    }
    const isRowSelected = (id) => select.includes(id);
    const deleteFun = () => {
      var array = tableData.filter((item, i) => {
        return !select.includes(i);
      });
    
      setTableData(array)
    };
    return (
        <div className='Createsuppliers listing_discription'>
    
                <div className='row'>
                    <div className='col-8'>
                        <h5>Product Identifiers:</h5>
                    </div>
                    <div className='col-4'>
                        <button onClick={handleOpen} type="button" className='btn1' >
                            <Add/> Add new
                        </button>
                        
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: 500 }}>
          <div className='modal-heade'>
            <div className='row'>
                <div className='col-10'>
                <h6>Add New Product Identifiers</h6>
                </div>
                <div className='col-2'>
                    <button onClick={handleClose} className='closeBtn'>
                        <Close/>
                    </button>
                </div>
            </div>
            
          </div>
          <div className='modal_body' style={{height:"30vh"}}>
            <div className='py-2 px-2'>
                <div className='row my-3'>
                    <div className='col-4' style={{position:"relative"}}>
                        <label style={{position:"absolute",right:"0%"}}>Product Identifiers:</label>
                    </div>
                    <div className='col-8'>
                       <input onChange={(e)=>setrow({...row,product_identifier:e.target.value})}   style={{border:"1px solid lightgray",padding:".4rem"}}/>
                    </div>
                </div>
                <div className='row my-3'>
                    <div className='col-4' style={{position:"relative"}}>
                        <label style={{position:"absolute",right:"0%"}}>Identifiers type:</label>
                    </div>
                    <div className='col-8'>
                       <select onChange={(e)=>setrow({...row,identifier_type:e.target.value})} style={{padding:"0.5rem",border:"1px solid lightgray"}}>
                        <option hidden></option>
                        <option value={"ASIN"}>ASIN</option>
                        <option value={"Customr ID"}>Customr ID</option>
                        <option value={"EAN"}>EAN</option>
                        <option value={"EAN"}>EAN</option>
                        <option value={"GCID"}>GCID</option>
                        <option value={"Google product identifier"}>Google product identifier</option>
                        <option value={"GTIN"}>GTIN</option>
                        <option value={"ISBN"}>ISBN</option>
                        <option value={"MPN"}>MPN</option>
                        <option value={"PZN"}>PZN</option>
                        <option value={"UPC"}>UPC</option>

                       </select>
                    </div>
                </div>
            </div>
        
          </div>
          <div className='model_footer'>
            <div className='row'>
              <div className='col-7'></div>
              <div className='col-5'>
              <button className='btn1 px-3' onClick={getData}><FiSave/> Add</button>
              <button  className='btn2' onClick={handleClose}>Cancel</button>
              </div>
            </div>
           
          </div>
        </Box>
      </Modal>
                        <button onClick={deleteFun} type="button" className='btn2'>
                         <Delete/>   Delete
                        </button>
                    </div>
                </div>

            <div className='table-responsive'>

            <table className="table table-striped">
                <thead>
                    <tr>
                    <th scope="col">Product Identifiers</th>
      <th scope="col">Identifiers identifier_type</th>
      <th scope="col">Site</th>


                    </tr>
                </thead>
                <tbody>
                    {tableData.map((item, index) => {
                        return (
                            <tr onClick={(e)=>getselect(index, e)} style={{ background: `${isRowSelected(index) ? "#93B1C9" : ""}`,color: `${isRowSelected(index) ? "white" : ""}`}}>
                                 <td><input onChange={(e) => handleInputChange(e, index, 'product_identifier')} value={item.product_identifier}/></td>
                <td><input   onChange={(e) => handleInputChange(e, index, 'identifier_type')} value={item.identifier_type}/></td>
                <td><input onChange={(e) => handleInputChange(e, index, 'site')} value={item.site}/></td>


                            </tr>
                        )
                    })}
                </tbody>
            </table>
            </div>

        </div>
    )
}

export default ProductIdentifiers