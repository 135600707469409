import { Delete } from '@mui/icons-material'
import React, { useState } from 'react'
import { useStateContext } from '../Context/ContextProvider';

function PostageDefination() {
  const {inventoryData,setinventoryData}=useStateContext()
  console.log(inventoryData);
  const gram=(value)=>{
    setinventoryData({...inventoryData,weight_grams:value,weight_ounces:value/28.35,weight_pounds:value/453.6,weight_kilograms:value/1000})
    
  }
  
    
  return (
    <>
                <div className='postage_defination'>
                <h6>Postage defination</h6>
                <div className='inp_1'>
                  <label>Default Postal Service:</label>
                  <select onChange={(e)=>setinventoryData({...inventoryData,default_postal_service_id:e.target.value})} >
                    <option value={1}>default</option>
                    <option value={1}>default</option>
                    <option value={1}>default</option>
                  </select>
                </div>
                <div className='inp_1'>
                  <label>Packing Group:</label>
                  <select onChange={(e)=>setinventoryData({...inventoryData,packaging_group_id:e.target.value})} style={{marginLeft:"55px"}}>
                    <option value={1}>Pack-jiffy-bag</option>
                    <option value={1}>Pack-jiffy-bag</option>
                    <option value={1}>Pack-jiffy-bag</option>
                  </select>
                </div>  
              </div>
              <div className='postage_defination'>
                <h6>Weight <span>(by default in 'Grams')</span></h6>
                <div className='inp_1'>
                    <div className='row'>
                        <div className='col-3'>
                        <label>Grams:</label>
                        <input  onChange={(e)=>gram(e.target.value)} type='number' style={{width:"90px"}} placeholder='grams'/>
                        </div>
                        <div className='col-3'>
                        <label>Oz:</label>
                        <input disabled type='number' value={inventoryData.weight_grams/28.35} style={{width:"90px"}} placeholder='Oz'/>
                        </div>
                        <div className='col-3'>
                        <label>Pounds:</label>
                        <input  disabled type='number' value={inventoryData.weight_grams/453.6} style={{width:"80px"}} placeholder='pounds'/>
                        </div>
                        <div className='col-3'>
                        <label>kg:</label>
                        <input  disabled type='number' value={inventoryData.weight_grams/1000} style={{width:"90px"}} placeholder='kg'/>
                        </div>
                    </div>
              
                </div>
         
              </div>
              <div className='postage_defination'>
                <h6>Dimention <span>(by default in 'Centimetres')</span></h6>
                <div className='inp_1'>
                    <div className='row'>
                        <div className='col-3'>
                        <label>width:</label>
                        <input onChange={(e)=>setinventoryData({...inventoryData,width:e.target.value})} type='number' style={{width:"90px"}} placeholder='width'/>
                        </div>
                        <div className='col-3'>
                        <label>Height:</label>
                        <input onChange={(e)=>setinventoryData({...inventoryData,height:e.target.value})} type='number' style={{width:"90px"}} placeholder='height'/>
                        </div>
                        <div className='col-3'>
                        <label>Depth:</label>
                        <input onChange={(e)=>setinventoryData({...inventoryData,depth:e.target.value,volume:inventoryData.width*inventoryData.height*inventoryData.depth})} type='number' style={{width:"80px"}} placeholder='depth'/>
                        </div>
                        <div className='col-3'>
                        <label>Volume:</label>
                        <input type='number' disabled value={inventoryData.width*inventoryData.height*inventoryData.depth} style={{width:"80px"}} placeholder='volume'/>
                        </div>
                    </div>
        
                </div>
         
              </div>

    </>
  )
}

export default PostageDefination