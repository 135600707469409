import React from 'react'
import Topbar from './Topbar'
import { Search } from '@mui/icons-material'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { IoIosRocket } from 'react-icons/io';
function DispatchConsole() {
  const orders = []; // This should be your list of orders

  return (
    <div className='dispatch_console'>
        <Topbar/>
        <div className='top_sectcion'>
            <h5 style={{fontWeight:"bold"}}>Dispatch Console</h5>
            <div>
                <label style={{fontWeight:"bold"}}>Lock search to:</label>
                <select style={{border:"1px solid lightgray",padding:".3rem",width:"180px",borderRadius:"5px",marginLeft:"5px"}}>
                    <option></option>
                </select>
                <input style={{border:"1px solid lightgray",padding:".3rem",width:"180px",borderRadius:"5px",marginLeft:"5px"}}/>
                <button style={{border:"1px solid lightgray",padding:".3rem",color:"white",background:"#85A6FF",borderRadius:"5px",marginLeft:"5px"}}>Lock</button>
            </div>
        </div>
        <div className='page_data'>
 {/* Tabs navs */}
 <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
    <li className="nav-item" role="presentation">
      <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true">By Order Id</a>
    </li>
    <li className="nav-item" role="presentation">
      <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Batch Process by order id </a>
    </li>
    <li className="nav-item" role="presentation">
      <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">By item Barcode</a>
    </li>
    <li className="nav-item" role="presentation">
      <a className="nav-link" id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="false">By tray</a>
    </li>
  </ul>
  <div className="tab-content" id="ex1-content">
    <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
    <input placeholder='Search by order id' style={{border:"1px solid lightgray",padding:".3rem",width:"280px",borderRadius:"5px",marginLeft:"5px"}}/>
                <button style={{border:"1px solid lightgray",padding:".3rem",color:"white",background:"#4A6BDB",borderRadius:"5px",marginLeft:"5px"}}><Search/></button>
    </div>
    <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
    <input placeholder='Search by order id' style={{border:"1px solid lightgray",padding:".3rem",width:"280px",borderRadius:"5px",marginLeft:"5px"}}/>
                <button style={{border:"1px solid lightgray",padding:".3rem 1rem",fontWeight:"bold",color:"white",background:"#4A6BDB",borderRadius:"5px",marginLeft:"5px"}}><Search/>Scan and process</button>



      <h6 style={{fontWeight:"bold",margin:" 1rem .5rem"}}>Scanned and processed orders:</h6>
      <div style={{minHeight:"60vh",border:"1px solid lightgray",background:"#FFFFFF"}}>
      <table style={{height:"100%", width: '100%'}}>
        <thead style={{background:"#F2F3F3",border:"1px solid ligthgray"}}>
          <tr>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Processed time</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Order id</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Order date</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Name</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Source</p></th>
          </tr>
        </thead>
        <tbody>
          {orders.length > 0 ? (
            orders.map((order) => (
              <tr key={order.id}>
                <td style={{ border: '1px solid black' }}>{order.processedTime}</td>
                <td style={{ border: '1px solid black' }}>{order.orderId}</td>
                <td style={{ border: '1px solid black' }}>{order.orderDate}</td>
                <td style={{ border: '1px solid black' }}>{order.name}</td>
                <td style={{ border: '1px solid black' }}>{order.source}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} style={{ textAlign: 'center'}}>
                <div style={{minHeight:"50vh",display:"flex",justifyContent:"center",alignContent:"center"}}>
                <p style={{marginTop:"20vh"}}>
                No Rows To Show
                </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
     

    </div>
    <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
    <input placeholder='Search by item barcode' style={{border:"1px solid lightgray",padding:".3rem",width:"280px",borderRadius:"5px",marginLeft:"5px"}}/>
                <button style={{border:"1px solid lightgray",padding:".3rem",color:"white",background:"#4A6BDB",borderRadius:"5px",marginLeft:"5px"}}><Search/></button>
                <div style={{paddingLeft:".5rem"}}>
                       <FormControlLabel control={<Checkbox defaultChecked />} label="Proceed to processing after scanning" />

                  {/* <label>Proceed to processing after scanning</label> */}
                </div>
                  <div style={{paddingLeft:".5rem"}}>
                         <FormControlLabel control={<Checkbox defaultChecked />} label="Process first one only" />

                    {/* <label>Process first one only</label> */}
                  </div>
                  <div style={{display:"flex",justifyContent:"space-between",}}>
                <h6 style={{fontWeight:"bold",margin:" 1rem .5rem"}}>Scanned and processed orders:</h6>
                <button style={{color:"white",background:"#85A6FF",marginBottom:".3rem",borderRadius:"5px",border:"none"}}><IoIosRocket style={{color:"white"}}/>Process selected (0)</button>
                  </div>
      <div style={{minHeight:"60vh",border:"1px solid lightgray",background:"#FFFFFF"}}>
      <table style={{height:"100%", width: '100%'}}>
        <thead style={{background:"#F2F3F3",border:"1px solid ligthgray"}}>
          <tr>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Order id</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Order date</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Name</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Source</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Product</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Totes</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Trays</p></th>
          </tr>
        </thead>
        <tbody>
          {orders.length > 0 ? (
            orders.map((order) => (
              <tr key={order.id}>
                <td style={{ border: '1px solid black' }}>{order.processedTime}</td>
                <td style={{ border: '1px solid black' }}>{order.orderId}</td>
                <td style={{ border: '1px solid black' }}>{order.orderDate}</td>
                <td style={{ border: '1px solid black' }}>{order.name}</td>
                <td style={{ border: '1px solid black' }}>{order.source}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} style={{ textAlign: 'center'}}>
                <div style={{minHeight:"50vh",display:"flex",justifyContent:"center",alignContent:"center"}}>
                <p style={{marginTop:"20vh",marginLeft:"250px"}}>
                No Rows To Show
                </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
    </div>
    <div className="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
    <input placeholder='Search by tray' style={{border:"1px solid lightgray",padding:".3rem",width:"280px",borderRadius:"5px",marginLeft:"5px"}}/>
                <button style={{border:"1px solid lightgray",padding:".3rem",color:"white",background:"#4A6BDB",borderRadius:"5px",marginLeft:"5px"}}><Search/></button>
                <div style={{paddingLeft:".5rem"}}>
                       <FormControlLabel control={<Checkbox defaultChecked />} label="Proceed to processing after scanning" />

                  {/* <label>Proceed to processing after scanning</label> */}
                </div>
                  <div style={{paddingLeft:".5rem"}}>
                         <FormControlLabel control={<Checkbox defaultChecked />} label="Process first one only" />

                    {/* <label>Process first one only</label> */}
                  </div>
                  <div style={{display:"flex",justifyContent:"space-between",}}>
                <h6 style={{fontWeight:"bold",margin:" 1rem .5rem"}}>Scanned and processed orders:</h6>
                <button style={{color:"white",background:"#85A6FF",marginBottom:".3rem",borderRadius:"5px",border:"none"}}><IoIosRocket style={{color:"white"}}/>Process selected (0)</button>
                  </div>
      <div style={{minHeight:"60vh",border:"1px solid lightgray",background:"#FFFFFF"}}>
      <table style={{height:"100%", width: '100%'}}>
        <thead style={{background:"#F2F3F3",border:"1px solid ligthgray"}}>
          <tr>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Order id</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Order date</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Name</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Source</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Product</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Totes</p></th>
            <th style={{paddingTop:".5rem",paddingLeft:"1rem" }}><p style={{ borderRight: '1px solid lightgray'}}>Trays</p></th>
          </tr>
        </thead>
        <tbody>
          {orders.length > 0 ? (
            orders.map((order) => (
              <tr key={order.id}>
                <td style={{ border: '1px solid black' }}>{order.processedTime}</td>
                <td style={{ border: '1px solid black' }}>{order.orderId}</td>
                <td style={{ border: '1px solid black' }}>{order.orderDate}</td>
                <td style={{ border: '1px solid black' }}>{order.name}</td>
                <td style={{ border: '1px solid black' }}>{order.source}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5} style={{ textAlign: 'center'}}>
                <div style={{minHeight:"50vh",display:"flex",justifyContent:"center",alignContent:"center"}}>
                <p style={{marginTop:"20vh",marginLeft:"250px"}}>
                No Rows To Show
                </p>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      </div>
    </div>
  </div>
  {/* Tabs content */}
        </div>
    </div>
  )
}

export default DispatchConsole