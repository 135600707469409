import React, { useEffect, useState } from 'react'
import Topbar from './Topbar'
import { Add, Search } from '@mui/icons-material'
import { RiShareForwardFill } from 'react-icons/ri'
import { PiPrinter } from 'react-icons/pi'
import { FiCheckSquare, FiFilter } from 'react-icons/fi'
import { Link, useNavigate } from 'react-router-dom'
import { useStateContext } from '../Context/ContextProvider'
import { MdFilterListAlt } from 'react-icons/md'
import { ImCheckboxUnchecked } from 'react-icons/im'
import Print from './Print'
import { useRef } from 'react'
import { useReactToPrint } from 'react-to-print';
function ParchaseOrder() {
  const {getsuplier,getPO,searchPO,WareHouse,currency,supplier,wareHouse,poData} =useStateContext()
  console.log(poData);
  useEffect(()=>{
    getsuplier()
    WareHouse()
    currency()
    getPO()
  },[])
  const [data,setdata]=useState()
  const [serchData,setSearchData]=useState({
    warehouse:"",
    supplier_id:0,
    e_date:"",
    create_date:"",
    redf_num:"",
    text:""
  })
  const navigate=useNavigate()
  const edit=()=>{
    if (data?.id) {
        
      navigate(`/dashboard/Edit-Purchase-order/${data.id}`)
    }
    else{
      alert(
        "select po"
      )
    }
  }
  console.log(supplier);
  return (
    <div className='perchase_order'>
        <Topbar/>
        <div className='tab_section'>
        <div>
  {/* Tabs navs */}
  <ul className="nav nav-tabs mb-3" id="ex1" role="tablist">
    <li className="nav-item" style={{position:"relative"}} role="presentation">
    {/* <FiCheckSquare style={{position:"absolute" ,left:"7%",top:"20%",color:"white",fontWeight:"bold",fontSize:"20px"}}/> */}
      <a className="nav-link active" id="ex1-tab-1" data-mdb-toggle="tab" href="#ex1-tabs-1" role="tab" aria-controls="ex1-tabs-1" aria-selected="true"> Pending</a>
    </li>
    <li className="nav-item"style={{position:"relative"}} role="presentation">
    {/* <ImCheckboxUnchecked style={{position:"absolute" ,left:"7%",top:"20%",fontWeight:"bold",fontSize:"20px"}}/> */}
      <a className="nav-link" id="ex1-tab-2" data-mdb-toggle="tab" href="#ex1-tabs-2" role="tab" aria-controls="ex1-tabs-2" aria-selected="false">Open</a>
    </li>
    <li className="nav-item" style={{position:"relative"}} role="presentation">
    {/* <ImCheckboxUnchecked style={{position:"absolute" ,left:"7%",top:"20%",fontWeight:"bold",fontSize:"20px"}}/> */}
      <a className="nav-link" id="ex1-tab-3" data-mdb-toggle="tab" href="#ex1-tabs-3" role="tab" aria-controls="ex1-tabs-3" aria-selected="false">Partial</a>
    </li>
    <li className="nav-item" style={{position:"relative"}} role="presentation">
    {/* <ImCheckboxUnchecked style={{position:"absolute" ,left:"7%",top:"20%",fontWeight:"bold",fontSize:"20px"}}/> */}
      <a className="nav-link" id="ex1-tab-4" data-mdb-toggle="tab" href="#ex1-tabs-4" role="tab" aria-controls="ex1-tabs-4" aria-selected="false">Delivered</a>
    </li>
    <li className="nav-item" style={{position:"relative"}} role="presentation">
    {/* <ImCheckboxUnchecked style={{position:"absolute" ,left:"7%",top:"20%",fontWeight:"bold",fontSize:"20px"}}/> */}
      <a className="nav-link" id="ex1-tab-5" data-mdb-toggle="tab" href="#ex1-tabs-5" role="tab" aria-controls="ex1-tabs-5" aria-selected="false">All</a>
    </li>
  </ul>
  {/* Tabs navs */}
    {/* <ImCheckboxUnchecked style={{position:"absolute" ,left:"7%",top:"20%",color:"white",fontWeight:"bold",fontSize:"20px"}}/> */}
  {/* Tabs content */}
  <div className="tab-content" id="ex1-content">
    <div className="tab-pane fade show active" id="ex1-tabs-1" role="tabpanel" aria-labelledby="ex1-tab-1">
      <div className='row'>
        <div className='col-7'>
            <div className='row'>
                <div className='col-12'>
                    <select value={serchData.warehouse} onChange={(e)=>setSearchData({...serchData,warehouse:e.target.value})}>
                    <option hidden>--select Ware House---</option>

                    {wareHouse?.map((item)=>{
                        return(
                          <option value={item.id}>{item.name}</option>

                        )
                      })}
                    </select>
                    <select value={serchData.supplier_id} onChange={(e)=>setSearchData({...serchData,supplier_id:e.target.value})}>
                      <option  hidden>--select  supplier---</option>
                      {supplier?.map((item)=>{

                        return(
                          <option value={item.id}>{item.supplier_name}</option>

                        )
                      })}

                    </select>
                    <input value={serchData.e_date} onChange={(e)=>setSearchData({...serchData,e_date:e.target.value})} type='date' className='date_inp'/>
                </div>
                <div className='col-12'>
                    <select value={serchData.redf_num} onChange={(e)=>setSearchData({...serchData,redf_num:e.target.value})}>
                        <option value={"reference_numbers"}>Reference Number</option>
                        <option value={"po_reference_number"}>Po reference number</option>
                        <option value={"stock_item_sku"}>Stock item sku</option>
                        <option value={"supplier_code"}>Supplier code</option>  
                        <option value={"supplier_reference_number"}>Supplier reference number</option>  
                    </select>
                 <input value={serchData.text} onChange={(e)=>setSearchData({...serchData,text:e.target.value})} placeholder='Enter search text' className='search_inp' />
                 <button onClick={()=>searchPO(serchData)}><Search/> Seacrh</button>
                
                </div>
            </div>
        </div>
        <div  className='col-5 d-flex justify-content-end'>
        <Link to={'/dashboard/Purchase-order'}>
        <button><Add/> New PO</button>
        </Link>
        <button onClick={edit}><RiShareForwardFill/> Edit PO</button>
        <Print data={data}/>
        
        </div>
      </div>
      <div className='table_box'>
            <div className='table-responsive'>

            <table class="table table-sm">
  <thead>
    <tr style={{background:"#F6F6F6"}}>
  
      <th scope="col">
       <div className='row'>
        <div style={{textAlign:"center"}} className='col-9'>
          <span style={{textAlign:"center"}}>PO#</span>
        </div>
        <div className='col-2'><MdFilterListAlt/></div>
       </div>
        
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9' style={{textAlign:"center"}}>Supplier Ref</div>
        <div className='col-2'><MdFilterListAlt/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9' style={{textAlign:"center"}}>Date</div>
        <div className='col-2'><MdFilterListAlt/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9' style={{textAlign:"center"}}>Supplier</div>
        <div className='col-2'><MdFilterListAlt/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9' style={{textAlign:"center"}}>Location</div>
        <div className='col-2'><MdFilterListAlt/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9' style={{textAlign:"center"}}>Line</div>
        <div className='col-2'><MdFilterListAlt/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9' style={{textAlign:"center"}}>Total</div>
        <div className='col-2'><MdFilterListAlt/></div>
       </div>
      </th>

     
    
    </tr>
  </thead>
  <tbody>
  {poData?.map((item,i)=>{
        return(
          <>
          {item.status=="PENDING" ? <tr className={`${i%2==0?"":"bg"}`} onClick={()=>setdata(item)} style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":""}}>
              
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.reference}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.supplier_ref}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
            <div className='row'>
              <div className='col-9'>
              {item.date}
              </div>
              </div>
            </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.supplier_name}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.location_name}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.total_amount}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.total_amount}
              </div>
              </div>
              </td>
  
           
          </tr>:""}
          </>

        )
    })}
  </tbody>
</table>
  </div>

        </div>
    </div>
    <div className="tab-pane fade" id="ex1-tabs-2" role="tabpanel" aria-labelledby="ex1-tab-2">
    <div className='row'>
        <div className='col-7'>
            <div className='row'>
                <div className='col-12'>
                    <select>
                        <option>Deleted 2</option>
                        <option>Sm Shoes</option>
                        <option>OK</option>
                    </select>
                    <select>
                        <option>Sm Shoes</option>
                        <option>OK</option>
                        <option>OK</option>
                    </select>
                    <input type='date' className='date_inp'/>
                </div>
                <div className='col-12'>
                    <select>
                        <option>Reference Number</option>
                        <option>OK</option>
                        <option>OK</option>
                    </select>
                 <input placeholder='Enter search text' className='search_inp' />
                 <button><Search/> Seacrh</button>
                
                </div>
            </div>
        </div>
        <div className='col-5 d-flex justify-content-end'>
        <button><Add/> New OP</button>
        <button onClick={edit}><RiShareForwardFill/> Deliver</button>
        <Print data={data}/>

        </div>
      </div>
      <div className='table_box'>
            <div className='table-responsive'>

            <table class="table table-sm">
  <thead>
    <tr>
  
      <th scope="col">
       <div className='row'>
        <div className='col-9'>PO#</div>
        <div className='col-2'><FiFilter/></div>
       </div>
        
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Supplier Ref</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Date</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Supplier</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Location</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Line</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Total</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>

     
    
    </tr>
  </thead>
  <tbody>
  {poData?.map((item,i)=>{
        return(
          <>
          {item.status=="OPEN" ? <tr className={`${i%2==0?"":"bg"}`} onClick={()=>setdata(item)} style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":""}}>
              
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.reference}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.supplier_ref}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
            <div className='row'>
              <div className='col-9'>
              {item.date}
              </div>
              </div>
            </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.supplier_name}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.location_name}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.total_amount}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.total_amount}
              </div>
              </div>
              </td>
  
           
          </tr>:""}
          </>

        )
    })}
  </tbody>
</table>
  </div>

        </div>
    </div>
    <div className="tab-pane fade" id="ex1-tabs-3" role="tabpanel" aria-labelledby="ex1-tab-3">
    <div className='row'>
        <div className='col-7'>
            <div className='row'>
                <div className='col-12'>
                    <select>
                        <option>Deleted 2</option>
                        <option>Sm Shoes</option>
                        <option>OK</option>
                    </select>
                    <select>
                        <option>Sm Shoes</option>
                        <option>OK</option>
                        <option>OK</option>
                    </select>
                    <input type='date' className='date_inp'/>
                </div>
                <div className='col-12'>
                    <select>
                        <option>Reference Number</option>
                        <option>OK</option>
                        <option>OK</option>
                    </select>
                 <input placeholder='Enter search text' className='search_inp' />
                 <button><Search/> Seacrh</button>
                
                </div>
            </div>
        </div>
        <div className='col-5 d-flex justify-content-end'>
        <Link to={'/dashboard/Purchase-order'}>
        <button><Add/> New PO</button>
        </Link>
        <button onClick={edit}><RiShareForwardFill/> Deliver</button>
        <Print data={data}/>

        </div>
      </div>
      <div className='table_box'>
            <div className='table-responsive'>

            <table class="table table-sm">
  <thead>
    <tr>
  
      <th scope="col">
       <div className='row'>
        <div className='col-9'>PO#</div>
        <div className='col-2'><FiFilter/></div>
       </div>
        
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Supplier Ref</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Date</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Supplier</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Location</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Line</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Delivered</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Total</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>

     
    
    </tr>
  </thead>
  <tbody>
  {poData?.map((item,i)=>{
        return( 
          <>
          {item.status=="Partial" ? <tr className={`${i%2==0?"":"bg"}`} onClick={()=>setdata(item)} style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":""}}>
              
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.reference}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.supplier_ref}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
            <div className='row'>
              <div className='col-9'>
              {item.date}
              </div>
              </div>
            </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.supplier_name}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.location_name}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.total_amount}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.total_amount}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.total_amount}
              </div>
              </div>
              </td>
  
           
          </tr>:""}
          </>

        )
    })}
  </tbody>
</table>
  </div>

        </div>
    </div>
    <div className="tab-pane fade" id="ex1-tabs-4" role="tabpanel" aria-labelledby="ex1-tab-4">
    <div className='row'>
        <div className='col-7'>
            <div className='row'>
                <div className='col-12'>
                    <select>
                        <option>Deleted 2</option>
                        <option>Sm Shoes</option>
                        <option>OK</option>
                    </select>
                    <select>
                        <option>Sm Shoes</option>
                        <option>OK</option>
                        <option>OK</option>
                    </select>
                    <input type='date' className='date_inp'/>
                </div>
                <div className='col-12'>
                    <select>
                        <option>Reference Number</option>
                        <option>OK</option>
                        <option>OK</option>
                    </select>
                 <input placeholder='Enter search text' className='search_inp' />
                 <button><Search/> Seacrh</button>
                
                </div>
            </div>
        </div>
        <div className='col-5 d-flex justify-content-end'>
        <Link to={'/dashboard/Purchase-order'}>
        <button><Add/> New PO</button>
        </Link>
        <button onClick={edit}><RiShareForwardFill/> View OP</button>
        <Print data={data}/>

        </div>
      </div>
      <div className='table_box'>
            <div className='table-responsive'>

            <table class="table table-sm">
  <thead>
    <tr>
  
      <th scope="col">
       <div className='row'>
        <div className='col-9'>PO#</div>
        <div className='col-2'><FiFilter/></div>
       </div>
        
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Supplier Ref</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Date</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Supplier</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Location</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Line</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>All Item Deliver</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Total</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>

     
    
    </tr>
  </thead>
  <tbody>
  {poData?.map((item,i)=>{
        return(
          <>
          {item.status=="Delivered" ? <tr className={`${i%2==0?"":"bg"}`} onClick={()=>setdata(item)} style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":""}}>
              
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.reference}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.supplier_ref}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
            <div className='row'>
              <div className='col-9'>
              {item.date}
              </div>
              </div>
            </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.supplier_name}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.location_name}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.total_amount}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              Yes
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.total_amount}
              </div>
              </div>
              </td>
  
           
          </tr>:""}
          </>

        )
    })}
  </tbody>
</table>
  </div>

        </div>
    </div><div className="tab-pane fade" id="ex1-tabs-5" role="tabpanel" aria-labelledby="ex1-tab-5">
    <div className='row'>
        <div className='col-7'>
            <div className='row'>
                <div className='col-12'>
                    <select>
                        <option>Deleted 2</option>
                        <option>Sm Shoes</option>
                        <option>OK</option>
                    </select>
                    <select>
                        <option>Sm Shoes</option>
                        <option>OK</option>
                        <option>OK</option>
                    </select>
                    <input type='date' className='date_inp'/>
                </div>
                <div className='col-12'>
                    <select>
                        <option>Reference Number</option>
                        <option>OK</option>
                        <option>OK</option>
                    </select>
                 <input placeholder='Enter search text' className='search_inp' />
                 <button><Search/> Seacrh</button>
                
                </div>
            </div>
        </div>
        <div className='col-5 d-flex justify-content-end'>
        <Link to={'/dashboard/Purchase-order'}>
        <button><Add/> New PO</button>
        </Link>
        {/* <button><RiShareForwardFill/> Edit OP</button> */}
        <Print data={data}/>

        </div>
      </div>
      <div className='table_box'>
            <div className='table-responsive'>

            <table class="table table-sm">
  <thead>
    <tr>
  
      <th scope="col">
       <div className='row'>
        <div className='col-9'>Status</div>
        <div className='col-2'><FiFilter/></div>
       </div>
        
      </th>
      <th scope="col">
       <div className='row'>
        <div className='col-9'>PO#</div>
        <div className='col-2'><FiFilter/></div>
       </div>
        
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Supplier Ref</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Date</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Supplier</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Location</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Line</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>
      <th scope="col">
      <div className='row'>
        <div className='col-9'>Total</div>
        <div className='col-2'><FiFilter/></div>
       </div>
      </th>

     
    
    </tr>
  </thead>
  <tbody>
  {poData?.map((item,i)=>{
        return(
          <>
          <tr className={`${i%2==0?"":"bg"}`} onClick={()=>setdata(item)} style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":""}}>
              
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.status}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.reference}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.supplier_ref}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
            <div className='row'>
              <div className='col-9'>
              {item.date}
              </div>
              </div>
            </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.supplier_name}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.location_name}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.total_amount}
              </div>
              </div>
              </td>
            <td style={{background:data?.id==item.id?"#44688C":"",color:data?.id==item.id?"white":"",textAlign:"center"}}>
              <div className='row'>
              <div className='col-9'>
              {item.total_amount}
              </div>
              </div>
              </td>
  
           
          </tr>
          </>

        )
    })}
  </tbody>
</table>
  </div>

        </div>
    </div>
  </div>
  {/* Tabs content */}
</div>

        </div>
    </div>
  )
}

export default ParchaseOrder