import React from 'react'
import img1 from '../image/linnworks.png'
import { BsCheck } from 'react-icons/bs'
import { RxCross2 } from 'react-icons/rx'
import { FaEnvelope, FaPercent, FaPhone, FaRegNewspaper, FaUser } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { BiSolidBox } from 'react-icons/bi'
import { PiGridFourFill } from 'react-icons/pi'
import { LuNetwork } from 'react-icons/lu'
function ProcessedDetail({ data }) {
    return (
        <div style={{background:"#F9F9F9",minHeight:"100vh"}} className='processed_orders proceedOrderData'>
            <button>BETA</button>
            <div style={{ padding: "1rem" }} className='variation_innerState'>
                <div className='tab_sectoion'>

                    {/* Tabs navs */}
                    <ul className="nav nav-tabs mb-3" id="ex-with-icons" role="tablist">
                        <li className="nav-item" role="presentation">
                        <a className="nav-link active" id="ex-with-icons-tab-1" data-mdb-toggle="tab" href="#ex-with-icons-tabs-1" role="tab" aria-controls="ex-with-icons-tabs-1" aria-selected="true"> <FaRegNewspaper/> Details</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="ex-with-icons-tab-2" data-mdb-toggle="tab" href="#ex-with-icons-tabs-2" role="tab" aria-controls="ex-with-icons-tabs-2" aria-selected="false"><BiSolidBox/>Audit Trail </a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="ex-with-icons-tab-3" data-mdb-toggle="tab" href="#ex-with-icons-tabs-3" role="tab" aria-controls="ex-with-icons-tabs-3" aria-selected="false"><LuNetwork/>Relations</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="ex-with-icons-tab-4" data-mdb-toggle="tab" href="#ex-with-icons-tabs-4" role="tab" aria-controls="ex-with-icons-tabs-4" aria-selected="false"><PiGridFourFill/>Package Split</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="ex-with-icons-tab-5" data-mdb-toggle="tab" href="#ex-with-icons-tabs-5" role="tab" aria-controls="ex-with-icons-tabs-5" aria-selected="false"> <PiGridFourFill/>Extended properties</a>
                        </li>
                        <li className="nav-item" role="presentation">
                            <a className="nav-link" id="ex-with-icons-tab-6" data-mdb-toggle="tab" href="#ex-with-icons-tabs-6" role="tab" aria-controls="ex-with-icons-tabs-6" aria-selected="false"> <PiGridFourFill/>Return/Exchanges</a>
                        </li>

                    </ul>
                    {/* Tabs navs */}
                    {/* Tabs content */}
                    <div className="tab-content" id="ex-with-icons-content">
                        <div style={{height:"80vh",overflowY:"scroll",padding:"0 1rem",overflowX:"hidden"}} className="tab-pane fade show active" id="ex-with-icons-tabs-1" role="tabpanel" aria-labelledby="ex-with-icons-tab-1">
                            <div className='row'>
                                <div className='col-md-8'>
                                    <div style={{paddingTop :".7rem"}} className='row'>
                                        <div className='col-md-8'>
                                            <div className='card' style={{ borderRadius: "0" ,boxShadow:'0 0 10px lightgray'}}>
                                                <div className='card-header' style={{background:"#FDFDFD"}}>
                                                    <div className='headerContent' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <div>
                                                            <p style={{ fontSize: "13px", color: "#333333" }}>#1337886</p>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-primary p-1' style={{ fontSize: "13px", color: "#333333" }}>paid</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <h6 style={{ fontWeight: "600", fontSize: "13px", color: "#333333" }}>Date</h6>
                                                            <p style={{ fontSize: "13px", color: "#333333" }}>02 Oct 2023 06:05</p>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <h6 style={{ fontWeight: "600", fontSize: "13px", color: "#333333" }}>Reference Number</h6>
                                                            <p style={{ fontSize: "13px", color: "#333333" }}>203-7086202-0365140</p>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <div className='card'>
                                                                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                                    <div>
                                                                        <h6 style={{ fontWeight: "600", fontSize: "13px", color: "#333333" }}>Love My Fashions</h6>
                                                                        <p style={{ fontSize: "13px", color: "#333333" }}>203-7086202-0365140</p>
                                                                    </div>
                                                                    <div>
                                                                        <img src={img1} width={"50px"} height={"50px"} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-md-4'>
                                                            <h6 style={{ fontWeight: "600", fontSize: "13px", color: "#333333" }}>Location</h6>
                                                            <p style={{ fontSize: "13px", color: "#333333" }}>Unit48 Ebay_Amazon_Web</p>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <h6 style={{ fontWeight: "600", fontSize: "13px", color: "#333333" }}>External Reference</h6>
                                                            <p style={{ fontSize: "13px", color: "#333333" }}>203-7086202-0365140</p>
                                                        </div>
                                                        <div className='col-md-4'>
                                                            <h6 style={{ fontWeight: "600", fontSize: "13px", color: "#333333" }}>Folders</h6>
                                                            <p style={{ fontSize: "13px", color: "#333333" }}>-</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-4'>
                                            <div className='card' style={{ borderRadius: "0" ,boxShadow:'0 0 10px lightgray' }}>
                                                <div className='card-header' style={{background:"#FDFDFD"}}>
                                                    <div className='headerContent' style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                        <div>
                                                            <p>12.55 GB</p>
                                                        </div>
                                                        <div>
                                                            <button className='btn btn-primary p-1' style={{ fontSize: "13px", color: "#333333" }}>Default</button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='card-body'>
                                                    <div className='row'>
                                                        <div className='col-8'>
                                                            <h6 style={{ fontSize: "13px", color: "#333333" }}>Discount</h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6 style={{ fontSize: "13px", color: "#333333" }}>$142</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-8'>
                                                            <h6 style={{ fontSize: "13px", color: "#333333" }}>Shipping</h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6 style={{ fontSize: "13px", color: "#333333" }}>$142</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-8'>
                                                            <h6 style={{ fontSize: "13px", color: "#333333" }}>Shipping (ex. Tax )</h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6 style={{ fontSize: "13px", color: "#333333" }}>$142</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-8'>
                                                            <h6 style={{ fontSize: "13px", color: "#333333" }}>Tax</h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6 style={{ fontSize: "13px", color: "#333333" }}>$142</h6>
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className='col-8'>
                                                            <h6 style={{ fontSize: "13px", color: "#333333" }}>SubTotal</h6>
                                                        </div>
                                                        <div className='col-4'>
                                                            <h6 style={{ fontSize: "13px", color: "#333333" }}>$142</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{padding:".7rem"}} className='row'>
                                        <div  style={{ border: "1px solid lightgray",boxShadow:'0 0 10px lightgray',padding:"0",background:"white" }}>
                                            <div className='table-responsive'>

                                            <table className="table">
                                                <thead style={{border:"1px solid lightgray",background:"#FDFDFD"}}>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Item</th>
                                                        <th scope="col">Discount</th>
                                                        <th scope="col">Tax Rate</th>
                                                        <th scope="col">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody style={{background:"white"}}>
                                                    <tr>
                                                        <th scope="row">
                                                            1x
                                                        </th>
                                                        <td>
                                                            Brave Soul Mens Long Sleeve Check Lumberjack Brushed Cotton Shirt
                                                        </td>
                                                        <td>0.00%</td>
                                                        <td>19.89%</td>
                                                        <td>$12.55</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>

                                    </div>
                                    <div style={{padding:".7rem"}} className='row'>
                                        <div  style={{ border: "1px solid lightgray",boxShadow:'0 0 10px lightgray',background:"white" ,padding:"0"}}>
                                            <div className='table-responsive'>

                                            <table className="table">
                                                <thead  style={{border:"1px solid lightgray",background:"#FDFDFD"}}>
                                                    <tr>
                                                        <th scope="col"></th>
                                                        <th scope="col">Item</th>
                                                        <th scope="col">Discount</th>
                                                        <th scope="col">Tax Rate</th>
                                                        <th scope="col">Total</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th scope="row">
                                                            1x
                                                        </th>
                                                        <td>
                                                            Brave Soul Mens Long Sleeve Check Lumberjack Brushed Cotton Shirt
                                                        </td>
                                                        <td>0.00%</td>
                                                        <td>19.89%</td>
                                                        <td>$12.55</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-md-4'>
                                    <div style={{padding:".7rem"}} className='row'>
                                        <div  style={{ border: "1px solid lightgray",paddingTop:"1rem",boxShadow:'0 0 10px lightgray' ,background:"white"}}>
                                            <h6 style={{margin:".5rem",fontWeight:"bold",color:'gray'}}>CUSTOMER</h6>
                                            <div style={{margin:".5rem"}}>
                                                <span><FaUser style={{ fontSize: "13px" }} /></span> <span style={{ fontSize: "13px", color: "#333333" }}> Philip Mohan</span>
                                            </div>
                                            <div style={{margin:".5rem"}}>
                                                <span><FaPhone style={{ fontSize: "13px" }} /></span> <span style={{ fontSize: "13px", color: "#333333" }}> <Link to={"#"}>0161 437 5302</Link></span>
                                            </div>
                                            <div style={{margin:".5rem"}}>
                                                <span><FaEnvelope style={{ fontSize: "13px" }} /></span> <span style={{ fontSize: "13px", color: "#333333" }}> <Link to={"#"}>jg3y4j2j1jbnq3b@marketplace.amazon.co.uk</Link></span>
                                            </div>
                                            <div style={{margin:".5rem"}}>
                                                <span><FaPercent style={{ fontSize: "13px" }} /></span> <span style={{ fontSize: "13px", color: "#333333" }}> -</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{padding:".7rem"}} className='row'>
                                        <div style={{border:'1px solid lightgray',padding:"0",boxShadow:'0 0 10px lightgray',background:"white"}}>
                                        <div style={{borderBottom:'1px solid lightgray',padding:".4rem"}}>
                                            <p style={{fontWeight:"bold",fontSize:'13px'}}>Amazon Buy - RM 48 (LL) (CRL) (Untracked)</p>
                                            <p># 39047065800000E23DCA5</p>
                                        </div>
                                          <div className='row'>
                                            <div className='col-6 p-3'>
                                                <p style={{padding:"0",margin:"0",fontWeight:"bold",fontSize:"13px"}}>Type</p>
                                                <p style={{padding:"0",margin:"0",fontSize:"13px"}}>Default</p>
                                            </div>
                                            <div className='col-6 p-3'>
                                                <p style={{padding:"0",margin:"0",fontWeight:"bold",fontSize:"13px"}}>Packaging</p>
                                                <p style={{padding:"0",margin:"0",fontSize:"13px"}}>Large Letter</p>
                                            </div>
                                            <div className='col-6 p-3'>
                                                <p style={{padding:"0",margin:"0",fontWeight:"bold",fontSize:"13px"}}>Weight</p>
                                                <p style={{padding:"0",margin:"0",fontSize:"13px"}}>0.175</p>
                                            </div>
                                        
                                          </div>
                                        </div>
                                    </div>
                                    <div style={{padding:".7rem"}} className='row'>
                                        <div style={{border:'1px solid lightgray',padding:"0",boxShadow:'0 0 10px lightgray',background:"white"}}>
                                      
                                        <div style={{padding:".4rem"}}>
                                            <p style={{fontWeight:"bold",fontSize:'13px'}}>Amazon Buy - RM 48 (LL) (CRL) (Untracked)</p>
                                            <p  style={{padding:"0",margin:"0",fontSize:"13px"}}>Hannah Koop</p>
                                            <p  style={{padding:"0",margin:"0",fontSize:"13px"}}>27</p>
                                            <p  style={{padding:"0",margin:"0",fontSize:"13px"}}>SCHOOL LANE BRINSCALL</p>
                                            <p  style={{padding:"0",margin:"0",fontSize:"13px"}}>CHORLEY</p>
                                            <p  style={{padding:"0",margin:"0",fontSize:"13px"}}>Lancashire</p>
                                            <p  style={{padding:"0",margin:"0",fontSize:"13px"}}>PR6 8QS</p>
                                            <p  style={{padding:"0",margin:"0",fontSize:"13px"}}>United Kingdom</p>
                                        </div>
                                          <div style={{borderTop:'1px solid lightgray',padding:".4rem"}}>
                                            <p style={{fontWeight:"bold",fontSize:'13px'}}>BILLING ADDRESS</p>
                                            <p>Same as shipping address.</p>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="ex-with-icons-tabs-2" role="tabpanel" aria-labelledby="ex-with-icons-tab-2">
                            <div className='table-responsive' style={{boder:"1px solid lightgray"}}>
                                <table id='processed_orderDetails' class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Discrption</th>
                                            <th scope="col">Note</th>
                                            <th scope="col">User</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.warehouse_levels?.map((item, i) => {
                                            return (
                                                <tr style={{ background: `${i % 2 == 0 ? "#ffff" : "#EAEAEA"}` }}>
                                                    {/* <th scope="row">1</th> */}
                                                    <td>{item?.name}</td>
                                                    <td style={{ textAlign: "center" }}>{item?.level == null ? 0 : item?.level}</td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td style={{ background: `${item?.level == null || item?.level == 0 ? "#FF7782" : "#AAC99E"}`, textAlign: "center", color: "white" }}>{item?.level == null ? 0 : item?.level}</td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td>{data?.purchase_price}</td>
                                                </tr>

                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="ex-with-icons-tabs-3" role="tabpanel" aria-labelledby="ex-with-icons-tab-3">
                            <div className='table-responsive' style={{boder:"1px solid lightgray"}}>
                                <table id='processed_orderDetails' class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Discrption</th>
                                            <th scope="col">Note</th>
                                            <th scope="col">User</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.warehouse_levels?.map((item, i) => {
                                            return (
                                                <tr style={{ background: `${i % 2 == 0 ? "#ffff" : "#EAEAEA"}` }}>
                                                    {/* <th scope="row">1</th> */}
                                                    <td>{item?.name}</td>
                                                    <td style={{ textAlign: "center" }}>{item?.level == null ? 0 : item?.level}</td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td style={{ background: `${item?.level == null || item?.level == 0 ? "#FF7782" : "#AAC99E"}`, textAlign: "center", color: "white" }}>{item?.level == null ? 0 : item?.level}</td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td>{data?.purchase_price}</td>
                                                </tr>

                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="ex-with-icons-tabs-4" role="tabpanel" aria-labelledby="ex-with-icons-tab-4">
                            <div className='table-responsive' style={{boder:"1px solid lightgray"}}>
                                <table id='processed_orderDetails' class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Discrption</th>
                                            <th scope="col">Note</th>
                                            <th scope="col">User</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.warehouse_levels?.map((item, i) => {
                                            return (
                                                <tr style={{ background: `${i % 2 == 0 ? "#ffff" : "#EAEAEA"}` }}>
                                                    {/* <th scope="row">1</th> */}
                                                    <td>{item?.name}</td>
                                                    <td style={{ textAlign: "center" }}>{item?.level == null ? 0 : item?.level}</td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td style={{ background: `${item?.level == null || item?.level == 0 ? "#FF7782" : "#AAC99E"}`, textAlign: "center", color: "white" }}>{item?.level == null ? 0 : item?.level}</td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td>{data?.purchase_price}</td>
                                                </tr>

                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="ex-with-icons-tabs-5" role="tabpanel" aria-labelledby="ex-with-icons-tab-5">
                            <div className='table-responsive' style={{boder:"1px solid lightgray"}}>
                                <table id='processed_orderDetails' class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Discrption</th>
                                            <th scope="col">Note</th>
                                            <th scope="col">User</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.warehouse_levels?.map((item, i) => {
                                            return (
                                                <tr style={{ background: `${i % 2 == 0 ? "#ffff" : "#EAEAEA"}` }}>
                                                    {/* <th scope="row">1</th> */}
                                                    <td>{item?.name}</td>
                                                    <td style={{ textAlign: "center" }}>{item?.level == null ? 0 : item?.level}</td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td style={{ background: `${item?.level == null || item?.level == 0 ? "#FF7782" : "#AAC99E"}`, textAlign: "center", color: "white" }}>{item?.level == null ? 0 : item?.level}</td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td>{data?.purchase_price}</td>
                                                </tr>

                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="ex-with-icons-tabs-6" role="tabpanel" aria-labelledby="ex-with-icons-tab-6">
                            <div className='table-responsive' style={{boder:"1px solid lightgray"}}>
                                <table id='processed_orderDetails' class="table table-sm">
                                    <thead>
                                        <tr>
                                            <th scope="col">Date</th>
                                            <th scope="col">Discrption</th>
                                            <th scope="col">Note</th>
                                            <th scope="col">User</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.warehouse_levels?.map((item, i) => {
                                            return (
                                                <tr style={{ background: `${i % 2 == 0 ? "#ffff" : "#EAEAEA"}` }}>
                                                    {/* <th scope="row">1</th> */}
                                                    <td>{item?.name}</td>
                                                    <td style={{ textAlign: "center" }}>{item?.level == null ? 0 : item?.level}</td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td style={{ background: `${item?.level == null || item?.level == 0 ? "#FF7782" : "#AAC99E"}`, textAlign: "center", color: "white" }}>{item?.level == null ? 0 : item?.level}</td>
                                                    <td style={{ textAlign: "center" }}>0</td>
                                                    <td>{data?.purchase_price}</td>
                                                </tr>

                                            )
                                        })
                                        }

                                    </tbody>
                                </table>
                            </div>
                        </div>
        
                    </div>
                    {/* Tabs content */}
                </div>
            </div>
        </div>
    )
}

export default ProcessedDetail