import { Add, Close } from '@mui/icons-material'
import { FormControlLabel, Radio } from '@mui/material'
import React from 'react'
import { BiSearchAlt2 } from 'react-icons/bi'
import { FaPencilAlt } from 'react-icons/fa'
import { FiArrowDown, FiArrowUp } from 'react-icons/fi'
import { ImBin, ImCopy } from 'react-icons/im'
// import * as React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { LuSave } from 'react-icons/lu'
const style = {
    position: 'absolute',
    top: '10%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // p: 4,
  };
function RuleEngen() {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
  return (
    <div className='rule_engen'>
        <div style={{display:'flex',justifyContent:"end",padding:"1rem"}}> 
            <button onClick={handleOpen} style={{border:"none",background:"#E9EAEB",padding:".5rem 1rem",border:"1px solid lightgray",boxShadow:"0 0 2px #E9EAEB"}}><Add/>New Rule</button>
            <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <div style={{padding:"1rem",background:"#44688C",display:"flex",justifyContent:"space-between"}} className='header_modal'>
               <h4 style={{color:"white"}}>Add Rule</h4>
               <button style={{color:"lightgray",background:"none",border:"none"}} onClick={handleClose}>
                <Close/>
               </button>
            </div>
            <div style={{padding:"1rem"}}>
                <lable>Rule Name</lable>
                <input style={{width:"100%",border:"1px solid lightgray",padding:".3rem"}}/>
            </div>
            <div style={{padding:"1rem",border:"1px solid lightgray",background:"#F0F0F0",display:"flex",justifyContent:"end"}}>
                <button style={{border:"none",background:"#5C7FA5",padding:".5rem 1rem",border:"1px solid lightgray",boxShadow:"0 0 2px #E9EAEB",color:"white"}}><LuSave/>Create</button>
                <button onClick={handleClose} style={{border:"none",background:"#E9EAEB",padding:".5rem 1rem",border:"1px solid lightgray",boxShadow:"0 0 2px #E9EAEB",marginLeft:"1rem"}}>Cancel</button>
            </div>
        </Box>
      </Modal>
        </div>
        <div className='table-responsive'>
        <table className="table table-sm">
  <thead>
    <tr>
      <th style={{width:"30px"}} scope="col"></th>
      <th style={{width:"350px"}} scope="col">Name</th>
      <th style={{width:"100px"}} scope="col">Type</th>
      <th style={{width:"100px"}} scope="col">Enabled</th>
      <th style={{width:"200px"}} scope="col">Status</th>
      <th style={{width:"50px"}} scope="col">Order</th>
      <th style={{width:"200px"}} scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr style={{background:"white"}}>
      <td></td>
      <td>Set Shopify shipping</td>
      <td>Orders</td>
      <td>
        <div style={{width:"100%",background:"#5C7FA5",padding:".2rem .5rem",display:"flex",justifyContent:"space-between"}}>
            <label style={{color:"white"}}>Enabled</label>
 <Radio style={{padding:"0",color:"white"}}/>

        </div>
      </td>
      <td>Pending changes</td>
      <td style={{textAlign:"center"}}>1</td>
      <td>

        <button style={{border:"none",background:"#E9EAEB",marginLeft:"10px",padding:".1rem .3rem",border:"1px solid lightgray",boxShadow:"0 0 2px #E9EAEB"}}>
            <FaPencilAlt />
        </button>
        <button style={{border:"none",background:"#E9EAEB",marginLeft:"10px",padding:".1rem .3rem",border:"1px solid lightgray",boxShadow:"0 0 2px #E9EAEB"}}>
            <BiSearchAlt2/>
        </button>
        <button style={{border:"none",background:"#E9EAEB",marginLeft:"10px",padding:".1rem .3rem",border:"1px solid lightgray",boxShadow:"0 0 2px #E9EAEB"}}>
            <ImCopy/>
        </button>
        <button style={{border:"none",background:"#E9EAEB",marginLeft:"10px",padding:".1rem .3rem",border:"1px solid lightgray",boxShadow:"0 0 2px #E9EAEB"}}>
            <FiArrowUp/>
        </button>
        <button style={{border:"none",background:"#E9EAEB",marginLeft:"10px",padding:".1rem .3rem",border:"1px solid lightgray",boxShadow:"0 0 2px #E9EAEB"}}>
            <FiArrowDown/>
        </button>
        <button style={{border:"none",background:"#E9EAEB",marginLeft:"10px",padding:".1rem .3rem",border:"1px solid lightgray",boxShadow:"0 0 2px #E9EAEB"}}>
            <ImBin/>
        </button>
      </td>
    </tr>

  </tbody>
</table>

        </div>
    </div>
  )
}

export default RuleEngen