import React from 'react'

function ProssesedOrderDetail({data}) {
  return (
    <div className='tab_sectoion'>
             
    {/* Tabs navs */}
    <ul className="nav nav-tabs mb-3" id="ex-with-icons" role="tablist">
      <li className="nav-item" role="presentation">
        <a className="nav-link active" id="ex-with-icons-tab-1" data-mdb-toggle="tab" href="#ex-with-icons-tabs-1" role="tab" aria-controls="ex-with-icons-tabs-1" aria-selected="true">Order Items</a>
      </li>
      <li className="nav-item" role="presentation">
        <a className="nav-link" id="ex-with-icons-tab-2" data-mdb-toggle="tab" href="#ex-with-icons-tabs-2" role="tab" aria-controls="ex-with-icons-tabs-2" aria-selected="false">Audit trail </a>
      </li>
    
    </ul>
    {/* Tabs navs */}
    {/* Tabs content */}
    <div className="tab-content" id="ex-with-icons-content">
      <div className="tab-pane fade show active" id="ex-with-icons-tabs-1" role="tabpanel" aria-labelledby="ex-with-icons-tab-1">
        <div className='table-responsive'>
        <table id='processed_orderDetail' class="table table-sm">
    <thead>
      <tr>
        <th scope="col">SKU</th>
        <th scope="col">Item title</th>
        <th scope="col">Quantity</th>
        <th scope="col">Cost (ex tax)</th>
        <th scope="col">Tax Rate (%)</th>
        <th scope="col">Sales tax</th>
        <th scope="col">Cost</th>
        <th scope="col">Note</th>
      </tr>
    </thead>
    <tbody>
    {data?.warehouse_levels?.map((item,i)=>{
        return(
      <tr style={{background:`${i%2==0?"#ffff":"#EAEAEA"}`}}>
        {/* <th scope="row">1</th> */}
        <td>{item?.name}</td>
        <td style={{textAlign:"center"}}>{item?.level==null?0:item?.level}</td>
        <td style={{textAlign:"center"}}>0</td>
        <td style={{background:`${item?.level==null||item?.level ==0?"#FF7782":"#AAC99E"}`,textAlign:"center",color:"white"}}>{item?.level==null?0:item?.level}</td>
        <td style={{textAlign:"center"}}>0</td>
        <td>{data?.purchase_price}</td>
      </tr>
  
        )
      })
  }
     
    </tbody>
  </table>
        </div>
      </div>
      <div className="tab-pane fade" id="ex-with-icons-tabs-2" role="tabpanel" aria-labelledby="ex-with-icons-tab-2">
      <div className='table-responsive'>
      <table id='processed_orderDetail' class="table table-sm">
    <thead>
      <tr>
        <th scope="col">Date</th>
        <th scope="col">Discrption</th>
        <th scope="col">Note</th>
        <th scope="col">User</th>
      </tr>
    </thead>
    <tbody>
    {data?.warehouse_levels?.map((item,i)=>{
        return(
      <tr style={{background:`${i%2==0?"#ffff":"#EAEAEA"}`}}>
        {/* <th scope="row">1</th> */}
        <td>{item?.name}</td>
        <td style={{textAlign:"center"}}>{item?.level==null?0:item?.level}</td>
        <td style={{textAlign:"center"}}>0</td>
        <td style={{background:`${item?.level==null||item?.level ==0?"#FF7782":"#AAC99E"}`,textAlign:"center",color:"white"}}>{item?.level==null?0:item?.level}</td>
        <td style={{textAlign:"center"}}>0</td>
        <td>{data?.purchase_price}</td>
      </tr>
  
        )
      })
  }
     
    </tbody>
  </table>
        </div>
      </div>
  
    </div>
    {/* Tabs content */}
          </div>
  )
}

export default ProssesedOrderDetail