import React, { useState } from 'react';

function ColumnSorting() {
  // Initialize two state variables for the arrays
  const [firstArray, setFirstArray] = useState([1, 2, 3, 4]);
  const [secondArray, setSecondArray] = useState([]);

  // Function to move a value from the first array to the second array
  const moveToSecondArray = (value) => {
    if (firstArray.includes(value)) {
      // Remove the value from the first array
      const updatedFirstArray = firstArray.filter(item => item !== value);
      setFirstArray(updatedFirstArray);
      
      // Add the value to the second array
      setSecondArray([...secondArray, value]);
    }
  };

  // Function to move a value from the second array to the first array
  const moveToFirstArray = (value) => {
    if (secondArray.includes(value)) {
      // Remove the value from the second array
      const updatedSecondArray = secondArray.filter(item => item !== value);
      setSecondArray(updatedSecondArray);
      
      // Add the value to the first array
      setFirstArray([...firstArray, value]);
    }
  };

  return (
    <div>
      <h2>First Array</h2>
      <table>
        <tbody>
          {firstArray.map((value) => (
            <tr key={value}>
              <td>{value}</td>
              <td>
                <button onClick={() => moveToSecondArray(value)}>Move to Second Array</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <h2>Second Array</h2>
      <table>
        <tbody>
          {secondArray.map((value) => (
            <tr key={value}>
              <td>{value}</td>
              <td>
                <button onClick={() => moveToFirstArray(value)}>Move to First Array</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ColumnSorting;
